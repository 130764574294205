import { useCallback, useContext } from 'react'

import { Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { setSelectedShapeIds } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, MODAL_TYPES } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { Shape, ShapeDetectionResult, ShapeKeyType } from 'interfaces/shape'

import { ERROR_PROCESS_MESSAGE } from 'services/ErrorHandler'
import { convertDetectionResultToShapes } from 'services/Points'
import { checkJobStatusForDetectPolygonsVolume } from 'services/VolumeEstimation'

export default function useDetectPolygonsVolume(
  /**
   * Editor context.
   */
  {
    inspectionSheet,
    changeIsJobRunning,
    fetchShapes,
    fetchInspectionItems,
    setIsLayerModifying,
    updateToggledCollapses,
  }: Editor,

  /**
   * Shape key to be detected.
   */
  shapeKey: ShapeKeyType,

  /**
   * Callback to be called during various stages of detecting polygons volume.
   */
  callbacks?: {
    /**
     * Callback to be called after finished running everything.
     */
    onFinished?: () => void
  },
) {
  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((root: RootState) => root.page.project)
  const inspectionArea = useSelector((root: RootState) => root.page.inspectionArea)

  /**
   * Polls the server for completion of volume estimation.
   */
  const poll: (jobToken: string) => Promise<boolean> = useCallback(
    async (jobToken: string) => {
      if (!project || !inspectionArea?.down_sampled_file?.path || !jobToken || !inspectionSheet) return false

      const token = await getAccessToken()
      if (!token) return false

      const errorResults: ShapeDetectionResult[] = []
      const jobResult = await checkJobStatusForDetectPolygonsVolume(token, jobToken, showErrorModal)
      if (jobResult === null) {
        setTimeout(() => {
          void poll(jobToken)
        }, 5000)
      } else {
        if (jobResult) {
          const results = jobResult.list_shapes as ShapeDetectionResult[]
          const newShapes = convertDetectionResultToShapes(
            shapeKey,
            results.filter((shape) => !shape.error_id),
          ) as Shape[]
          errorResults.push(...(results.filter((shape) => !!shape.error_id) || []))

          if (newShapes.length > 0) {
            // refetch inspection sheet items as various behavior related to estimate volume
            // depends on inspection item generated by estimateVolume API call
            // do this regardless of errors since if some succeeds, those need to be shown to user
            await Promise.all([fetchInspectionItems(), fetchShapes()])

            // set the new planes as selected planes
            dispatch(setSelectedShapeIds(newShapes.map((shape) => shape.shape_id)))
          }

          const totalErrorShapes = errorResults.length

          // track with mixpanel
          mixpanel.track('Finish detecting shapes', {
            'Shape type': shapeKey,
            'Number of shapes (success)': jobResult.list_shapes.length - totalErrorShapes,
            'Number of shapes (error)': totalErrorShapes,
          })

          // if job has failed or no shape has been detected, show a message
          if (!jobResult.list_shapes.length) {
            const statusId = (
              JSON.parse(
                (
                  JSON.parse((JSON.parse(jobResult.error_object) as { body: string }).body) as {
                    status_message: string
                  }
                ).status_message,
              ) as { status_id: number }
            ).status_id
            const messages = statusId ? ERROR_PROCESS_MESSAGE.ESTIMATE_VOLUME.messages[statusId] || '' : ''

            dispatch(setAttentionText({ message: '' }))
            showModal({
              body: messages || '要素は検出されませんでした。',
              modalType: MODAL_TYPES.ALERT,
              title: '体積測定',
              confirmText: '了解',
            })
            // if there are some shapes failed to detected, show a message
          } else if (totalErrorShapes) {
            dispatch(setAttentionText({ message: '' }))
            showModal({
              body: (
                <Text>
                  <Text fontWeight="bold" display="inline-block">
                    {totalErrorShapes}
                  </Text>
                  個の平面が検出されませんでした。
                </Text>
              ),
              modalType: MODAL_TYPES.ALERT,
              title: '要素検出',
              confirmText: '了解',
            })
          }
        }

        setIsLayerModifying(false)
        changeIsJobRunning(false)
        updateToggledCollapses([EDITOR_COLLAPSE_TYPES.detected])
        dispatch(setAttentionText({ message: '' }))
        if (callbacks?.onFinished) callbacks.onFinished()
      }
      return false
    },
    [
      project,
      inspectionArea,
      inspectionSheet,
      shapeKey,
      callbacks,
      dispatch,
      fetchShapes,
      showErrorModal,
      getAccessToken,
      changeIsJobRunning,
      fetchInspectionItems,
      setIsLayerModifying,
      showModal,
      updateToggledCollapses,
    ],
  )

  return {
    poll,
  }
}
