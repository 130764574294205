import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { merge } from 'lodash'

import { InspectionArea, Project } from 'interfaces/interfaces'
import { ErrorPayload, PageError } from 'interfaces/system'

import { getInspectionArea, getInspectionAreas, setInspectionAreasOrder } from 'services/InspectionArea'
import { getProject } from 'services/Projects'

export interface PageState {
  pageError?: PageError

  /**
   * Current project beign viewed.
   */
  project: Project | null

  /**
   * Current inspection area being viewed.
   */
  inspectionArea?: InspectionArea

  /**
   * All inspection areas for the current project.
   */
  inspectionAreas: InspectionArea[]

  /**
   * Whether the page is loading.
   */
  isLoading: boolean

  /**
   * Whether the user is the owner of the project.
   */
  isOwner: boolean

  /**
   * Whether the user is invited to the project.
   */
  isInvited: boolean
}

/**
 * Get the currnet page's project.
 *
 * @param {string} access_token Access token provided by Auth0
 * @param {string} project_id Project ID
 * @param {function} showErrorModal Show error modal function
 */
export const fetchProject = createAsyncThunk(
  'page/fetchProject',
  async (
    {
      access_token,
      project_id,
      showErrorModal,
    }: {
      access_token: string
      project_id: string
      showErrorModal?: (message: string) => void
    },
    { rejectWithValue },
  ) => {
    let result: Awaited<ReturnType<typeof getProject>> = null
    try {
      result = await getProject(access_token, project_id, showErrorModal)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        // If showErrorModal is provided, this will never be executed as
        // the error will be caught there which are intentional.
        const axe = err as AxiosError
        return rejectWithValue({
          code: axe.response?.status,
          message: axe.response?.statusText,
          response: axe.response?.data,
          showErrorModal: !!showErrorModal,
        })
      }

      return rejectWithValue(err)
    }

    return result
  },
)

/**
 * Get the current page's inspection area.
 *
 * @param {string} access_token Access token provided by Auth0
 * @param {function} showErrorModal Show error modal function
 */
export const fetchInspectionArea = createAsyncThunk(
  'page/fetchInspectionArea',
  async (
    {
      access_token,
      project_id,
      inspection_area_id,
      showErrorModal,
    }: {
      access_token: string
      project_id: string
      inspection_area_id: string
      showErrorModal?: (message: string) => void
    },
    { rejectWithValue },
  ) => {
    let result: InspectionArea | null = null
    try {
      result = await getInspectionArea(access_token, project_id, inspection_area_id, showErrorModal)
    } catch (err) {
      // If showErrorModal is provided, this will never be executed as
      // the error will be caught there which are intentional.
      if (axios.isAxiosError(err)) {
        const axe = err as AxiosError
        return rejectWithValue({
          code: axe.response?.status,
          message: axe.response?.statusText,
          response: axe.response?.data,
        })
      }

      return rejectWithValue(err)
    }

    return result
  },
)

/**
 * Get all inspection areas for the current page's project.
 *
 * @param {string} access_token Access token provided by Auth0
 * @param {function} showErrorModal Show error modal function
 */
export const fetchInspectionAreas = createAsyncThunk(
  'page/fetchInspectionAreas',
  ({
    access_token,
    project_id,
    showErrorModal,
  }: {
    access_token: string
    project_id: string
    showErrorModal?: (message: string) => void
  }) => getInspectionAreas(access_token, project_id, showErrorModal),
)

const initialState: PageState = {
  project: null,
  isLoading: true,
  isOwner: false,
  isInvited: false,
  inspectionAreas: [],
}

export const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageError: (state, action: PayloadAction<PageError>) => {
      state.pageError = action.payload
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload
    },
    patchInspectionAreas: (state, { payload: updatedAreas }: PayloadAction<Partial<InspectionArea>[]>) => {
      const updatedArea = updatedAreas.find(
        (area) => area.inspection_area_id === state.inspectionArea?.inspection_area_id,
      )
      if (updatedArea) state.inspectionArea = merge({}, state.inspectionArea, updatedArea)
      state.inspectionAreas = state.inspectionAreas.map((area) => {
        const newArea = updatedAreas.find((a) => a.inspection_area_id === area.inspection_area_id)
        return newArea ? merge({}, area, newArea) : area
      })
    },
    setInspectionArea: (state, { payload }: PayloadAction<InspectionArea | undefined>) => {
      state.inspectionArea = payload
    },
    setInspectionAreas: (state, { payload }: PayloadAction<InspectionArea[]>) => {
      state.inspectionAreas = payload
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setIsOwner: (state, { payload }: PayloadAction<boolean>) => {
      state.isOwner = payload
    },
    setIsInvited: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvited = payload
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.fulfilled, (state, action) => {
        if (action.payload) state.project = action.payload
      })
      .addCase(fetchProject.rejected, (state, action) => {
        const err = action.payload as ErrorPayload
        if (err.code === 403) state.pageError = PageError.Forbidden
        else if (err.code === 404) state.pageError = PageError.NotFound
        else if ([400, 500].includes(err.code)) state.pageError = PageError.InternalServerError
      })
      .addCase(fetchInspectionArea.fulfilled, (state, action) => {
        if (action.payload) {
          state.inspectionArea = action.payload
          state.inspectionAreas = state.inspectionAreas.map((area) =>
            area.inspection_area_id === action.payload?.inspection_area_id ? action.payload : area,
          )
        }
      })
      .addCase(fetchInspectionArea.rejected, (state, action) => {
        const err = action.payload as ErrorPayload
        if (err.code === 403) state.pageError = PageError.Forbidden
        else if (err.code === 404) state.pageError = PageError.NotFound
        else if ([400, 500].includes(err.code)) state.pageError = PageError.InternalServerError
      })
      .addCase(fetchInspectionAreas.fulfilled, (state, action) => {
        if (action.payload) {
          state.inspectionAreas = setInspectionAreasOrder(action.payload, []).sort((a, b) => a.order! - b.order!)
        }
      })
  },
})

export const {
  setProject,
  setPageError,
  setIsLoading,
  setIsInvited,
  setIsOwner,
  setInspectionArea,
  setInspectionAreas,
  patchInspectionAreas,
  reset,
} = slice.actions

export default slice.reducer
