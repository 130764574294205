import { FC, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, createStandaloneToast } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { BufferAttribute, BufferGeometry, DoubleSide, Mesh, Object3D, ShapeUtils, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { TOAST_ID } from 'hooks/Shape'

import { EDITOR_TOOLS } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'
import theme from 'config/theme'

import { PolygonPlaneMeshProps, ShapeKeyType } from 'interfaces/interfaces'

import { meterToMillimeter, validateSelectedShape } from 'services/Util'
import { getVolumeEstimationItems } from 'services/VolumeEstimation'

import { setHoveredShapeId } from '../store/editor'
import { Tools } from '../tools/setup'
import DistanceLabel from './DistanceLabel'

export const PolygonPlaneMesh: FC<PolygonPlaneMeshProps> = ({
  polygon,
  origin,
  transparent = true,
  label,
  labelBgColor = 'yellow',
  labelTextColor = 'black',
  color = 'hotpink',
  opacity = 1,
  showGuides,
  showGuidesLabel = showGuides,
  guideColor = '#000',
  guideStyle,
  guideThickness,
  guideLabelTextColor = '#000',
  guideLabelBgColor = '#fff',
  guideLabelOutlineColor,
  guideLabelBorderWidth = 2,
  guideOpacity = 1,
  selectable,
  isOpenLoop = false,
}) => {
  // Context
  const { selectedTool, shapes, inspectionItems, isDragging, changeSelectedShapeIds, updateMeshRefs } =
    useContext(EditorContext)

  // Use standalone toast as we're considered 'outside' of React component.
  const { toast } = useMemo(
    () =>
      createStandaloneToast({
        theme,
      }),
    [],
  )

  // Store
  const dispatch = useAppDispatch()
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // Refs
  const ref = useRef<Mesh | null>(null)
  // const outlineRef = useRef<Mesh>()

  // Current Editor tool
  const currentEditorTool = Tools.find((tool) => tool.key === selectedTool)
  const isShapeSelectable = currentEditorTool?.config?.selectableShapes?.includes(ShapeKeyType.POLYGON) || false

  // User need to click down an up on the same mesh to make a selection.
  // Store the down status on this state object for the up status checking
  const [isPointerDown, setIsPointerDown] = useState(false)

  // Vars
  const planeOrigin = useMemo(
    () => (origin ? new Vector3(...origin) : new Vector3(...polygon.center!)),
    [origin, polygon.center],
  )
  // const isSelected = selectedShapeIds.includes(polygon.shape_id)

  /**
   * Keep track of the mesh reference
   */
  useEffect(() => {
    if (polygon.shape_id) {
      updateMeshRefs({ [polygon.shape_id]: ref as MutableRefObject<Object3D> })
    }
  }, [updateMeshRefs, polygon.shape_id])

  const [geometry, setGeometry] = useState<BufferGeometry>(new BufferGeometry())

  useEffect(() => {
    if (!polygon.positions?.length || !polygon.vertices?.length || !polygon.center) return

    const buffer = new BufferGeometry()
    const positions = new Float32Array(polygon.positions.length * 3)
    polygon.positions.forEach((vertex, index) => {
      new Vector3(...vertex).toArray(positions, index * 3)
    })

    const indi = ShapeUtils.triangulateShape(
      polygon.vertices.map((v) => new Vector2(...v)),
      [],
    )

    buffer.setAttribute('position', new BufferAttribute(positions, 3))
    buffer.setIndex(indi.flat())
    buffer.translate(-polygon.center[0], -polygon.center[1], -polygon.center[2])
    buffer.computeVertexNormals()
    setGeometry(buffer)
  }, [polygon.positions, polygon.vertices, polygon.center])

  /**
   * Handle pointer enter event
   */
  const onPointerOver = useCallback(
    (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation()
      dispatch(setHoveredShapeId(polygon.shape_id))
    },
    [polygon.shape_id, dispatch],
  )

  /**
   * Handle pointer leave event
   */
  const onPointerOut = useCallback(
    (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation()
      dispatch(setHoveredShapeId(''))
      setIsPointerDown(false)
    },
    [dispatch],
  )

  /**
   * Handle pointer move event
   */
  const onPointerMove = useCallback(() => {
    // Prevent selecting shape when moving camera
    setIsPointerDown(false)
  }, [])

  /**
   * Handle pointer up event
   */
  const onPointerDown = useCallback(() => {
    setIsPointerDown(true)
  }, [])

  /**
   * Handle click event
   */
  const onClick = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      event.stopPropagation()

      if (
        (selectedTool !== EDITOR_TOOLS.MOVE && selectedTool !== EDITOR_TOOLS.DEPTH && !isShapeSelectable) ||
        isDragging
      ) {
        return
      }

      try {
        validateSelectedShape(
          polygon,
          ShapeKeyType.POLYGON,
          getVolumeEstimationItems(inspectionItems),
          currentEditorTool,
        )
      } catch (e) {
        if (!toast.isActive(TOAST_ID)) {
          toast({
            ...TOAST_CONFIG,
            id: TOAST_ID,
            status: 'error',
            position: 'bottom',
            title: e instanceof Error ? e.message : 'エラー',
          })
        }
        return
      }

      let newShapeIds = [...selectedShapeIds]

      // If only 1 volume can be selected, deselect all other volumes
      if (currentEditorTool && currentEditorTool.config?.volume?.onlyOneSelectable) {
        newShapeIds = newShapeIds
          .filter((id) => !shapes.polygons.find((poly) => poly.shape_id === id))
          .filter((id) => !shapes.cylinders.find((cylinder) => cylinder.shape_id === id))
      }

      const idIndex = newShapeIds.indexOf(polygon.shape_id)
      if (idIndex >= 0) {
        newShapeIds.splice(idIndex, 1)
      } else {
        newShapeIds.push(polygon.shape_id)
      }

      changeSelectedShapeIds(newShapeIds)
    },
    [
      polygon,
      currentEditorTool,
      inspectionItems,
      isDragging,
      isShapeSelectable,
      selectedShapeIds,
      selectedTool,
      shapes,
      toast,
      changeSelectedShapeIds,
    ],
  )

  /**
   * Handle pointer up event
   */
  const onPointerUp = useCallback(
    (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation()
      if (isPointerDown) {
        onClick(event)
      }
      setIsPointerDown(false)
    },
    [isPointerDown, onClick],
  )

  return (
    <group renderOrder={1}>
      {/* Plane mesh */}
      {polygon.positions.length > 2 && (
        <mesh
          ref={ref}
          onPointerOver={selectable ? onPointerOver : undefined}
          onPointerOut={selectable ? onPointerOut : undefined}
          onPointerDown={selectable ? onPointerDown : undefined}
          onPointerUp={selectable ? onPointerUp : undefined}
          onPointerMove={selectable ? onPointerMove : undefined}
          position={planeOrigin}
          geometry={geometry}
        >
          <meshBasicMaterial color={color} side={DoubleSide} transparent={transparent} opacity={opacity} />
        </mesh>
      )}

      {/* Guidelines between placed points */}
      {showGuides &&
        polygon.positions.map((point, index) =>
          (isOpenLoop && index === polygon.positions.length - 1) || index < polygon.positions.length - 1 ? (
            <DistanceLabel
              key={`${polygon.shape_id}-guide-${point.join('-')}`}
              id={`${polygon.shape_id}-guide-${point.join('-')}`}
              hideAnchors
              points={[point, polygon.positions[(index + 1) % polygon.positions.length]]}
              label={
                showGuidesLabel
                  ? `${Math.round(
                      meterToMillimeter(
                        new Vector3(...point).distanceTo(
                          new Vector3(...polygon.positions[(index + 1) % polygon.positions.length]),
                        ),
                      ),
                    )}mm`
                  : undefined
              }
              lineColor={guideColor}
              lineStyle={guideStyle}
              lineThickness={guideThickness}
              labelTextColor={guideLabelTextColor}
              labelBgColor={guideLabelBgColor}
              labelOutlineColor={guideLabelOutlineColor}
              labelBorderWidth={guideLabelBorderWidth}
              opacity={guideOpacity}
            />
          ) : undefined,
        )}

      {/* Label */}
      {!!label && ref.current && (
        <Html
          position={polygon.center}
          style={{ transform: 'translateX(-50%) translateY(-50%)', pointerEvents: 'none' }}
          zIndexRange={[1, 9]}
        >
          <Box
            backgroundColor={labelBgColor}
            px={2}
            fontSize="80%"
            fontWeight="bold"
            color={labelTextColor}
            whiteSpace="nowrap"
          >
            {label}
          </Box>
        </Html>
      )}
    </group>
  )
}
