/* istanbul ignore file */
import { AppError } from 'pages/_error/AppError'
import Dashboard from 'pages/dashboard/Dashboard'
import InvitedUsers from 'pages/dashboard/InvitedUsers'
import LandingPage from 'pages/landingPage/LandingPage'
import Blueprint from 'pages/projects/blueprint/Blueprint'
import OldInspectionAreaURLRedirect from 'pages/projects/common/OldInspectionAreaURLRedirect'
import RouteProjectsLayout from 'pages/projects/common/RouteProjectsLayout'
import Editor from 'pages/projects/editor/Editor'
import InspectionSheet from 'pages/projects/inspection-sheet/InspectionSheet'
import Register from 'pages/register/Register'
import UserAccount from 'pages/userAccount'
import UserList from 'pages/userList/UserList'
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import MaintenanceImage from 'assets/imgs/maintenance-page.png'

import { Protected } from 'contexts/Auth'

export const routes = createRoutesFromElements(
  <Route
    errorElement={
      <AppError description="エラーが発生しました。時間をおいて、再度実行してください。" image={MaintenanceImage} />
    }
  >
    <Route id="main-page" path="/" element={<LandingPage />} />
    <Route id="registration" path="/register" element={<Register />} />
    <Route path="/register?:email_address" element={<Register />} />
    <Route element={<Protected />}>
      <Route id="dashboard" path="/dashboard" element={<Dashboard />} />
      <Route id="user-list" path="/user-list" element={<UserList />} />
      <Route id="user" path="/user" element={<UserAccount />} />

      <Route id="project" path="projects" element={<RouteProjectsLayout />}>
        {/* TODO: add redirect to new paths */}
        <Route id="project-inspection-area" path=":project_id/inspection-area/:inspection_area_id">
          <Route id="inspection-area-editor" index element={<OldInspectionAreaURLRedirect />} />
          <Route id="inspection-area-blueprint" path="blueprints" element={<OldInspectionAreaURLRedirect />} />
          <Route
            id="inspection-area-inspection-sheet"
            path="inspection-sheet"
            element={<OldInspectionAreaURLRedirect />}
          />
        </Route>

        <Route id="project-editor" index path=":project_id/editor" element={<Editor />} />
        <Route id="project-blueprint" path=":project_id/blueprints" element={<Blueprint />} />
        <Route id="project-inspection-sheet" path=":project_id/inspection-sheet" element={<InspectionSheet />} />
        <Route id="project-invites" path=":project_id/users" element={<InvitedUsers />} />
      </Route>
    </Route>
    <Route path="*" element={<AppError description="ページが存在しません" image={MaintenanceImage} />} />
  </Route>,
)

/**
 * Creates a router.
 * Might seem weird to have a function that just returns a router, but it's needed for testing.
 * @returns
 */
export const getRouter = () => createBrowserRouter(routes)
