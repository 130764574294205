import { useCallback, useContext, useEffect, useMemo } from 'react'

import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import useDetectShapes from 'hooks/useDetectShapes'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'
import { PlaneSide, ShapeKeyType } from 'interfaces/shape'

import { detectShapes } from 'services/InspectionArea'

import { reset, resetWorkingPoints, setIsLoading } from '../store'

export default function useEditor(props: Editor): EditorConfig {
  const { selectedTool, isPreviousTool, inspectionSheet, changeIsJobRunning, updateToggledCollapses } = props

  // Store
  const dispatch = useAppDispatch()
  const planes = useSelector((state: RootState) => state.toolPlaneDetection.planes)
  const isLoading = useSelector((state: RootState) => state.toolPlaneDetection.isLoading)
  const isDirty = useSelector((state: RootState) => state.toolPlaneDetection.isDirty)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const project = useSelector((state: RootState) => state.page.project)
  const maskRegions = useSelector((state: RootState) => state.maskPCD.regions)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.PLANE, [selectedTool])

  // Hooks
  const { poll } = useDetectShapes(props, ShapeKeyType.POLYGON, {
    onFinished: useCallback(() => {
      dispatch(reset())
    }, [dispatch]),
  })

  /**
   * Toggle info panels when tool is selected
   */
  useEffect(() => {
    if (!isToolSelected) {
      if (isPreviousTool(EDITOR_TOOLS.PLANE) && selectedTool !== EDITOR_TOOLS.FOCUS) {
        dispatch(resetWorkingPoints())
      }

      return
    }

    updateToggledCollapses([EDITOR_COLLAPSE_TYPES.detecting])
  }, [isToolSelected, selectedTool, isPreviousTool, updateToggledCollapses, dispatch])

  /**
   * Start rebar detection
   */
  const startPlaneDetection = useCallback(async () => {
    if (!inspectionArea?.down_sampled_file?.path || !project || !inspectionSheet) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    dispatch(setIsLoading(true))
    changeIsJobRunning(true)
    dispatch(setAttentionText({ message: '平面を検出中...' }))

    const result = await detectShapes(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      inspectionSheet.inspection_sheet_id,
      inspectionArea.down_sampled_file?.path,
      ShapeKeyType.POLYGON,
      {
        ...INITIAL_SHAPE_STATE(),
        polygons: planes.map((label) => ({
          points: label.points.slice(0, -1),
          plane_side: PlaneSide.LOWER,
        })),
      },
      maskRegions.filter((region) => !region.invisible),
      showErrorModal,
    )

    if (result) {
      void poll(result)
      return true
    }

    dispatch(setIsLoading(false))
    changeIsJobRunning(false)
    dispatch(setAttentionText({ message: '' }))
    return true
  }, [
    inspectionArea,
    inspectionSheet,
    project,
    planes,
    maskRegions,
    getAccessToken,
    showErrorModal,
    poll,
    changeIsJobRunning,
    dispatch,
  ])

  /**
   * Reset on unmount
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    buttons: {
      submit: {
        key: 'plane-detection-submit',
        label: `面積を計算`,
        loadingLabel: '計算中',
        onClick: startPlaneDetection,
        isShown: useCallback(() => isLoading || isToolSelected, [isLoading, isToolSelected]),
        isLoading: useCallback(() => isLoading, [isLoading]),
        isDisabled: useCallback(() => !planes.length, [planes]),
      },
      reset: {
        onClick: useCallback(() => dispatch(reset()), [dispatch]),
        isShown: useCallback(() => isToolSelected || isLoading, [isToolSelected, isLoading]),
        isDisabled: useCallback(() => isLoading || !isDirty, [isLoading, isDirty]),
      },
      undo: {
        isShown: useCallback(() => isToolSelected || isLoading, [isToolSelected, isLoading]),
        isDisabled: useCallback(() => isLoading || !isDirty, [isLoading, isDirty]),
      },
    },
  }
}
