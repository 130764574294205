import { ToolbarCommentIcon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

const RebarDetection: EditorTool = {
  key: EDITOR_TOOLS.COMMENT,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.COMMENT_THREAD.CREATE.includes(userType),
  toolbar: {
    icon: <ToolbarCommentIcon width="50%" height="50%" />,
    label: EDITOR_TOOLS_LABELS.COMMENT,
    category: ToolbarCategory.Manipulate,
  },
}

export default RebarDetection
