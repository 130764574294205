/* istanbul ignore file */
import { Router as RemixRouter } from '@remix-run/router'

export enum PageError {
  InternalServerError,
  NotFound,
  Forbidden,
}

export interface Router {
  router: RemixRouter | null
}

export interface ErrorPayload {
  code: number
  message: string
  response: {
    status_id: number
    status_text: string
  }
}
