import { FC, useContext, useState } from 'react'

import { Button, MenuItem, Square } from '@chakra-ui/react'
import fileDownload from 'js-file-download'
import { TbCloudDownload } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { downloadCadFile } from 'services/AWS'
import { getSignedUrlForOriginFile } from 'services/InspectionArea'

const PCDExportButton: FC = () => {
  // Store
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  // Context
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)
  const [downloadText, setDownloadText] = useState('')

  const download = async () => {
    if (!project?.project_id || !inspectionArea?.inspection_area_id) {
      showModal({
        body: '工事が存在しません。',
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    if (!inspectionArea?.origin_file?.name) {
      setIsLoading(false)
      showModal({
        body: 'データが存在しません。',
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    //* Get signed URL for origin file
    const downloadUrl = await getSignedUrlForOriginFile(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      showErrorModal,
    )
    if (!downloadUrl) {
      setIsLoading(false)
      return false
    }

    //* Download file
    const downloadResult = await downloadCadFile(downloadUrl, setDownloadText, showErrorModal)
    if (!downloadResult) {
      setIsLoading(false)
      return false
    }

    const { blob, type } = downloadResult
    const fileBlob = new Blob(blob, { type })
    fileDownload(fileBlob, inspectionArea.origin_file.name)

    setIsLoading(false)
    return true
  }

  return (
    <MenuItem
      as={Button}
      rightIcon={
        <Square fontSize={{ base: 'xl', xl: 'lg' }}>
          <TbCloudDownload />
        </Square>
      }
      fontSize={{ base: 'md', xl: 'sm' }}
      onClick={download}
      isLoading={isLoading}
      isDisabled={isLoading}
      spinnerPlacement="end"
      loadingText={downloadText.substring(downloadText.indexOf('.'))}
      justifyContent="space-between"
      whiteSpace="nowrap"
      textAlign="left"
      data-testid="pcd-export-button"
      w="100%"
    >
      Download PCD
    </MenuItem>
  )
}

export default PCDExportButton
