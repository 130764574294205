/* istanbul ignore file */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LayerStatusExtended } from 'interfaces/attribute'
import { DistanceLabelProps } from 'interfaces/canvas'
import { InspectionItem } from 'interfaces/inspection'

export const TEMP_ID_PREFIX = 'temp_polyline'
export const WORKING_ID_PREFIX = 'working_polyline'

export interface Polyline extends InspectionItem, LayerStatusExtended {
  completed?: boolean
}

export interface PolylineState {
  /**
   * Loading state
   */
  isLoading: boolean

  /**
   * Dirty state
   */
  isDirty: boolean

  /**
   * Polylines
   */
  polylines: Polyline[]

  /**
   * Working polylines.
   */
  workingPolylines: Polyline[]

  /**
   * Working label (the one user is manipulating).
   * DO NOT use this other than useMainCanvas.
   * This is a high-update state and should be used only Main Canvas
   * to avoid unnecessary re-renders.
   */
  workingLabel?: DistanceLabelProps

  /**
   * Flag to check if the first point is placed (for working label).
   */
  firstPointPlaced?: boolean
}

const initialState: PolylineState = {
  isLoading: false,
  isDirty: false,
  polylines: [],
  workingPolylines: [],
}

export const slice = createSlice({
  name: 'editor/tools/polyline',
  initialState,
  reducers: {
    // ## Editor states
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsDirty(state, action: PayloadAction<boolean>) {
      state.isDirty = action.payload
    },

    // ## Polyline from DB
    setPolylines: (state, action: PayloadAction<Polyline[]>) => {
      state.polylines = action.payload
    },
    addPolyline: (state, action: PayloadAction<Polyline>) => {
      state.polylines.push(action.payload)
    },
    patchPolylines: (state, action: PayloadAction<Polyline[]>) => {
      state.polylines = state.polylines.map(
        (polyline) => action.payload.find((p) => p.inspection_item_id === polyline.inspection_item_id) || polyline,
      )
    },

    // ## Working Polyline
    setWorkingPolylines: (state, action: PayloadAction<Polyline[]>) => {
      state.workingPolylines = action.payload
    },
    addWorkingPolyline: (state, action: PayloadAction<Polyline>) => {
      state.workingPolylines.push(action.payload)
    },
    patchWorkingPolyline: (state, action: PayloadAction<Polyline>) => {
      state.workingPolylines = state.workingPolylines.map((polyline) =>
        polyline.inspection_item_id === action.payload.inspection_item_id ? action.payload : polyline,
      )
    },
    deleteWorkingPolylines: (state, action: PayloadAction<string[]>) => {
      const toBeDeletedPolylines = state.workingPolylines.filter((polyline) =>
        action.payload.includes(polyline.inspection_item_id!),
      )
      const toBeDeletedPolylineIsNonCompleted = toBeDeletedPolylines.some(
        (polyline) => action.payload.includes(polyline.inspection_item_id!) && !polyline.completed,
      )

      if (toBeDeletedPolylineIsNonCompleted) {
        state.workingLabel = undefined
        state.firstPointPlaced = false
      }

      state.workingPolylines = state.workingPolylines.filter(
        (polyline) => !action.payload.includes(polyline.inspection_item_id!),
      )

      if (state.workingPolylines.length === 0) {
        state.isDirty = false
      }
    },
    setWorkingLabel: (state, action: PayloadAction<DistanceLabelProps | undefined>) => {
      state.workingLabel = action.payload
    },
    setFirstPointPlaced: (state, action: PayloadAction<boolean>) => {
      state.firstPointPlaced = action.payload
    },
    resetWorkingPolylines: (state) => {
      state.workingPolylines = []
      state.isDirty = false
      state.workingLabel = undefined
      state.firstPointPlaced = false
    },
    deleteNonCompletedWorkingPolylines: (state) => {
      state.workingPolylines = state.workingPolylines.filter((polyline) => polyline.completed)

      if (state.workingPolylines.length === 0) {
        state.isDirty = false
      }
    },
    undoLastWorkingPolyline: (state) => {
      // Rebuild the working label by having the first point as the last point of the last polyline.
      // And the second point as-is.
      const rebuildWorkingLabel = (polyline: Polyline | null) => {
        if (state.workingLabel) {
          let points = [...state.workingLabel.points]

          // Resets working label by keeping the last point which should be where the user's mouse is.
          if (!polyline) {
            points = state.workingLabel.points.slice(-1)
          } else if (polyline?.polyline_length?.positions_for_distance) {
            points = [
              polyline.polyline_length.positions_for_distance.slice(-1)[0],
              state.workingLabel.points[1],
            ].filter(Boolean)
          }

          state.workingLabel.points = points
        }
      }

      const lastWorkingPolyline = state.workingPolylines.slice(-1)[0]

      if (
        state.workingLabel &&
        state.workingLabel.points.length > 0 &&
        state.firstPointPlaced &&
        (!lastWorkingPolyline || lastWorkingPolyline.completed)
      ) {
        state.firstPointPlaced = false
        state.workingLabel.points = []

        if (!lastWorkingPolyline) {
          state.isDirty = false
        }

        return
      }

      if (
        lastWorkingPolyline?.polyline_length?.positions_for_distance &&
        (!lastWorkingPolyline.completed ||
          (lastWorkingPolyline.completed &&
            (!state.workingLabel || (state.workingLabel && state.workingLabel.points.length <= 1))))
      ) {
        lastWorkingPolyline.polyline_length.positions_for_distance.pop()

        // if we're re-opening a completed polyline
        if (lastWorkingPolyline?.completed) {
          lastWorkingPolyline.completed = false
          state.firstPointPlaced = true
          rebuildWorkingLabel(lastWorkingPolyline)
        }
        // If the last polyline has no points, remove it.
        else if (lastWorkingPolyline.polyline_length.positions_for_distance.length === 0) {
          state.workingPolylines.pop()
          state.firstPointPlaced = false

          if (state.workingLabel) state.workingLabel.points = []

          // If there are no more working polylines, reset the state.
          if (state.workingPolylines.length === 0) {
            state.isDirty = false

            // If the working label has more than 1 point, reset it.
            if (state.workingLabel) {
              rebuildWorkingLabel(null)
            }
          } else {
            rebuildWorkingLabel(null)
          }
        } else if (state.workingLabel) {
          // Rebuild working label.
          rebuildWorkingLabel(lastWorkingPolyline)
        }
      } else if (state.workingLabel) {
        rebuildWorkingLabel(null)
      }
    },

    // Reset
    reset: () => initialState,
  },
})

export const {
  // Editor states
  setIsLoading,
  setIsDirty,

  // Polyline from DB
  setPolylines,
  addPolyline,
  patchPolylines,

  // Working Polyline
  setWorkingPolylines,
  addWorkingPolyline,
  patchWorkingPolyline,
  deleteWorkingPolylines,
  deleteNonCompletedWorkingPolylines,
  resetWorkingPolylines,
  undoLastWorkingPolyline,

  // Working label / drawing
  setWorkingLabel,
  setFirstPointPlaced,

  // Reset
  reset,
} = slice.actions

export default slice.reducer
