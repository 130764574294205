import './Editor.css'

import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Flex, Input, Text, Tooltip, VStack } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import LoadingPage from 'components/LoadingPage'
import PageErrorHandler from 'components/PageErrorHandler'
import { uniq } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { InspectionAreaDownSampleStatus } from 'project-dashboard-library/dist/interfaces/inspectionArea'
import { useCookies } from 'react-cookie'
import { isMobile, isTablet } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'
import { patchInspectionAreas, setInspectionArea } from 'store/page'
import { Box3, Mesh, PerspectiveCamera, Vector2, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import { AddIcon, CheckCircleIcon, ResetIcon } from 'assets/icons'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { useUploadPcd } from 'hooks/uploadPcd'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

import {
  COOKIE_EXPIRE,
  DEFAULT_EDITOR_TOOL,
  EDITOR_BACKGROUND_COOKIE_NAME,
  EDITOR_COLLAPSE_TYPES,
  EDITOR_CUBOID_KEY,
  EDITOR_MEASURE_KEYS,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_SHAPE_KEYS,
  EDITOR_TOOLS,
  EDITOR_TOOL_KEYS,
  INITIAL_DEPTH_ESTIMATION_TYPES,
  MODAL_TYPES,
  USER_TYPES,
} from 'config/constants'
import { EDITOR_ACTION_BUTTON_MIN_WIDTH, EDITOR_DEFAULT_BACKGROUND } from 'config/styles'

import { Editor as EditorInterface } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'
import { INSPECTION_ITEM_TYPES } from 'interfaces/inspection'
import {
  AnchorPoints,
  Anchors,
  CommentImage,
  CommentUploadedImage,
  CuboidDirection,
  DepthType,
  FocusedPoint,
  InspectionItem,
  InspectionSheet,
  LayerStatus,
  LayerStatusExtended,
  MeshPoints,
  MeshRefs,
  PlaneSide,
  PointArray,
  Polygon,
  Shape,
  ShapeKey,
  Shapes,
  ShapesId,
} from 'interfaces/interfaces'

import { getDepthEstimationItems, getPlaneToCylinderDistanceAnchors } from 'services/DepthEstimation'
import { calculateCenterAndDistance } from 'services/Editor'
import { deleteShapes, getShapes } from 'services/InspectionArea'
import { getInspectionItems, getInspectionSheets } from 'services/InspectionSheet'
import { getMaskingRegions } from 'services/MaskingRegion'
import { updateShape } from 'services/Shape'
import { getNonDeletedLayers, needClearAnchorFrames } from 'services/Util'
import { decideActionPermission } from 'services/Validation'
import {
  getStepText as getVolumeEstimationHelpText,
  getVolumeEstimationItem,
  getVolumeEstimationItems,
} from 'services/VolumeEstimation'

import AttentionText from '../common/AttentionText/AttentionText'
import { setAttentionText } from '../common/AttentionText/store/attentionText'
import { useComments } from '../common/Comments/hooks/comments'
import Navbar from '../common/Navbar'
import { useEditor } from './hooks/editor'
import InfoPanels from './infoPanels/InfoPanels'
import MainCanvas from './mainCanvas/MainCanvas'
import { setCuboidAnchor, setEditingCuboid } from './shapes/cuboid/store'
import { CursorState, setCursor, setHidePlaneLabel, setSelectedShapeIds } from './store/editor'
import SubToolbar from './toolbar/SubToolbar'
import Toolbar from './toolbar/Toolbar'
import TransformControlsToolbar from './toolbar/TransformControls'
import { resetWorking as resetWorkingGrid } from './tools/Grid/store'
import { setMaskRegions } from './tools/MaskPCD/store'
import { reset as resetPlaneDetection } from './tools/PlaneDetection/store'
import { resetWorkingPolylines } from './tools/Polyline/store'
import { reset as resetRebarDetection } from './tools/RebarDetection/store'
import { reset as resetVolumeEstimation } from './tools/VolumeEstimation/Polygon/store'
import { Tools, getCurrentEditorTool } from './tools/setup'

const Editor: FC = () => {
  // Router
  const location = useLocation()
  const navigate = useNavigate()
  const { project_id } = useParams<{ project_id: string }>()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Hooks
  useComments()
  useEditor()
  const { processSelectedFile, uploadPcd } = useUploadPcd()

  // Store
  const dispatch = useAppDispatch()
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const cuboidAnchor = useSelector((state: RootState) => state.cuboid.anchor)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const userType = useSelector((state: RootState) => state.user.userType) as keyof typeof USER_TYPES
  const userLoaded = useSelector((state: RootState) => state.user.userLoaded)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // Page title
  useDocumentTitle(
    `${inspectionArea?.inspection_area_name || 'Hatsuly'}${project ? ` · ${project?.project_name}` : ''}`,
  )

  // Tool Data
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const toolVolumePolygonIsDirty = useSelector((state: RootState) => state.toolVolumeEstimationPolygon.isDirty)
  const toolRebarDetectionIsDirty = useSelector((state: RootState) => state.toolRebarDetection.isDirty)
  const toolPlaneDetectionIsDirty = useSelector((state: RootState) => state.toolPlaneDetection.isDirty)
  const toolPolylineIsDirty = useSelector((state: RootState) => state.toolPolyline.isDirty)

  const editorRef = useRef<HTMLDivElement>(null)
  const cameraRef = useRef<PerspectiveCamera>(null)
  const arcballControlsRef = useRef<ArcballControls>(null)

  // File upload
  const uploadFileRef = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)

  //* 工事データアクセス用
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const [cookies, setCookie] = useCookies([EDITOR_BACKGROUND_COOKIE_NAME])
  const { user } = useAuth0()

  // Toolbar
  const [selectedTool, setSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [selectedSubTool, setSelectedSubTool] = useState<string>()
  const [prevSelectedTool, setPrevSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [prevPrevSelectedTool, setPrevPrevSelectedTool] = useState(DEFAULT_EDITOR_TOOL)
  const [isJobRunning, setIsJobRunning] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isPointCloudInvisible, setIsPointCloudInvisible] = useState(false)
  const [selectedPoint, setSelectedPoint] = useState<FocusedPoint>()
  const [hoveredPoint, setHoveredPoint] = useState<FocusedPoint>()
  const [isLayerModifying, setIsLayerModifying] = useState(false)
  const [pointSize, setPointSize] = useState<number>()
  const [cuboidDirection, setCuboidDirection] = useState<CuboidDirection>()
  const [toggledCollapses, setToggledCollapses] = useState<string[]>(Object.values(EDITOR_COLLAPSE_TYPES))
  const [selectedInspectionItem, setSelectedInspectionItem] = useState<InspectionItem>()
  const [commentPopupPosition, setCommentPopupPosition] = useState<Vector3>()
  const [collidingShapeIds, setCollidingShapeIds] = useState<string[]>([])

  // Project
  const [inspectionSheet, setInspectionSheet] = useState<InspectionSheet>()
  const [inspectionItems, setInspectionItems] = useState<InspectionItem[]>([])

  // Data states
  const [anchors, setAnchors] = useState<Anchors>(INITIAL_SHAPE_STATE())
  const [distanceAnchors, setDistanceAnchors] = useState<AnchorPoints[]>([])
  const [shapes, setShapes] = useState<Shapes>(INITIAL_SHAPE_STATE())
  const [processingAnchor, setProcessingAnchor] = useState<PointArray>()
  const [isToolProcessing, setIsToolProcessing] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [meshRefs, setMeshRefs] = useState<MeshRefs>()
  const [meshPoints, setMeshPoints] = useState<MeshPoints>({})
  const actionPanelRef = useRef<HTMLDivElement>(null)
  const [isMainSheetModalOpen, setIsMainSheetModalOpen] = useState(false)
  const [selectionBoundaryBoxVectors] = useState<Vector3[][]>([])
  const [depthEstimationTypes, setDepthEstimationTypes] = useState<DepthType[]>(INITIAL_DEPTH_ESTIMATION_TYPES)

  //* Prefixed cube positions
  const [movingPrefixedPosition, setMovingPrefixedPosition] = useState('')

  // Comment
  const [commentName, setCommentName] = useState('')
  const [commentImages, setCommentImages] = useState<CommentImage[]>([])
  const [commentBody, setCommentBody] = useState('')
  const [commentDeletingImageIndexes, setCommentDeletingImageIndexes] = useState<number[]>([])
  const [commentEditingCaptions, setCommentEditingCaptions] = useState<string[]>([])
  const [commentImageHandlingIndex, setCommentImageHandlingIndex] = useState(-1)
  const [commentOpeningImageIds, setCommentOpeningImageIds] = useState<string[]>([])
  const [commentUploadedImages, setCommentUploadedImages] = useState<CommentUploadedImage[]>([])

  const addAnchor = (addedPoint: PointArray) => {
    const shapeKey = EDITOR_TOOL_KEYS[selectedTool]

    if (!shapeKey) {
      return
    }

    const anchorPoints = [...getNonDeletedLayers(anchors[shapeKey])] as AnchorPoints[]
    const lastAnchor = anchorPoints.length ? anchorPoints[anchorPoints.length - 1] : undefined

    const trackProps: { [name: string]: unknown } = {
      'Anchor amount': 1,
      Tool: selectedTool,
    }

    // If there is no anchor yet, or the last anchor already has enough required points
    // -> add a new anchor
    if (!lastAnchor?.points?.length || lastAnchor.points.length >= EDITOR_REQUIRED_ANCHORS[shapeKey]) {
      const upperPlaneExists = anchorPoints.some((anchor) => anchor.plane_side === 'upper')
      const planeSide = upperPlaneExists ? 'lower' : 'upper'

      anchorPoints.push({
        points: [addedPoint],
        diameter: shapeKey === EDITOR_SHAPE_KEYS.PLANES ? undefined : baseDiameter,
        plane_side: shapeKey === EDITOR_SHAPE_KEYS.PLANES ? planeSide : undefined,
      })
      setIsToolProcessing(true)
      setSelectedPoint({ anchorIndex: anchorPoints.length - 1, pointIndex: 0, shapeKey })

      // Analytics
      trackProps['Anchor amount'] = 1
      if (selectedTool === EDITOR_TOOLS.VOLUME) {
        trackProps['Plane side'] = planeSide
      }
    } else {
      // Else, add this point as the end point of the last anchor
      lastAnchor.points.push(addedPoint)
      anchorPoints[anchorPoints.length - 1] = lastAnchor
      // Keep drawing live line if required points are not enough
      const isProcessing = lastAnchor.points.length < EDITOR_REQUIRED_ANCHORS[shapeKey]
      setIsToolProcessing(isProcessing)
      setSelectedPoint({ anchorIndex: anchorPoints.length - 1, pointIndex: lastAnchor.points.length - 1, shapeKey })

      // Set next help text if exists when there's enough points for current anchor
      if (selectedTool === EDITOR_TOOLS.VOLUME && !isProcessing) {
        dispatch(setAttentionText(getVolumeEstimationHelpText(anchorPoints)))
      }
      if (selectedTool === EDITOR_TOOLS.CYLINDER && !isProcessing) {
        dispatch(
          setAttentionText({
            message:
              '鉄筋の直径は右側のパネルから指定できます。\n選択が完了したら右下の「鉄筋を検出」をクリックしてください。',
          }),
        )
      }

      // Analytics
      trackProps['Anchor amount'] = lastAnchor.points.length
      if (selectedTool === EDITOR_TOOLS.VOLUME) {
        trackProps['Plane side'] = lastAnchor.plane_side
      }
    }

    setProcessingAnchor(undefined)
    setAnchors({ ...anchors, [shapeKey]: anchorPoints })
    setToggledCollapses([EDITOR_COLLAPSE_TYPES.detecting, EDITOR_COLLAPSE_TYPES.diameter])
  }

  // There are two event listening for adding distance anchor.
  // 1: click on point cloud object
  // 2: click on mesh object
  // To prevent duplicating point, select the point which closer to camera
  const addDistanceAnchor = useCallback(
    (addedPoint: PointArray, mousePosition: Vector2, cameraDistance: number, timestamp: number) => {
      const anchorPoints = [...distanceAnchors]
      const lastAnchor = anchorPoints.length ? anchorPoints[anchorPoints.length - 1] : undefined

      // Check if the last picked point is from the same mouse click event with this being added point
      if (lastAnchor && lastAnchor.points.length === lastAnchor.pickedInfo?.length) {
        const lastPickedInfo = lastAnchor.pickedInfo[lastAnchor.points.length - 1]
        if (lastPickedInfo.timestamp === timestamp && lastPickedInfo.mousePosition.equals(mousePosition)) {
          // If true, take the one that closer to camera
          // Switching point will not update states of isToolProcessing and selectedPoint
          if (cameraDistance < lastPickedInfo.cameraDistance) {
            lastAnchor.points[lastAnchor.points.length - 1] = addedPoint
            lastAnchor.pickedInfo[lastAnchor.points.length - 1] = { mousePosition, cameraDistance, timestamp }
            // save back the points
            anchorPoints[anchorPoints.length - 1] = lastAnchor
          }
          // Clear the processing state and stop this adding process here
          setProcessingAnchor(undefined)
          setDistanceAnchors(anchorPoints)
          return
        }
      }

      if (!lastAnchor?.points.length || lastAnchor.points.length >= 2) {
        // If there is no anchor yet, or the last anchor already has enough required points
        // -> add a new anchor
        anchorPoints.push({
          points: [addedPoint],
          pickedInfo: [
            {
              mousePosition,
              cameraDistance,
              timestamp,
            },
          ],
        })
        setIsToolProcessing(true)
        setSelectedPoint({
          anchorIndex: anchorPoints.length - 1,
          pointIndex: 0,
          shapeKey: EDITOR_MEASURE_KEYS.DISTANCE,
        })
      } else {
        // Else, add this point as the end point of the last anchor
        lastAnchor.points.push(addedPoint)
        if (lastAnchor.pickedInfo) {
          lastAnchor.pickedInfo.push({
            mousePosition,
            cameraDistance,
            timestamp,
          })
        }
        const distanceFactors = calculateCenterAndDistance(lastAnchor)
        lastAnchor.center = distanceFactors?.[0]
        lastAnchor.distance = distanceFactors?.[1]

        anchorPoints[anchorPoints.length - 1] = lastAnchor
        // Keep drawing live line if required points are not enough
        setIsToolProcessing(lastAnchor.points.length < 2)
        setSelectedPoint({
          anchorIndex: anchorPoints.length - 1,
          pointIndex: lastAnchor.points.length - 1,
          shapeKey: EDITOR_MEASURE_KEYS.DISTANCE,
        })
      }

      setProcessingAnchor(undefined)
      setDistanceAnchors(anchorPoints)
      setToggledCollapses([
        EDITOR_COLLAPSE_TYPES.distance,
        EDITOR_COLLAPSE_TYPES.settings,
        EDITOR_COLLAPSE_TYPES.detected,
      ])
    },
    [distanceAnchors],
  )

  const updateShapeStatus = useCallback((props: LayerStatusExtended, index: number, shapeKey: ShapeKey) => {
    setShapes((shps) => {
      const statuses = [...shps[shapeKey]]

      if (index < 0 || index >= statuses.length) {
        return shps
      }

      statuses[index] = { ...statuses[index], ...props }

      return { ...shps, [shapeKey]: statuses }
    })
  }, [])

  const updateShapeStatusById = useCallback(
    (props: LayerStatus, id: string, shapeKey: ShapeKey) => {
      const statuses = [...shapes[shapeKey]]
      const index = statuses.findIndex((status) => status.shape_id === id)
      if (index >= 0) updateShapeStatus(props, index, shapeKey)
    },
    [shapes, updateShapeStatus],
  )

  const updateShapeStatusByIds = useCallback(
    (shapeKey: ShapeKey, shps: Pick<Shape, 'shape_id' | keyof LayerStatus>[]) => {
      setShapes((prev) => {
        const newShapes = { ...prev }
        shps.forEach((shape) => {
          const shapeIndex = newShapes[shapeKey].findIndex((shp) => shp.shape_id === shape.shape_id)
          if (shapeIndex >= 0) {
            newShapes[shapeKey][shapeIndex] = { ...newShapes[shapeKey][shapeIndex], ...shape }
          }
        })
        return newShapes
      })
    },
    [],
  )

  const updateAllShapesStatus = useCallback(
    (props: Record<'invisible', boolean>, shapeKey: ShapeKey) => {
      const statuses = [...shapes[shapeKey]].map((status) => ({ ...status, ...props }))
      setShapes({ ...shapes, [shapeKey]: statuses })
    },
    [shapes],
  )

  const updateAnchorPoint = useCallback(
    (pointInfo: FocusedPoint, point: PointArray) => {
      const points = [...anchors[pointInfo.shapeKey as ShapeKey]]
      points[pointInfo.anchorIndex].points[pointInfo.pointIndex] = point
      setAnchors({ ...anchors, [pointInfo.shapeKey]: points })
    },
    [anchors],
  )
  const updateDistanceAnchorPoint = useCallback(
    (pointInfo: FocusedPoint, point: PointArray) => {
      const points = [...distanceAnchors]
      points[pointInfo.anchorIndex].points[pointInfo.pointIndex] = point

      const distanceFactors = calculateCenterAndDistance(points[pointInfo.anchorIndex])
      points[pointInfo.anchorIndex].center = distanceFactors?.[0]
      points[pointInfo.anchorIndex].distance = distanceFactors?.[1]

      setDistanceAnchors(points)
    },
    [distanceAnchors],
  )

  const updateAnchorStatus = useCallback(
    (props: Record<string, boolean>, index: number, shapeKey: ShapeKey) => {
      const statuses = [...anchors[shapeKey]]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      const updatedAnchors = { ...anchors, [shapeKey]: statuses }
      setAnchors(updatedAnchors)

      // Stop any active operations when deleting an anchor that are being worked on
      if (props.deleted === true && statuses[index].points.length < EDITOR_REQUIRED_ANCHORS[shapeKey]) {
        setProcessingAnchor(undefined)
        setIsToolProcessing(false)
      }

      // Update help text if exists when there's enough points for current anchor
      if (selectedTool === EDITOR_TOOLS.VOLUME) {
        dispatch(
          setAttentionText(getVolumeEstimationHelpText(getNonDeletedLayers(updatedAnchors.planes) as AnchorPoints[])),
        )
      }
    },
    [anchors, selectedTool, dispatch],
  )
  const updateAllAnchorsStatus = useCallback(
    (props: Record<string, boolean>, shapeKey: ShapeKey) => {
      const statuses = [...anchors[shapeKey]].map((status) => ({ ...status, ...props }))
      setAnchors({ ...anchors, [shapeKey]: statuses })
    },
    [anchors],
  )

  const updateDistanceAnchorStatus = useCallback(
    (props: Record<string, boolean>, index: number) => {
      const statuses = [...distanceAnchors]

      if (index < 0 || index >= statuses.length) {
        return
      }

      statuses[index] = { ...statuses[index], ...props }
      setDistanceAnchors(statuses)
    },
    [distanceAnchors],
  )
  const updateAllDistanceAnchorsStatus = useCallback(
    (props: Record<string, boolean>) => {
      const statuses = distanceAnchors.map((status) => ({ ...status, ...props }))
      setDistanceAnchors(statuses)
    },
    [distanceAnchors],
  )

  const updateAllSelectedShapesStatus = useCallback(
    (props: Record<'invisible', boolean>) => {
      const newStatuses = { ...shapes }

      selectedShapeIds.forEach((id) => {
        const shapeIndex = shapes[EDITOR_SHAPE_KEYS.CYLINDERS].findIndex((shape) => shape.shape_id === id)
        if (shapeIndex >= 0) {
          newStatuses.cylinders[shapeIndex] = { ...newStatuses.cylinders[shapeIndex], ...props }
        }
      })

      setShapes(newStatuses)
    },
    [selectedShapeIds, shapes],
  )

  const updateSelectedPointValue = useCallback(
    (newValue: PointArray) => {
      if (!selectedPoint) {
        return
      }
      if (selectedPoint.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE) {
        const anchorPoints = [...distanceAnchors]
        anchorPoints[selectedPoint.anchorIndex].points[selectedPoint.pointIndex] = newValue

        const distanceFactors = calculateCenterAndDistance(anchorPoints[selectedPoint.anchorIndex])
        anchorPoints[selectedPoint.anchorIndex].center = distanceFactors?.[0]
        anchorPoints[selectedPoint.anchorIndex].distance = distanceFactors?.[1]

        setDistanceAnchors(anchorPoints)
        // cannot update anchor point position for cuboid detection
      } else if (selectedPoint.shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS) {
        const anchorPoints = [...anchors[selectedPoint.shapeKey]]
        anchorPoints[selectedPoint.anchorIndex].points[selectedPoint.pointIndex] = newValue

        setAnchors({ ...anchors, [selectedPoint.shapeKey]: anchorPoints })
      }
    },
    [anchors, distanceAnchors, selectedPoint],
  )
  const updateSelectedPointDiameter = useCallback(
    (newValue: number) => {
      if (!selectedPoint) {
        return
      }
      if (selectedPoint.shapeKey === EDITOR_CUBOID_KEY) {
        if (cuboidAnchor) {
          dispatch(setCuboidAnchor({ ...cuboidAnchor, diameter: newValue }))
        }
      } else {
        const anchorPoints = [...anchors[selectedPoint.shapeKey as ShapeKey]]
        anchorPoints[selectedPoint.anchorIndex].diameter = newValue
        setAnchors({ ...anchors, [selectedPoint.shapeKey]: anchorPoints })
      }
    },
    [anchors, cuboidAnchor, selectedPoint, dispatch],
  )

  const fetchInspectionSheet = useCallback(async () => {
    if (!project_id || !inspection_area_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const inspectionSheets = await getInspectionSheets(token, project_id, inspection_area_id, showErrorModal)

    if (!inspectionSheets.length || !inspectionSheets[0].inspection_sheet_id) {
      showErrorModal('帳票が存在しません。')
      return false
    }

    setInspectionSheet(inspectionSheets[0])

    return true
  }, [project_id, inspection_area_id, getAccessToken, showErrorModal])

  const fetchInspectionItems = useCallback(async () => {
    if (!project_id || !inspection_area_id || !inspectionSheet) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const items = await getInspectionItems(
      token,
      project_id,
      inspection_area_id,
      inspectionSheet.inspection_sheet_id,
      showErrorModal,
    )

    // reset depth estimation types
    setDepthEstimationTypes([...INITIAL_DEPTH_ESTIMATION_TYPES])
    setInspectionItems(items)

    return true
  }, [project_id, inspection_area_id, inspectionSheet, getAccessToken, showErrorModal])

  useEffect(() => {
    if (inspectionSheet) void fetchInspectionItems()
  }, [inspectionSheet, fetchInspectionItems])

  /**
   * Update shapes to state while keeping the old shape's invisible status
   */
  const updateShapes = useCallback(
    (newShapes: Shapes) => {
      setShapes((oldShapes) => ({
        cylinders: newShapes.cylinders.map((cyl) =>
          updateShape(
            cyl,
            oldShapes.cylinders.find((old) => old.shape_id === cyl.shape_id),
          ),
        ),
        polygons: newShapes.polygons.map((poly) =>
          updateShape(
            poly,
            oldShapes.polygons.find((old) => old.shape_id === poly.shape_id),
          ),
        ),
      }))
    },
    [setShapes],
  )

  /**
   * Given 2 planes, find all cylinders that are within the volume of the 2 planes
   * @param planes Array of plane shape ids
   */
  const findCylindersWithinVolumePlanes = useCallback(
    (planes: string[]): string[] => {
      if (meshPoints && meshRefs) {
        const points = planes
          .map((id) => meshPoints[id])
          .filter((value) => value !== undefined)
          .flat()

        if (points.length) {
          // ⚠️ DEBUG ONLY. Don't uncomment on commit
          // Leaving this here as it can be difficult to debug without it
          // setSelectionBoundaryBoxVectors([...selectionBoundaryBoxVectors, points])

          const boxBB = new Box3().setFromPoints(points)
          const cylinders = shapes.cylinders
            .map((cylinder) => {
              const cylinderBB = new Box3().setFromObject(meshRefs[cylinder.shape_id].current as Mesh)

              if (boxBB.containsBox(cylinderBB) || boxBB.intersectsBox(cylinderBB)) {
                return cylinder.shape_id
              }
              return ''
            })
            .filter((value) => value !== '')

          return cylinders
        }
      }
      return []
    },
    [shapes, meshRefs, meshPoints],
  )

  /**
   * Add or remove additional shape ids when selecting a shape
   * TODO: Figure out how to do in modules.
   * @param shapeIds All shapeIDs to be selected including current ones
   */
  const addAdditionalSelectedShapeIds = useCallback(
    (shapeIds: string[], newTool?: string): string[] => {
      let newCollection = [...shapeIds]
      const volumeItems = getVolumeEstimationItems(inspectionItems)
      const currentTool = getCurrentEditorTool(newTool || selectedTool)

      if (volumeItems.length) {
        // If tool is configured only for single selectable volume,
        // when there's more than 1 volume selected, deselect all except for one.
        if (currentTool?.config?.volume?.onlyOneSelectable) {
          const selectedVolumesByPlanes = newCollection.reduce<Record<string, number>>((collection, id) => {
            const selectedVolume = volumeItems.find((item) => (item.shape_ids.polygons || []).includes(id))
            if (selectedVolume) {
              if (collection[selectedVolume.inspection_item_id!]) {
                collection[selectedVolume.inspection_item_id!] += 1
              } else {
                collection[selectedVolume.inspection_item_id!] = 1
              }
            }

            return collection
          }, {})

          // Filter shape ids to last selected volume
          if (Object.keys(selectedVolumesByPlanes).length > 1) {
            const selectedVolumeIds = Object.keys(selectedVolumesByPlanes)
            const lastSelectedVolumeId = selectedVolumeIds[selectedVolumeIds.length - 1]
            newCollection = newCollection.filter((id) => {
              const selectedVolume = volumeItems.find((item) => (item.shape_ids.polygons || []).includes(id))
              return selectedVolume?.inspection_item_id === lastSelectedVolumeId
            })
          }
        }

        // ## Add both planes of volume estimation planes if one is selected
        const missingPlanes = volumeItems
          .map((item) => (item.shape_ids.polygons || [])?.filter((value) => !shapeIds.includes(value)))
          .filter((arr) => arr.length === 1)
          .flat()
        const toRemove = !!missingPlanes.filter((value) => selectedShapeIds.includes(value)).length

        if (missingPlanes.length === 1) {
          // add all missing plane ids to the collection
          newCollection = newCollection.concat(missingPlanes)

          // user is un-selecting a volume plane
          if (toRemove) {
            const toRemoveIds = volumeItems
              .filter((item) => (item.shape_ids.polygons || [])?.some((value) => missingPlanes.includes(value)))
              .map((item) => item.shape_ids.polygons || [])
              .flat()
            newCollection = newCollection.filter((value) => !toRemoveIds.includes(value))

            // If we're on depth estimation, remove all cylinders located within the selected plane
            // we need to group it by volume so we don't accidentally merge planes of different volume.
            if (selectedTool === EDITOR_TOOLS.DEPTH || newTool === EDITOR_TOOLS.DEPTH) {
              const deselectedVolumes = volumeItems
                .filter((item) => (item.shape_ids.polygons || [])?.some((value) => toRemoveIds.includes(value)))
                .map((item) => item.shape_ids.polygons || [])
              const deselectedCylinders = deselectedVolumes.map((vol) => findCylindersWithinVolumePlanes(vol))
              newCollection = newCollection.filter((id) => !deselectedCylinders.flat().includes(id))
            }
          }
        }

        // If we're on depth estimation, add all cylinders located within the selected plane
        // this only applies when changing tool or selecting a plane
        if (
          (newTool !== selectedTool && newTool === EDITOR_TOOLS.DEPTH) ||
          (selectedTool === EDITOR_TOOLS.DEPTH && missingPlanes.length === 1)
        ) {
          // we need to group it by volume so we don't accidentally merge planes of different volume.
          const selectedVolumes = volumeItems
            .filter((item) => (item.shape_ids.polygons || [])?.some((value) => newCollection.includes(value)))
            .map((item) => item.shape_ids.polygons || [])

          newCollection = uniq(
            newCollection.concat(selectedVolumes.map((vol) => findCylindersWithinVolumePlanes(vol)).flat()),
          )
        }
      }

      return newCollection
    },
    [inspectionItems, selectedShapeIds, selectedTool, findCylindersWithinVolumePlanes],
  )

  /**
   * Set shape IDs, optionally adding any related shapes as well.
   */
  const changeSelectedShapeIds = useCallback(
    (shapeIds: string[]) => {
      dispatch(setSelectedShapeIds(addAdditionalSelectedShapeIds(shapeIds)))
    },
    [addAdditionalSelectedShapeIds, dispatch],
  )

  /**
   * Filters or add shapes based on currently selected shapes.
   */
  const filterOrAddSelectedShapeIds = useCallback(
    (newTool?: string) => {
      dispatch(setSelectedShapeIds(addAdditionalSelectedShapeIds(selectedShapeIds, newTool)))
    },
    [selectedShapeIds, addAdditionalSelectedShapeIds, dispatch],
  )

  /**
   * Fetch all shapes
   */
  const fetchShapes = useCallback(async () => {
    if (!project_id || !inspection_area_id) {
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    const newShapes = await getShapes(token, project_id, inspection_area_id, showErrorModal)
    if (!newShapes) {
      return false
    }

    updateShapes(newShapes)

    return true
  }, [project_id, inspection_area_id, updateShapes, getAccessToken, showErrorModal])

  const deleteSelectedShapes = useCallback(
    (forSelectedShapes: boolean, shapeId?: string, shapeKey?: ShapeKey) => {
      if (!project?.project_id || !inspection_area_id) {
        return false
      }

      const shapesId: ShapesId = INITIAL_SHAPE_STATE()

      if (forSelectedShapes) {
        selectedShapeIds.forEach((id) => {
          if (shapes.cylinders.some((shape) => shape.shape_id === id)) {
            shapesId.cylinders.push(id)
          }
        })
        selectedShapeIds.forEach((id) => {
          if (shapes.polygons.some((shape) => shape.shape_id === id)) {
            shapesId.polygons.push(id)
          }
        })
      } else if (shapeId !== undefined && shapeKey) {
        shapesId[shapeKey] = [shapeId]
      }

      // Split volume polygons and standalone plane shapes
      const volumePolygonShapes = shapes[EDITOR_SHAPE_KEYS.POLYGONS].filter(
        (shp) =>
          getVolumeEstimationItem(inspectionItems, shp.shape_id) && (shp as Polygon).plane_side === PlaneSide.UPPER,
      )
      const standalonePlaneShapes = shapes[EDITOR_SHAPE_KEYS.POLYGONS].filter(
        (shp) => !getVolumeEstimationItem(inspectionItems, shp.shape_id),
      )

      // Count shapes to be deleted
      let selectedShapesCount = 0
      if (shapes[EDITOR_SHAPE_KEYS.CYLINDERS].length) {
        selectedShapesCount += shapes[EDITOR_SHAPE_KEYS.CYLINDERS].filter((shape) =>
          selectedShapeIds.includes(shape.shape_id),
        ).length
      }

      if (shapes[EDITOR_SHAPE_KEYS.POLYGONS].length) {
        selectedShapesCount += volumePolygonShapes.filter((shape) => selectedShapeIds.includes(shape.shape_id)).length
        selectedShapesCount += standalonePlaneShapes.filter((shape) => selectedShapeIds.includes(shape.shape_id)).length
      }

      showModal({
        title: `${forSelectedShapes ? selectedShapesCount : 1} アイテムを削除しますか？`,
        size: 'xl',
        body: (
          <Text>
            一度削除してしまうと、元に戻せません。
            <br />
            また、関連する測定値が全て削除されます。
          </Text>
        ),
        confirmText: '削除',
        modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
        onConfirm: () => {
          void (async () => {
            setIsLayerModifying(true)
            dispatch(setAttentionText({ message: 'データを更新中...' }))
            const token = await getAccessToken()
            if (!token) {
              return false
            }

            // If the planes we're deleting has been used for volume estimation, make sure to delete both planes.
            const volumeEstimationItems = getVolumeEstimationItems(inspectionItems)
            if (volumeEstimationItems) {
              // Get all volume estimation items that are using the planes we're deleting. Get only plane IDs.
              const polyRemovedVolumeShapeIds = volumeEstimationItems
                .filter((item) => shapesId.polygons.find((id) => item.shape_ids.polygons?.includes(id)))
                .map((item) => item.shape_ids.polygons)
                .flat()

              // Add the planes we're deleting to the list of shapes to be deleted. Make sure to not add duplicates.
              if (polyRemovedVolumeShapeIds) {
                const polyMissingIds = polyRemovedVolumeShapeIds.filter((val) => !shapesId.polygons.includes(val))
                if (polyMissingIds.length) {
                  shapesId[EDITOR_SHAPE_KEYS.POLYGONS] = shapesId[EDITOR_SHAPE_KEYS.POLYGONS].concat(polyMissingIds)
                }
              }
            }

            mixpanel.track('Deleting shapes', {
              'Shape amount': shapesId.cylinders.length + shapesId.polygons.length,
              'Shape IDs': shapesId,
            })

            const deleteResult = await deleteShapes(
              token,
              project.project_id,
              inspection_area_id,
              shapesId,
              showErrorModal,
            )

            if (deleteResult) {
              void (await fetchInspectionItems())

              const newShapes = { ...shapes }
              newShapes.cylinders = newShapes.cylinders.filter((shape) => !shapesId.cylinders.includes(shape.shape_id))
              newShapes.polygons = newShapes.polygons.filter((shape) => !shapesId.polygons.includes(shape.shape_id))
              setShapes(newShapes)

              const newSelectedShapeIds = selectedShapeIds.filter(
                (id) => !shapesId.cylinders.includes(id) && !shapesId.polygons.includes(id),
              )
              changeSelectedShapeIds(newSelectedShapeIds)
            }

            setIsLayerModifying(false)
            dispatch(setAttentionText({ message: '' }))
            return deleteResult
          })()
          return true
        },
      })

      return true
    },
    [
      getAccessToken,
      project,
      inspection_area_id,
      selectedShapeIds,
      shapes,
      inspectionItems,
      showErrorModal,
      showModal,
      fetchInspectionItems,
      dispatch,
      changeSelectedShapeIds,
    ],
  )

  //* 鉄筋canvas用
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  //* 鉄筋追加・編集データ用
  const [baseDiameter, setBaseDiameter] = useState<number>()

  //* ローディング管理用
  const [initCompleted, setInitCompleted] = useState(false)

  //* Clean the anchor picking status
  //* Should be called before changing tool
  const resetState = useCallback(() => {
    const shapeKey = EDITOR_TOOL_KEYS[selectedTool]

    if (shapeKey) {
      const requiredAnchors = EDITOR_REQUIRED_ANCHORS[shapeKey]
      const anchorPoints = [...anchors[shapeKey]]
      const lastAnchor = anchorPoints.pop()

      if (processingAnchor || (lastAnchor?.points?.length && lastAnchor.points.length < requiredAnchors)) {
        setProcessingAnchor(undefined)
        setAnchors({ ...anchors, [shapeKey]: anchorPoints })
      }
    } else if (selectedTool === EDITOR_TOOLS.DISTANCE) {
      const requiredAnchors = 2
      const anchorPoints = [...distanceAnchors]
      const lastAnchor = anchorPoints.pop()

      if (processingAnchor || (lastAnchor?.points?.length && lastAnchor.points.length < requiredAnchors)) {
        setProcessingAnchor(undefined)
        setDistanceAnchors(anchorPoints)
      }
    }

    setIsToolProcessing(false)
    setSelectedPoint(undefined)
    setIsDragging(false)
    setHoveredPoint(undefined)
    dispatch(setAttentionText({ message: '' }))
  }, [selectedTool, anchors, processingAnchor, distanceAnchors, dispatch])

  const finalizeChangingTool = useCallback(
    (tool: string) => {
      // TODO: Move to tool module
      if (tool === EDITOR_TOOLS.COMMENT) {
        dispatch(setCursor(CursorState.CROSSHAIR))
      } else if (selectedTool === EDITOR_TOOLS.COMMENT) {
        dispatch(setCursor(CursorState.DEFAULT))
      }

      setPrevPrevSelectedTool(prevSelectedTool)
      setPrevSelectedTool(selectedTool)
      setSelectedTool(tool)
      const currentTool = getCurrentEditorTool(tool)

      if (currentTool?.config?.volume?.onlyOneSelectable) {
        filterOrAddSelectedShapeIds(tool)
      }

      // Set help text if have it
      // TODO: Move to tool module
      if (tool === EDITOR_TOOLS.VOLUME) {
        // @deprecated VOLUME is deprected in favor of VOLUME_POLYGON
        const anchorPoints = [...getNonDeletedLayers(anchors[EDITOR_TOOL_KEYS[tool]])] as AnchorPoints[]
        dispatch(setAttentionText(getVolumeEstimationHelpText(anchorPoints)))
      } else if (tool === EDITOR_TOOLS.CYLINDER) {
        dispatch(
          setAttentionText({
            message:
              '鉄筋の始点と終点を選択してください。同時に11本まで指定することができます。\n平面モデルは右側の「検出した要素」パネルの目のアイコンで非表示にすることができます。',
          }),
        )
      }

      mixpanel.track('Change tool', {
        'Tool (new)': tool,
        'Tool (old)': selectedTool,
      })
    },
    [anchors, selectedTool, prevSelectedTool, filterOrAddSelectedShapeIds, dispatch],
  )

  /**
   * Check if the tool is the previous tool, or the tool before the previous tool
   * if the previous tool is FOCUS.
   */
  const isPreviousTool = useCallback(
    (tool: string) =>
      prevSelectedTool === tool || (prevSelectedTool === EDITOR_TOOLS.FOCUS && prevPrevSelectedTool === tool),
    [prevSelectedTool, prevPrevSelectedTool],
  )

  /**
   * Reset all tools and states
   */
  const resetTools = useCallback(() => {
    setAnchors(INITIAL_SHAPE_STATE())
    dispatch(setCuboidAnchor(undefined))
    dispatch(setEditingCuboid(undefined))
    dispatch(setHidePlaneLabel(false))

    // TODO: Need to figure out how to do this modularized way.
    dispatch(resetWorkingGrid())
    dispatch(resetVolumeEstimation())
    dispatch(resetRebarDetection())
    dispatch(resetPlaneDetection())
    dispatch(resetWorkingPolylines())
  }, [dispatch])

  const changeTool = useCallback(
    (tool: string, skipClearAnchorFramesChecking?: boolean) => {
      resetState()

      if (
        isLayerModifying &&
        // TODO: to be replaced with non-blocking operations
        [
          EDITOR_TOOLS.MOVE,
          EDITOR_TOOLS.VOLUME_POLYGON,
          EDITOR_TOOLS.PLANE,
          EDITOR_TOOLS.POLYLINE,
          EDITOR_TOOLS.CYLINDER,
          EDITOR_TOOLS.PLANE,
          EDITOR_TOOLS.DEPTH,
          EDITOR_TOOLS.GRID,
          EDITOR_TOOLS.PCD_TRIM_CUBOID,
        ].includes(tool)
      ) {
        showErrorModal('データを更新中です。完了後に再度実行してください。')
        return
      }

      // need volume to use tool
      const volumeInspectionItem = getVolumeEstimationItem(inspectionItems)
      const toolRequireVolume = Tools.find((t) => t.key === tool)?.config?.volume?.required || false
      if (toolRequireVolume && !volumeInspectionItem) {
        showErrorModal('体積測定を先に実施してください。')
        return
      }

      // need cylinder to use tool
      const hasCylinders = shapes.cylinders?.length > 0
      const toolRequireCylinder = Tools.find((t) => t.key === tool)?.config?.cylinder?.required || false
      if (toolRequireCylinder && !hasCylinders) {
        showErrorModal('先に鉄筋を検出してください。')
        return
      }

      // clear guidelines if any
      if (
        !skipClearAnchorFramesChecking &&
        needClearAnchorFrames(
          tool,
          cuboidAnchor,
          intervals,
          toolVolumePolygonIsDirty,
          toolRebarDetectionIsDirty,
          toolPlaneDetectionIsDirty,
          toolPolylineIsDirty,
        )
      ) {
        showModal({
          modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
          body: '検出を破棄しますか？',
          confirmText: '破棄',
          cancelText: 'キャンセル',
          onConfirm: () => {
            resetTools()
            finalizeChangingTool(tool)
            return true
          },
        })
      } else {
        finalizeChangingTool(tool)
      }
    },
    [
      cuboidAnchor,
      inspectionItems,
      isLayerModifying,
      intervals,
      shapes,
      toolRebarDetectionIsDirty,
      toolVolumePolygonIsDirty,
      toolPlaneDetectionIsDirty,
      toolPolylineIsDirty,
      resetTools,
      finalizeChangingTool,
      resetState,
      showErrorModal,
      showModal,
    ],
  )

  /**
   * Focus the camera on the anchor point
   */
  const focusCamera = useCallback(
    (anchorPoint: PointArray | undefined) => {
      if (!anchorPoint || !cameraRef.current || !arcballControlsRef.current) return

      // Save the current position and up vector of the camera
      const currentCameraPosition = cameraRef.current.position.clone()
      const up = cameraRef.current.up.clone()

      // distance from the camera to the anchor point
      const cameraToAnchor = new Vector3(...anchorPoint).sub(currentCameraPosition.clone())
      // angle between the camera's forward vector and the vector from the camera to the anchor point
      const angle = cameraToAnchor.angleTo(new Vector3(0, 0, -1).applyQuaternion(cameraRef.current.quaternion.clone()))
      // center position of viewing plane calculated from the camera's position and the distance to the anchor point
      const centerOfTheClickedViewingPlane = currentCameraPosition
        .clone()
        .sub(
          new Vector3(0, 0, cameraToAnchor.length() * Math.cos(angle)).applyQuaternion(
            cameraRef.current.quaternion.clone(),
          ),
        )

      // Set the target of the camera to the clicked point
      const newCameraPosition = currentCameraPosition
        .clone()
        .add(new Vector3(...anchorPoint))
        .sub(centerOfTheClickedViewingPlane)
      arcballControlsRef.current.setTarget(...anchorPoint)
      arcballControlsRef.current.reset()
      // Set the camera position and up vector back to the original
      cameraRef.current.position.set(newCameraPosition.x, newCameraPosition.y, newCameraPosition.z)
      cameraRef.current.up.set(up.x, up.y, up.z)
    },
    [cameraRef, arcballControlsRef],
  )

  const changeSubTool = useCallback((subTool: string) => {
    setSelectedSubTool(subTool)
  }, [])
  const changeBackgroundColor = useCallback(
    (color: string) => {
      setCookie(EDITOR_BACKGROUND_COOKIE_NAME, color, { expires: COOKIE_EXPIRE })
    },
    [setCookie],
  )
  const changeBaseDiameter = useCallback((dia: number) => {
    setBaseDiameter(dia)
  }, [])
  const changeCuboidDirection = useCallback((direction: CuboidDirection) => {
    setCuboidDirection(direction)
  }, [])
  const changeHoveredPoint = useCallback((point?: FocusedPoint) => {
    setHoveredPoint(point)
  }, [])
  const changeSelectedPoint = useCallback((point?: FocusedPoint) => {
    setSelectedPoint(point)
  }, [])
  const changeIsJobRunning = useCallback(
    (checking: boolean) => {
      setIsJobRunning(checking)
      if (checking && selectedTool !== EDITOR_TOOLS.DISTANCE) {
        changeTool(EDITOR_TOOLS.MOVE, true)
      }
    },
    [changeTool, selectedTool],
  )
  const changeIsDragging = useCallback((dragging: boolean) => {
    // Lock controls immediately. The flag takes a while to update,
    // in the meantime, the user can still move the camera.
    if (arcballControlsRef.current) arcballControlsRef.current.enabled = !dragging
    setIsDragging(dragging)
  }, [])
  const changeIsMouseDown = useCallback((isDown: boolean) => {
    setIsMouseDown(isDown)
  }, [])
  const changeIsToolProcessing = useCallback((processing: boolean) => {
    setIsToolProcessing(processing)
  }, [])
  const changePointSize = useCallback((size?: number) => {
    setPointSize(size)
  }, [])
  const changeProcessingAnchor = useCallback((anchor?: PointArray) => {
    setProcessingAnchor(anchor)
  }, [])
  const changeSelectedInspectionItem = useCallback((item?: InspectionItem) => {
    setSelectedInspectionItem(item)
  }, [])
  const changeCommentPopupPosition = useCallback((position?: Vector3) => {
    setCommentPopupPosition(position)
  }, [])
  const updatePointCloudVisibility = useCallback((invisible: boolean) => {
    setIsPointCloudInvisible(invisible)
  }, [])
  const updateMeshRefs = useCallback((ref: MeshRefs) => {
    setMeshRefs((refs) => ({ ...refs, ...ref }))
  }, [])
  const setMeshPointsFn = useCallback((pts: MeshPoints) => {
    setMeshPoints((mp) => ({ ...mp, ...pts }))
  }, [])
  const updateToggledCollapses = useCallback((collapses: string[]) => {
    setToggledCollapses(collapses)
  }, [])
  const updateCommentBody = useCallback((body: string) => {
    setCommentBody(body)
  }, [])
  const updateCommentDeletingImageIndexes = useCallback((indexes: number[]) => {
    setCommentDeletingImageIndexes(indexes)
  }, [])
  const updateCommentEditingCaptions = useCallback((captions: string[]) => {
    setCommentEditingCaptions(captions)
  }, [])
  const updateCommentImages = useCallback((images: CommentImage[]) => {
    setCommentImages(images)
  }, [])
  const updateCommentName = useCallback((name: string) => {
    setCommentName(name)
  }, [])
  const updateCommentImageHandlingIndex = useCallback((index: number) => {
    setCommentImageHandlingIndex(index)
  }, [])
  const toggleCommentOpeningImageId = useCallback((id: string) => {
    setCommentOpeningImageIds((states) => {
      const ids = [...states]
      if (ids.includes(id)) {
        ids.splice(ids.indexOf(id), 1)
      } else {
        ids.push(id)
      }
      return ids
    })
  }, [])
  const updateCommentUploadedImages = useCallback((images: CommentUploadedImage[]) => {
    setCommentUploadedImages(images)
  }, [])
  const changeCollidingShapeIds = useCallback((shapeIds: string[]) => {
    setCollidingShapeIds(shapeIds)
  }, [])

  const contextValue = useMemo(
    () =>
      ({
        addDistanceAnchor,
        baseDiameter,
        backgroundColor:
          (cookies as Record<string, string>)[EDITOR_BACKGROUND_COOKIE_NAME] || EDITOR_DEFAULT_BACKGROUND,
        commentPopupPosition,
        cuboidDirection,
        changeBackgroundColor,
        changeBaseDiameter,
        changeCommentPopupPosition,
        changeCuboidDirection,
        changeHoveredPoint,
        changeIsJobRunning,
        changeIsDragging,
        changeIsMouseDown,
        changeIsToolProcessing,
        changePointSize,
        changeProcessingAnchor,
        changeSelectedInspectionItem,
        changeSelectedPoint,
        changeSelectedShapeIds,
        changeTool,
        changeSubTool,
        setPrevSelectedTool,
        commentBody,
        commentDeletingImageIndexes,
        commentEditingCaptions,
        commentImageHandlingIndex,
        commentImages,
        commentName,
        commentOpeningImageIds,
        commentUploadedImages,
        deleteShapes: deleteSelectedShapes,
        distanceAnchors,
        hoveredPoint,
        initCompleted,
        isJobRunning,
        isDragging,
        isMouseDown,
        isLayerModifying,
        isPointCloudInvisible,
        isToolProcessing,
        meshRefs,
        pointSize,
        processingAnchor,
        project,
        inspectionArea,
        inspectionSheet,
        setInspectionSheet,
        selectedInspectionItem,
        selectedPoint,
        selectedTool,
        selectedSubTool,
        prevSelectedTool,
        prevPrevSelectedTool,
        isPreviousTool,
        anchors,
        shapes,
        setShapes,
        toggledCollapses,
        toggleCommentOpeningImageId,
        updateAnchorPoint,
        updateAllAnchorsStatus,
        updateAllDistanceAnchorsStatus,
        updateAllSelectedShapesStatus,
        updateAllShapesStatus,
        updateAnchorStatus,
        updateCommentBody,
        updateCommentDeletingImageIndexes,
        updateCommentEditingCaptions,
        updateCommentImageHandlingIndex,
        updateCommentImages,
        updateCommentName,
        updateCommentUploadedImages,
        updateDistanceAnchorPoint,
        updateDistanceAnchorStatus,
        updatePointCloudVisibility,
        updateMeshRefs,
        updateSelectedPointDiameter,
        updateSelectedPointValue,
        updateShapeStatus,
        updateShapeStatusById,
        updateShapeStatusByIds,
        updateToggledCollapses,
        isMainSheetModalOpen,
        setIsMainSheetModalOpen,
        inspectionItems,
        setInspectionItems,
        fetchInspectionSheet,
        fetchInspectionItems,
        fetchShapes,
        setIsLayerModifying,
        setDepthEstimationTypes,
        depthEstimationTypes,
        changeCollidingShapeIds,
        collidingShapeIds,
        selectionBoundaryBoxVectors,
        meshPoints,
        setMeshPoints: setMeshPointsFn,
        cameraRef,
        arcballControlsRef,
        focusCamera,
      }) as EditorInterface,
    [
      addDistanceAnchor,
      baseDiameter,
      cookies,
      commentPopupPosition,
      cuboidDirection,
      changeBackgroundColor,
      changeBaseDiameter,
      changeCommentPopupPosition,
      changeCuboidDirection,
      changeHoveredPoint,
      changeIsJobRunning,
      changeIsDragging,
      changeIsMouseDown,
      changeIsToolProcessing,
      changePointSize,
      changeProcessingAnchor,
      changeSelectedInspectionItem,
      changeSelectedPoint,
      changeSelectedShapeIds,
      changeTool,
      changeSubTool,
      setPrevSelectedTool,
      commentBody,
      commentDeletingImageIndexes,
      commentEditingCaptions,
      commentImageHandlingIndex,
      commentImages,
      commentName,
      commentOpeningImageIds,
      commentUploadedImages,
      deleteSelectedShapes,
      distanceAnchors,
      hoveredPoint,
      initCompleted,
      isJobRunning,
      isDragging,
      isMouseDown,
      isLayerModifying,
      isPointCloudInvisible,
      isToolProcessing,
      meshRefs,
      pointSize,
      processingAnchor,
      project,
      inspectionArea,
      inspectionSheet,
      setInspectionSheet,
      selectedInspectionItem,
      selectedPoint,
      selectedTool,
      selectedSubTool,
      prevSelectedTool,
      prevPrevSelectedTool,
      isPreviousTool,
      anchors,
      shapes,
      setShapes,
      toggledCollapses,
      toggleCommentOpeningImageId,
      updateAnchorPoint,
      updateAllAnchorsStatus,
      updateAllDistanceAnchorsStatus,
      updateAllSelectedShapesStatus,
      updateAllShapesStatus,
      updateAnchorStatus,
      updateCommentBody,
      updateCommentDeletingImageIndexes,
      updateCommentEditingCaptions,
      updateCommentImageHandlingIndex,
      updateCommentImages,
      updateCommentName,
      updateCommentUploadedImages,
      updateDistanceAnchorPoint,
      updateDistanceAnchorStatus,
      updatePointCloudVisibility,
      updateMeshRefs,
      updateSelectedPointDiameter,
      updateSelectedPointValue,
      updateShapeStatus,
      updateShapeStatusById,
      updateShapeStatusByIds,
      updateToggledCollapses,
      isMainSheetModalOpen,
      setIsMainSheetModalOpen,
      inspectionItems,
      setInspectionItems,
      fetchInspectionSheet,
      fetchInspectionItems,
      fetchShapes,
      setIsLayerModifying,
      setDepthEstimationTypes,
      depthEstimationTypes,
      changeCollidingShapeIds,
      collidingShapeIds,
      selectionBoundaryBoxVectors,
      meshPoints,
      setMeshPointsFn,
      cameraRef,
      arcballControlsRef,
      focusCamera,
    ],
  )

  // Set depth estimate data after loading inspection items
  useEffect(() => {
    const items = getDepthEstimationItems(inspectionItems)
    const depthTypes = [...depthEstimationTypes]

    // Old items have no plane_side so treat the first plane to cylinder as Type 1
    depthTypes[0].distanceAnchors = undefined
    depthTypes[0].inspectionItems = items
      // The reduce is to make sure we only get 1 plane to cylinder distance per volume
      .reduce<InspectionItem[]>((arr, item) => {
        if (item.plane_side) {
          return arr
        }
        if (arr.some((it) => it.volume_id === item.volume_id)) {
          return arr
        }
        if (
          item.item_type === INSPECTION_ITEM_TYPES.PLANE_TO_CYLINDERS_DISTANCE &&
          !!item?.plane_to_cylinders_distance?.estimated_value
        ) {
          return [...arr, item]
        }

        return arr
      }, [])
      .map((item) => {
        const updated = { ...item }
        if (updated?.plane_to_cylinders_distance?.estimated_value) {
          depthTypes[0].distanceAnchors = [
            ...(depthTypes[0].distanceAnchors || []),
            ...getPlaneToCylinderDistanceAnchors(updated.plane_to_cylinders_distance),
          ]
        }
        return updated
      })

    // If there's another plane to cylinder different from found on Type 1, then that shall be Type 2
    depthTypes[1].distanceAnchors = undefined
    depthTypes[1].inspectionItems = items
      .filter(
        (item) =>
          !item.plane_side &&
          item.item_type === INSPECTION_ITEM_TYPES.PLANE_TO_CYLINDERS_DISTANCE &&
          !depthTypes[0].inspectionItems?.some((it) => it.inspection_item_id === item.inspection_item_id) &&
          !!item?.plane_to_cylinders_distance?.estimated_value,
      )
      .map((item) => {
        const updated = { ...item }
        if (updated?.plane_to_cylinders_distance?.estimated_value) {
          depthTypes[1].distanceAnchors = [
            ...(depthTypes[1].distanceAnchors || []),
            ...getPlaneToCylinderDistanceAnchors(updated.plane_to_cylinders_distance),
          ]
        }

        return updated
      })

    // Top side
    depthTypes[2].distanceAnchors = undefined
    depthTypes[2].inspectionItems = items
      .filter((item) => item.plane_side === PlaneSide.UPPER)
      .map((item) => {
        const updated = { ...item }
        if (updated?.plane_to_cylinders_distance?.estimated_value) {
          depthTypes[2].distanceAnchors = [
            ...(depthTypes[2].distanceAnchors || []),
            ...getPlaneToCylinderDistanceAnchors(updated.plane_to_cylinders_distance),
          ]
        }
        return updated
      })

    // Bottom side
    depthTypes[3].distanceAnchors = undefined
    depthTypes[3].inspectionItems = items
      .filter((item) => item.plane_side === PlaneSide.LOWER)
      .map((item) => {
        const updated = { ...item }
        if (updated?.plane_to_cylinders_distance?.estimated_value) {
          depthTypes[3].distanceAnchors = [
            ...(depthTypes[3].distanceAnchors || []),
            ...getPlaneToCylinderDistanceAnchors(updated.plane_to_cylinders_distance),
          ]
        }
        return updated
      })

    setDepthEstimationTypes(depthTypes)

    // We don't care about `depthEstimationTypes` updates so intentionally exclude here so it doesn't infinite loop
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inspectionItems, shapes])

  useEffect(() => {
    if (cookies) {
      // update the cookie expiration date
      setCookie(EDITOR_BACKGROUND_COOKIE_NAME, cookies[EDITOR_BACKGROUND_COOKIE_NAME] || '#000', {
        expires: COOKIE_EXPIRE,
      })
    }
  }, [cookies, setCookie])

  const handleResize = useCallback(() => {
    const canvasBox = document.getElementById('canvasBox') as HTMLElement
    if (!canvasBox) return

    setWindowSize({
      width: window.innerWidth,
      height: canvasBox.clientHeight,
    })
  }, [])

  /**
   * Mixpanel
   */
  // Super properties to register
  const mixpanelSuperPropsRef = useRef({
    Page: 'Editor',
    'Project ID': project_id,
    'Inspection Area ID': inspection_area_id,
    'Original File Name': inspectionArea?.origin_file?.name,
  })

  // Cleanup super properties
  useEffect(() => () => Object.keys(mixpanelSuperPropsRef.current).forEach((prop) => mixpanel.unregister(prop)), [])

  /**
   * If area has already been set and inspection_area_id is different, reset store/states
   */
  useEffect(() => {
    if (initCompleted && inspection_area_id && inspectionArea?.inspection_area_id !== inspection_area_id) {
      setShapes(INITIAL_SHAPE_STATE())
      setInspectionSheet(undefined)
      setInspectionItems([])
      dispatch(setSelectedShapeIds([]))
      dispatch(setMaskRegions([]))
      setInitCompleted(false)
      resetTools()
      changeTool(EDITOR_TOOLS.MOVE, true)
    }
  }, [initCompleted, inspection_area_id, inspectionArea, resetTools, changeTool, dispatch])

  /**
   * Load everything needed for Editor page.
   */
  useEffect(() => {
    void (async () => {
      if (
        !initCompleted &&
        project &&
        inspectionArea &&
        userLoaded &&
        project_id &&
        inspection_area_id &&
        inspectionArea.inspection_area_id === inspection_area_id
      ) {
        const token = await getAccessToken()
        if (!token) {
          setInitCompleted(true)
          return
        }

        const allData = await Promise.all([
          getShapes(token, project_id, inspection_area_id, showErrorModal),
          fetchInspectionSheet(),
          getMaskingRegions(token, project_id, inspection_area_id, showErrorModal),
        ])

        if (allData[0]) {
          setShapes(allData[0])
        } else {
          setShapes(INITIAL_SHAPE_STATE())
        }

        if (allData[2]) {
          dispatch(setMaskRegions(allData[2]))
        }

        setInitCompleted(true)

        // analytics
        mixpanel.register(mixpanelSuperPropsRef.current)
        mixpanel.track('Page View')
      }
    })()
  }, [
    initCompleted,
    userLoaded,
    project,
    project_id,
    inspectionArea,
    inspection_area_id,
    getAccessToken,
    showErrorModal,
    dispatch,
    fetchInspectionSheet,
  ])

  /**
   * Prevent iPad from pulling to refresh
   * canvasがリサイズされるたびに再計算
   */
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    document.body.classList.add('no-overflow')

    return () => {
      window.removeEventListener('resize', handleResize)
      document.body.classList.remove('no-overflow')
    }
  }, [handleResize])

  /**
   * Hacky way to use router from within ThreeJS
   */
  useEffect(() => {
    const goto = (ev: Event) => {
      const {
        detail: { url, replace },
      } = ev as CustomEvent<{ url: string; replace: boolean }>
      navigate(url, { replace })
    }

    document.addEventListener('app:navigate', goto)

    return () => {
      document.removeEventListener('app:navigate', goto)
    }
  }, [navigate])

  // Tools activation by running hook
  // Regardless if user can't use it, the hook must run.
  const toolsConfig = Tools.map((tool) => {
    const tools: (
      | {
          key: string
          auth: boolean
          hook: EditorConfig
        }
      | undefined
    )[] = []
    if (tool.hooks?.useEditor) {
      tools.push({
        key: tool.key,
        auth: tool.authCheck(decideActionPermission(isOwner, isInvited), userType),
        hook: tool.hooks?.useEditor(contextValue),
      })
    }

    if (tool.toolbar?.variants) {
      tools.push(
        ...tool.toolbar.variants.map(
          (variant) =>
            variant.hooks?.useEditor && {
              key: variant.key,
              auth: variant.authCheck(decideActionPermission(isOwner, isInvited), userType),
              hook: variant.hooks?.useEditor(contextValue),
            },
        ),
      )
    }

    return tools
  })
    .filter(Boolean)
    .flat()
  const toolsComponents = Tools.map((tool) => tool.components?.editor)
    .flat()
    .filter((component) => !!component) as FC[]

  // Down-sampling is still running
  if (inspectionArea && inspectionArea.downsample_status?.status === InspectionAreaDownSampleStatus.RUNNING) {
    return (
      <PageErrorHandler>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Flex
            bg="black"
            flex={1}
            w="100%"
            color="white"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={3}
          >
            <LoadingPage text="ファイルを変換中..." />
          </Flex>
        </VStack>
      </PageErrorHandler>
    )
  }

  if (inspectionArea && !inspectionArea.down_sampled_file?.name) {
    return (
      <PageErrorHandler>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Flex
            bg="black"
            flex={1}
            w="100%"
            color="white"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap={3}
          >
            <Text fontSize="lg" color="gray.300">
              {isUploading
                ? `アップロード中...${uploadProgress !== null ? `${uploadProgress}%` : ''}`
                : '3D画面を利用するためには点群ファイルを追加してください'}
            </Text>
            <Button
              variant="outline"
              color="gray.100"
              borderColor="gray.300"
              _hover={{ color: 'gray.800', backgroundColor: 'white' }}
              size="lg"
              onClick={() => uploadFileRef?.current?.click()}
              leftIcon={<AddIcon />}
              isDisabled={isUploading}
            >
              点群ファイルをアップロード
            </Button>
            <Input
              data-testid="file-upload"
              hidden
              ref={uploadFileRef}
              type="file"
              accept={isMobile ? '' : '.las,.laz,.ply,.pts,.xyz,.xyzrgb'}
              onChange={async (e) => {
                e.preventDefault()
                if (!project || !inspectionArea) return

                const file = processSelectedFile(e)
                if (!file) return

                // start uploading
                setIsUploading(true)
                try {
                  const area = await uploadPcd(project, inspectionArea, file, setUploadProgress)

                  // need to update both unfortunately
                  dispatch(setInspectionArea(area))
                  dispatch(patchInspectionAreas([area]))
                } catch (err) {
                  Sentry.captureException(err)
                } finally {
                  setIsUploading(false)
                  setUploadProgress(null)
                }
              }}
            />
          </Flex>
        </VStack>
      </PageErrorHandler>
    )
  }

  return (
    <PageErrorHandler>
      <EditorContext.Provider value={contextValue}>
        <VStack p={0} m={0} h="100svh" spacing={0}>
          <Navbar />
          <Box
            w="100svw"
            h="100%"
            className="editor"
            ref={editorRef}
            position="relative"
            flex="1"
            marginTop="0 !important"
          >
            <Flex justify="space-between" align="start" h="100%">
              <Box id="canvasBox" flex={1} h="100%">
                <MainCanvas
                  addAnchor={addAnchor}
                  updateAnchorPoint={updateAnchorPoint}
                  updateDistanceAnchorPoint={updateDistanceAnchorPoint}
                  windowSize={windowSize}
                  setMovingPrefixedPosition={setMovingPrefixedPosition}
                  movingPrefixedPosition={movingPrefixedPosition}
                  user={user}
                />
              </Box>

              {initCompleted && (
                <>
                  <Toolbar />
                  {!isJobRunning &&
                    Tools.find((tool) => selectedTool === tool.key)?.config?.additionalToolbars?.cuboidControls &&
                    cuboidAnchor?.points.length === EDITOR_REQUIRED_ANCHORS.cuboid && <SubToolbar />}
                  <TransformControlsToolbar />
                  {/* ((action panel's bottom position = 4) * 2  = 8) * 4px */}
                  <InfoPanels actionPanelHeight={(actionPanelRef.current?.clientHeight || 0) + 8 * 4} />

                  {/* Message box at the top of screen */}
                  <AttentionText />
                </>
              )}
            </Flex>
          </Box>

          {initCompleted && (
            <>
              <VStack
                ref={actionPanelRef}
                minWidth={EDITOR_ACTION_BUTTON_MIN_WIDTH}
                position="absolute"
                right={2}
                bottom={2}
              >
                {toolsConfig.map(
                  (tool) =>
                    tool?.auth &&
                    tool.hook?.buttons?.undo?.isShown() && (
                      <Button
                        key={`tool-action-button-undo-${tool.key}`}
                        data-testid={`action-button-undo-${tool.key}`}
                        variant="toolbar"
                        rightIcon={<ResetIcon />}
                        size={isTablet ? 'lg' : 'md'}
                        fontSize="md"
                        onClick={() => {
                          document.dispatchEvent(new Event('editor-undo'))
                        }}
                        isDisabled={tool.hook.buttons.undo.isDisabled()}
                        width="full"
                        justifyContent="space-between"
                      >
                        点をひとつ戻す
                      </Button>
                    ),
                )}
                {toolsConfig.map(
                  (tool) =>
                    tool?.auth &&
                    tool.hook?.buttons?.reset?.isShown() && (
                      <Button
                        key={`tool-action-button-reset-${tool.key}`}
                        data-testid={`action-button-reset-${tool.key}`}
                        variant="toolbar"
                        rightIcon={<ResetIcon />}
                        size={isTablet ? 'lg' : 'md'}
                        fontSize="md"
                        onClick={() => {
                          showModal({
                            title: `リセット`,
                            body: '変更をリセットしますか？',
                            confirmText: 'リセット',
                            modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
                            onConfirm: () => {
                              tool.hook.buttons!.reset!.onClick()
                              return true
                            },
                          })
                        }}
                        isDisabled={tool.hook.buttons.reset.isDisabled()}
                        width="full"
                        justifyContent="space-between"
                      >
                        リセット
                      </Button>
                    ),
                )}
                {toolsConfig.map(
                  (tool) =>
                    tool?.auth &&
                    tool.hook?.buttons?.submit?.isShown() && (
                      <Tooltip
                        key={`tool-action-submit-tooltip-${tool.hook.buttons.submit.key}`}
                        label={tool.hook.buttons.submit.tooltip}
                        placement="left"
                        hasArrow
                      >
                        <Box w="100%">
                          <Button
                            data-testid={`action-button-${tool.hook.buttons.submit.key || 'submit'}`}
                            colorScheme="primary"
                            rightIcon={<CheckCircleIcon />}
                            size={isTablet ? 'lg' : 'md'}
                            fontSize="md"
                            onClick={tool.hook.buttons.submit.onClick}
                            isLoading={
                              tool.hook.buttons.submit.isLoading ? tool.hook.buttons.submit.isLoading() : false
                            }
                            isDisabled={tool.hook.buttons.submit.isDisabled()}
                            spinnerPlacement="end"
                            loadingText={tool.hook.buttons.submit.loadingLabel}
                            width="full"
                            justifyContent="space-between"
                          >
                            {tool.hook.buttons.submit.label}
                          </Button>
                        </Box>
                      </Tooltip>
                    ),
                )}
              </VStack>

              {toolsComponents.map((ToolComponent) => (
                <ToolComponent key={`editor-tool-component-${ToolComponent.name}`} />
              ))}
            </>
          )}
        </VStack>
      </EditorContext.Provider>
      {/* <DebugPanel /> */}
    </PageErrorHandler>
  )
}

export default Editor
