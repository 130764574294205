import { RefObject, useContext, useMemo, useState } from 'react'

import { createStandaloneToast } from '@chakra-ui/react'
import { ThreeEvent } from '@react-three/fiber'
import dayjs from 'dayjs'
import { setHoveredShapeId } from 'pages/projects/editor/store/editor'
import { Tools } from 'pages/projects/editor/tools/setup'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Camera, Mesh, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_MEASURE_KEYS, EDITOR_MOUSE_EVENT_DELAY, EDITOR_TOOLS } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'
import theme from 'config/theme'

import { PointArray, ShapeKey, ShapeKeyType, ShapeTypes, Timeout } from 'interfaces/interfaces'

import { createRayCaster, getCameraDistance, getClickCoords } from 'services/Points'
import { validateSelectedShape } from 'services/Util'
import { getVolumeEstimationItems } from 'services/VolumeEstimation'

export const TOAST_ID = 'shape-selection-error'

const useShape = (shape: ShapeTypes, shapeKey: ShapeKey, cameraRef: RefObject<Camera> | null) => {
  // Context
  const {
    addDistanceAnchor,
    changeSelectedShapeIds,
    changeProcessingAnchor,
    changeCommentPopupPosition,
    updateAnchorPoint,
    updateDistanceAnchorPoint,
    shapes,
    commentPopupPosition,
    isDragging,
    isToolProcessing,
    isMouseDown,
    selectedTool,
    hoveredPoint,
    inspectionItems,
    meshRefs,
    meshPoints,
    setMeshPoints,
  } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const selectedShapeIds = useSelector((root: RootState) => root.editor.selectedShapeIds)
  const hoveredShapeId = useSelector((root: RootState) => root.editor.hoveredShapeId)

  // Use standalone toast as we're considered 'outside' of React component.
  const { toast } = useMemo(
    () =>
      createStandaloneToast({
        theme,
      }),
    [],
  )

  //* Smooth mouse movement
  const [mouseTimeout, setMouseTimeout] = useState<Timeout>()
  // User need to click down an up on the same mesh to make a selection.
  // Store the down status on this state object for the up status checking
  const [isPointerDown, setIsPointerDown] = useState(false)

  // Current Editor tool
  const currentEditorTool = Tools.find((tool) => tool.key === selectedTool)
  const isShapeSelectable = currentEditorTool?.config?.selectableShapes?.includes(shapeKey as ShapeKeyType) || false

  const getSelectedPoint = (event: ThreeEvent<PointerEvent>): PointArray | undefined => {
    if (!cameraRef) return undefined

    //* カメラ取得
    const camera = cameraRef.current as Camera

    //* 光線生成とクリック位置特定
    const rayCaster = createRayCaster()
    const targetElem = (event.nativeEvent.target as HTMLElement).getBoundingClientRect()
    const { x, y, width, height } = targetElem
    const { coords } = getClickCoords(
      {
        x: event.nativeEvent.clientX,
        y: event.nativeEvent.clientY,
      },
      {
        x,
        y,
        width,
        height,
      },
    )

    //* カメラからpointまで光線を伸ばす
    rayCaster.setFromCamera(coords, camera)
    const intersects = rayCaster.intersectObject(event.eventObject)
    if (!intersects.length || intersects[0].point === undefined) {
      return undefined
    }

    return intersects[0].point.toArray()
  }

  const onPointerOver = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    if (
      (selectedTool !== EDITOR_TOOLS.MOVE && selectedTool !== EDITOR_TOOLS.DEPTH && !isShapeSelectable) ||
      isDragging ||
      shape.invisible ||
      isMouseDown
    ) {
      return
    }
    dispatch(setHoveredShapeId(shape.shape_id))
  }

  const onPointerMove = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    // Prevent selecting shape when moving camera
    setIsPointerDown(false)

    if (mouseTimeout) {
      clearTimeout(mouseTimeout)
    }

    const timeout = setTimeout(() => {
      if (isDragging || isToolProcessing) {
        const anchorPoint = getSelectedPoint(event)
        if (!anchorPoint) return

        if (selectedTool === EDITOR_TOOLS.DISTANCE && isToolProcessing) {
          changeProcessingAnchor(anchorPoint)
        } else if (isDragging && hoveredPoint) {
          if (hoveredPoint.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE) {
            updateDistanceAnchorPoint(hoveredPoint, anchorPoint)
          } else {
            updateAnchorPoint(hoveredPoint, anchorPoint)
          }
        }
      }
    }, EDITOR_MOUSE_EVENT_DELAY)

    setMouseTimeout(timeout)
  }

  const onPointerOut = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()

    if ((selectedTool !== EDITOR_TOOLS.MOVE && !isShapeSelectable) || isDragging) {
      return
    }
    dispatch(setHoveredShapeId(shape.shape_id))
    dispatch(setHoveredShapeId(''))
    setIsPointerDown(false)
  }

  const onPointerDown = () => {
    setIsPointerDown(true)
  }

  const onPointerUp = (event: ThreeEvent<PointerEvent>) => {
    event.stopPropagation()
    if (isPointerDown) {
      onClick(event)
    }
    setIsPointerDown(false)
  }

  /**
   * Handle clicking of a shape
   *
   * @param event Click event
   */
  const onClick = (event: ThreeEvent<PointerEvent>) => {
    if (selectedTool === EDITOR_TOOLS.DISTANCE) {
      const anchorPoint = getSelectedPoint(event)
      if (!anchorPoint || !cameraRef) return

      addDistanceAnchor(
        anchorPoint,
        new Vector2(event.nativeEvent.clientX, event.nativeEvent.clientY),
        getCameraDistance(new Vector3(...anchorPoint), cameraRef.current),
        dayjs().unix(),
      )
    }

    if (selectedTool === EDITOR_TOOLS.COMMENT) {
      const anchorPoint = getSelectedPoint(event)
      if (!anchorPoint || !cameraRef) return

      // To prevent re-picking position
      if (!commentPopupPosition) {
        changeCommentPopupPosition(new Vector3(...anchorPoint))
      }
    }

    if ((selectedTool !== EDITOR_TOOLS.MOVE && !isShapeSelectable) || isDragging) {
      return
    }

    try {
      validateSelectedShape(shape, shapeKey, getVolumeEstimationItems(inspectionItems), currentEditorTool)
    } catch (e) {
      if (!toast.isActive(TOAST_ID)) {
        toast({
          ...TOAST_CONFIG,
          id: TOAST_ID,
          status: 'error',
          position: 'bottom',
          title: e instanceof Error ? e.message : 'エラー',
        })
      }
      return
    }

    let newShapeIds = [...selectedShapeIds]

    // If only 1 volume can be selected, deselect all other volumes
    if (currentEditorTool && currentEditorTool.config?.volume?.onlyOneSelectable && shapeKey === ShapeKeyType.POLYGON) {
      newShapeIds = newShapeIds
        .filter((id) => !shapes.polygons.find((poly) => poly.shape_id === id))
        .filter((id) => !shapes.cylinders.find((cylinder) => cylinder.shape_id === id))
    }

    const idIndex = newShapeIds.indexOf(shape.shape_id)
    if (idIndex >= 0) {
      newShapeIds.splice(idIndex, 1)
    } else {
      newShapeIds.push(shape.shape_id)
    }

    changeSelectedShapeIds(newShapeIds)
  }

  const onAfterRender = () => {
    if (meshPoints[shape.shape_id] || !meshRefs) return

    const meshRef = meshRefs[shape.shape_id]
    if (!meshRef) return

    const msh = meshRef.current as Mesh
    if (msh.geometry.attributes.position) {
      const arr: Vector3[] = []
      for (let i = 0; i < msh.geometry.attributes.position.count; i += 1) {
        arr.push(
          msh.localToWorld(
            new Vector3(
              msh.geometry.attributes.position.array[i * 3],
              msh.geometry.attributes.position.array[i * 3 + 1],
              msh.geometry.attributes.position.array[i * 3 + 2],
            ),
          ),
        )
      }

      setMeshPoints({ [shape.shape_id]: arr })
    }
  }

  return {
    onPointerOver,
    onPointerOut,
    onPointerMove,
    onPointerDown,
    onPointerUp,
    onAfterRender,
    isFocus: shape.shape_id === hoveredShapeId,
  }
}

export default useShape
