import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LayerStatusExtended, PointArray } from 'interfaces/attribute'
import { DistanceLabelProps } from 'interfaces/canvas'

/**
 * Distance label props specifically for working cylinder.
 */
export interface DistanceLabelWorkingCylinderProps extends DistanceLabelProps, LayerStatusExtended {
  /**
   * Cylinder diameter.
   */
  diameter?: number
}

export interface RebarDetectionState {
  /**
   * Grid being processed.
   */
  isLoading: boolean

  /**
   * Working distance labels that user draws to mark the cylinder.
   */
  workingDistanceLabels: DistanceLabelWorkingCylinderProps[]

  /**
   * Temporary distance label that user is currently drawing.
   */
  tempDistanceLabel: DistanceLabelWorkingCylinderProps | undefined

  /**
   * Cylinder diameter.
   */
  baseDiameter?: number

  /**
   * Distance label being dragged.
   */
  draggingDistanceLabel?: { labelIndex: number; anchorIndex: number; label: DistanceLabelWorkingCylinderProps }

  /**
   * Flag for when first point has been placed.
   */
  firstPointPlaced: boolean

  /**
   * Flag to indicate if the tool is dirty (has working items).
   */
  isDirty: boolean
}

const initialState: RebarDetectionState = {
  isLoading: false,
  workingDistanceLabels: [],
  tempDistanceLabel: undefined,
  firstPointPlaced: false,
  isDirty: false,
}

export const slice = createSlice({
  name: 'editor/tools/rebarDetaction',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setWorkingDistanceLabels: (state, action: PayloadAction<DistanceLabelWorkingCylinderProps[]>) => {
      state.workingDistanceLabels = action.payload
    },
    addWorkingDistanceLabel: (state, action: PayloadAction<DistanceLabelWorkingCylinderProps>) => {
      state.workingDistanceLabels.push(action.payload)
    },
    updateLastWorkingDistanceLabel: (state, action: PayloadAction<DistanceLabelWorkingCylinderProps>) => {
      state.workingDistanceLabels[state.workingDistanceLabels.length - 1] = action.payload
    },
    updateDraggingDistanceLabelAnchor: (state, action: PayloadAction<PointArray>) => {
      if (state.draggingDistanceLabel) {
        state.draggingDistanceLabel.label.points[state.draggingDistanceLabel.anchorIndex] = action.payload
      }
    },
    updateSelectedDistanceLabel: (state, action: PayloadAction<Partial<DistanceLabelWorkingCylinderProps>>) => {
      state.workingDistanceLabels = state.workingDistanceLabels.map((label) =>
        label.selected ? { ...label, ...action.payload } : label,
      )
    },
    setTempDistanceLabel: (state, action: PayloadAction<DistanceLabelWorkingCylinderProps | undefined>) => {
      state.tempDistanceLabel = action.payload
      if (action.payload && action.payload.points.length === 2) {
        state.isDirty = true
      }
    },
    toggleAllWorkingDistanceLabelsStatus: (state, action: PayloadAction<LayerStatusExtended>) => {
      state.workingDistanceLabels = state.workingDistanceLabels.map((label) => ({
        ...label,
        ...action.payload,
      }))
    },
    /**
     * Toggle layer status for a specific distance label by index.
     * Most status will be allowed to be toggled on multiple at once, but selected status will be exclusive.
     */
    toggleWorkingDistanceLabelStatusAtIndex: (
      state,
      action: PayloadAction<{ index: number; status: LayerStatusExtended }>,
    ) => {
      if (action.payload.status.selected !== undefined) {
        state.workingDistanceLabels = state.workingDistanceLabels.map((label, index) => ({
          ...label,
          ...action.payload.status,
          selected: index === action.payload.index ? action.payload.status.selected : false,
        }))
      } else {
        state.workingDistanceLabels[action.payload.index] = {
          ...state.workingDistanceLabels[action.payload.index],
          ...action.payload.status,
        }
      }
    },
    deleteWorkingDistanceLabelAtIndex: (state, action: PayloadAction<number>) => {
      state.workingDistanceLabels.splice(action.payload, 1)
      if (
        state.workingDistanceLabels.length === 0 &&
        (!state.tempDistanceLabel || state.tempDistanceLabel.points.length < 2)
      ) {
        state.isDirty = false
      }
    },
    /**
     * Set base diameter for new distance labels.
     * Will also set the diameter for existing distance labels if they don't have one.
     * Will also set the diameter for selected distance label.
     */
    setDiameter: (state, action: PayloadAction<number>) => {
      state.baseDiameter = action.payload
      state.workingDistanceLabels = state.workingDistanceLabels.map((label) => ({
        ...label,
        diameter: label.diameter || action.payload,
      }))
    },
    setDraggingDistanceLabel: (
      state,
      action: PayloadAction<{ labelIndex: number; anchorIndex: number } | undefined>,
    ) => {
      if (action.payload) {
        state.draggingDistanceLabel = {
          ...action.payload,
          label: state.workingDistanceLabels[action.payload.labelIndex],
        }
      } else {
        state.draggingDistanceLabel = undefined
      }
    },
    finishDraggingDistanceLabel: (state) => {
      if (state.draggingDistanceLabel) {
        state.workingDistanceLabels[state.draggingDistanceLabel.labelIndex] = state.draggingDistanceLabel.label
      }
      state.draggingDistanceLabel = undefined
    },
    setFirstPointPlaced: (state, action: PayloadAction<boolean>) => {
      state.firstPointPlaced = action.payload
    },

    /**
     * Full reset of working state only if there are no working distance labels.
     * This is done here instead of inside hooks to prevent unnecessary rendering.
     */
    resetFullIfNoWorkingLabels: (state) => {
      state.tempDistanceLabel = undefined
      if (state.workingDistanceLabels.length === 0) {
        state.isLoading = false
        state.firstPointPlaced = false
        state.workingDistanceLabels = []
        state.draggingDistanceLabel = undefined
        state.isDirty = false
      }
    },

    /**
     * Full reset of working state.
     */
    resetWorking: (state) => {
      state.isLoading = false
      state.workingDistanceLabels = []
      state.tempDistanceLabel = undefined
      state.draggingDistanceLabel = undefined
      state.isDirty = false
      state.firstPointPlaced = false
    },
    reset: () => initialState,
  },
})

export const {
  setIsLoading,
  reset,
  resetWorking,
  resetFullIfNoWorkingLabels,
  setDiameter,
  setDraggingDistanceLabel,
  finishDraggingDistanceLabel,
  setFirstPointPlaced,

  // distance labels
  setWorkingDistanceLabels,
  addWorkingDistanceLabel,
  updateLastWorkingDistanceLabel,
  updateSelectedDistanceLabel,
  setTempDistanceLabel,
  updateDraggingDistanceLabelAnchor,
  toggleAllWorkingDistanceLabelsStatus,
  toggleWorkingDistanceLabelStatusAtIndex,
  deleteWorkingDistanceLabelAtIndex,
} = slice.actions

export default slice.reducer
