import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PointArray } from 'interfaces/attribute'
import { CameraProfile } from 'interfaces/inspection'

export enum SelectionStage {
  FOCUS = 'FOCUS',
  ROTATE = 'ROTATE',
  SAVING = 'SAVING',
}

interface CameraProfileState {
  /**
   * Selection stage.
   */
  selectionStage: SelectionStage

  /**
   * Saved camera profile.
   */
  cameraProfile?: CameraProfile

  /**
   * Camera target (center/pivot point).
   */
  target?: PointArray
}

export const initialState: CameraProfileState = {
  selectionStage: SelectionStage.FOCUS,
}

export const slice = createSlice({
  name: 'cameraProfile',
  initialState,
  reducers: {
    setSelectionStage: (state, action: PayloadAction<SelectionStage>) => {
      state.selectionStage = action.payload
    },
    setCameraProfile: (state, action: PayloadAction<CameraProfile | undefined>) => {
      state.cameraProfile = action.payload
    },
    setTarget: (state, action: PayloadAction<PointArray>) => {
      state.target = action.payload
    },
    resetWorkingState: (state) => {
      state.selectionStage = SelectionStage.FOCUS
      state.target = undefined
    },
    reset: () => initialState,
  },
})

export const { setSelectionStage, setCameraProfile, setTarget, resetWorkingState, reset } = slice.actions

export default slice.reducer
