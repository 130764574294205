import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import {
  CursorState,
  addDimmedShapeId,
  removeDimmedShapeId,
  setAnchorPlacementObjects,
  setCursor,
} from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { Matrix4, Mesh, Quaternion, Raycaster, Plane as ThreePlane, Vector2, Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS, GRID_MINIMUM_INTERVAL } from 'config/constants'
import { PLANE_SIDE_COLOR } from 'config/styles'

import { MinimumAreaBoundary } from 'interfaces/diagram'
import { CanvasConfig } from 'interfaces/editor'
import {
  CameraProfileState,
  DistanceLabelProps,
  LabelProps,
  PlaneSide,
  PointArray,
  Polygon,
  PolygonPlaneMeshProps,
} from 'interfaces/interfaces'

import { getArcballControlsCameraState } from 'services/Editor'
import { minAreaRectangleOfPolygon } from 'services/MinimumRectangle'
import { calculateBoundaryDirectionVectors } from 'services/PlaneDiagram'
import { findPerpendicularProjection, getCentroidOfPolygon3d, transform2Dto3D } from 'services/Points'
import { findNormalTowardsB, getNormals } from 'services/Shape'
import { generateGridPoints, getDistanceLabel, isHoverSelected, millimeterToMeter } from 'services/Util'

import { IntervalsConfig, removeWorkingGridPoint, updateInterval, updateWorkingGridPoints } from '../store'

const raycaster = new Raycaster()

interface MovingGridPoint {
  volumeId: string
  gridPointIndex: number
  anchorIndex: number
  points: PointArray[]
}

const useMainCanvas = (): CanvasConfig => {
  // Context
  const { meshRefs, selectedTool, inspectionItems, prevSelectedTool, shapes, arcballControlsRef, changeIsDragging } =
    useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const cameraProfile = useSelector((state: RootState) => state.toolCameraProfile.cameraProfile)
  const grids = useSelector((state: RootState) => state.toolGrid.grids)
  const intervals = useSelector((state: RootState) => state.toolGrid.intervals)
  const workingGridPoints = useSelector((state: RootState) => state.toolGrid.workingGridPoints)
  const highlightedWorkingGrid = useSelector((state: RootState) => state.toolGrid.highlightedWorkingGrid)
  const hoveredShapeId = useSelector((state: RootState) => state.editor.hoveredShapeId)
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // State
  const [existingDistanceLabels, setExistingDistanceLabels] = useState<Record<string, DistanceLabelProps[]>>({})
  const [workingDistanceLabels, setWorkingDistanceLabels] = useState<Record<string, DistanceLabelProps[]>>({})
  const [warningLabels, setWarningLabels] = useState<LabelProps[]>([])
  const [movingGridPoint, setMovingGridPoint] = useState<MovingGridPoint | null>(null)
  const [threePlanes, setThreePlanes] = useState<Record<string, ThreePlane>>({})

  // Flags
  const isToolSelected = selectedTool === EDITOR_TOOLS.GRID

  /**
   * Generate distance labels for grids retrieved from API.
   */
  useEffect(() => {
    const newDistanceLabels: Record<string, DistanceLabelProps[]> = {}
    let newWarningLabels: LabelProps[] = []

    // Generate distance labels
    grids.forEach((item) => {
      newDistanceLabels[item.volume_id!] =
        item.grid?.list_distances
          .filter(() => !intervals[item.volume_id!])
          .map<DistanceLabelProps>((distance) => {
            // Hide if invisible however if highlighted, show with low opacity
            let opacity = distance.invisible ? 0 : 1
            if (distance.highlighted && distance.invisible) {
              opacity = 0.6
            }

            return {
              id: `grid-point-${item.volume_id}-${distance.position_on_grid_point.join('-')}`,
              points: distance.distance
                ? [distance.position_on_grid_point, distance.position_on_projected_point]
                : [distance.position_on_grid_point],
              anchorScale: distance.highlighted ? 1.5 : undefined,
              labelOutlineColor: distance.highlighted ? '#fff' : undefined,
              opacity,
              label: item.shape_ids.polygons.some((id) => isHoverSelected(id, hoveredShapeId, selectedShapeIds))
                ? `${distance.name}: ${getDistanceLabel(distance.distance || 0)}`
                : undefined, // only for clearing TS constraint check
              lineOutline: true,
              topAnchorColor: PLANE_SIDE_COLOR.upper,
              bottomAnchorColor: PLANE_SIDE_COLOR.lower,
              anchorOutlineColor: distance.distance ? undefined : 'yellow',
            }
          })
          .filter((distance) => distance.opacity) || []
    })

    // Generate warning labels
    grids.forEach((item) => {
      newWarningLabels = newWarningLabels.concat(
        item.grid?.list_distances
          .filter(
            (distance) =>
              !distance.distance &&
              !intervals[item.volume_id!] &&
              item.shape_ids.polygons.some((id) => isHoverSelected(id, hoveredShapeId, selectedShapeIds)),
          )
          .map((distance) => {
            // Hide if invisible however if highlighted, show with low opacity
            let opacity = distance.invisible ? 0 : 1
            if (distance.highlighted && distance.invisible) {
              opacity = 0.6
            }

            return {
              point: distance.position_on_grid_point,
              label: distance.name,
              message: '底面に対応する点が見つかりません',
              opacity,
              offset: [0, -18] as [number, number],
            }
          })
          .filter((distance) => distance.opacity) || [],
      )
    })

    setExistingDistanceLabels(newDistanceLabels)
    setWarningLabels(newWarningLabels)
  }, [grids, intervals, hoveredShapeId, selectedShapeIds, inspectionItems])

  /**
   * Generate minimum rectangle boundaries for polygons.
   */
  const minimumRectangleBoundaries = useMemo(
    () =>
      (shapes.polygons || [])
        .filter((poly) => poly.plane_side === PlaneSide.UPPER)
        .map((poly) => ({
          polygon: poly,
          boundary: minAreaRectangleOfPolygon(poly.vertices, poly.positions),
        }))
        .filter((bundle) => bundle?.boundary) as { polygon: Polygon; boundary: MinimumAreaBoundary }[],
    [shapes],
  )

  /**
   * Get shapes of both top and bottom planes for both polygon and rectangle.
   */
  const getTopAndBottomShapes = useCallback(
    (interval: IntervalsConfig) => {
      const topShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.topPlaneId)
      const bottomShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.bottomPlaneId)
      const topShape = topShapePolygon
      const bottomShape = bottomShapePolygon

      // TODO: refactor use top/bottom shape directly.

      return {
        topShapePolygon,
        bottomShapePolygon,
        topShape,
        bottomShape,
      }
    },
    [shapes.polygons],
  )

  /**
   * Generate vectors pointing towards the edge of the minimum boundary to determine the up/right/forward direction.
   * The definition of up/right/forward is based on the camera, either from the camera profile or the arcball controls.
   */
  const edgeVectors = useMemo(
    () =>
      shapes?.polygons
        .filter(
          (polygon) =>
            polygon.plane_side === PlaneSide.UPPER &&
            inspectionItems.find(
              (item) => item.item_type === 'volume' && item.shape_ids.polygons?.includes(polygon.shape_id),
            ),
        )
        .reduce<
          {
            id: string
            bottomId: string
            center: Vector3
            up: {
              direction: Vector3
              point: Vector3
              distance: number
            }
            right: {
              direction: Vector3
              point: Vector3
              distance: number
            }
            forward: {
              direction: Vector3
              point: Vector3
              distance: number
            }
            transform: Matrix4
          }[]
        >((collection, polygon) => {
          let cameraProfileState: CameraProfileState | undefined
          if (cameraProfile) {
            cameraProfileState = cameraProfile.state
          } else if (arcballControlsRef.current) {
            cameraProfileState = getArcballControlsCameraState(arcballControlsRef.current)
          }

          if (!cameraProfileState || !meshRefs) return collection

          // Get top and bottom shapes
          const topShapePolygon = polygon
          const bottomShapePolygon = shapes.polygons?.find(
            (poly) =>
              poly.plane_side === PlaneSide.LOWER &&
              inspectionItems.find(
                (item) =>
                  item.item_type === 'volume' &&
                  item.shape_ids.polygons.includes(polygon.shape_id) &&
                  item.shape_ids.polygons.includes(poly.shape_id),
              ),
          )

          if (!topShapePolygon || !bottomShapePolygon) return collection

          // Get mesh references for top and bottom planes, prioritizing minimum boundary if available
          const actualTopMeshRef = meshRefs[topShapePolygon.shape_id]
          const topMeshRef = meshRefs[`minimum-boundary-${topShapePolygon.shape_id}`] || actualTopMeshRef
          const bottomMeshRef = meshRefs[bottomShapePolygon.shape_id]

          if (!topMeshRef?.current || !bottomMeshRef?.current || !actualTopMeshRef?.current) return collection

          // Determine direction of the bottom plane
          const topNormal = getNormals(topShapePolygon, topMeshRef.current as Mesh)
          const bottomNormal = getNormals(bottomShapePolygon, bottomMeshRef.current as Mesh)
          const direction = findNormalTowardsB(
            topMeshRef.current.getWorldPosition(new Vector3()),
            bottomMeshRef.current.getWorldPosition(new Vector3()),
            topNormal,
          )

          // Construct plane instances for top and bottom planes
          let topPlane: ThreePlane = threePlanes[topShapePolygon.shape_id]
          let bottomPlane: ThreePlane = threePlanes[bottomShapePolygon.shape_id]

          if (!topPlane) {
            topPlane = new ThreePlane().setFromNormalAndCoplanarPoint(topNormal, actualTopMeshRef.current.position)
            setThreePlanes((prev) => ({ ...prev, [topShapePolygon.shape_id]: topPlane }))
          }

          if (!bottomPlane) {
            bottomPlane = new ThreePlane().setFromNormalAndCoplanarPoint(bottomNormal, bottomMeshRef.current.position)
            setThreePlanes((prev) => ({ ...prev, [bottomShapePolygon.shape_id]: bottomPlane }))
          }

          // Get camera vectors for up/right direction reference
          const cameraMatrix = new Matrix4().fromArray(cameraProfileState.arcballState.cameraMatrix.elements)
          const cameraRotation = new Quaternion().setFromRotationMatrix(cameraMatrix)
          const cameraRightVector = new Vector3(1, 0, 0).applyQuaternion(cameraRotation)
          const cameraUpVector = new Vector3(0, 1, 0).applyQuaternion(cameraRotation)

          // Get top boundary for the actual up/right direction
          const topBoundary = minimumRectangleBoundaries.find(
            (bundle) => bundle.polygon.shape_id === polygon?.shape_id,
          )?.boundary
          if (!topBoundary) return collection

          // Calculate up/right direction vectors
          const positions = transform2Dto3D(
            topBoundary.vertices.map((vertex) => new Vector2(...vertex)),
            polygon.transformation,
          ).map((v) => new Vector3(...v))
          const vectors = calculateBoundaryDirectionVectors(positions, cameraUpVector, cameraRightVector)
          if (!vectors) return collection

          // Calculate center
          const center = getCentroidOfPolygon3d(positions)

          // make Matrix4 from vectors
          const { up, right } = vectors
          const transform = new Matrix4().makeBasis(right.direction, up.direction, direction).setPosition(center)

          // Calculate distance between top and bottom planes
          const topBottomDistance = new Vector3(...topShapePolygon.center!).distanceTo(
            new Vector3(...bottomShapePolygon.center!),
          )

          collection.push({
            id: polygon.shape_id,
            bottomId: bottomShapePolygon.shape_id,
            center,
            up,
            right,
            forward: {
              direction,
              point: center.clone().add(direction.clone().multiplyScalar(topBottomDistance)),
              distance: topBottomDistance,
            },
            transform,
          })
          return collection
        }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want edgeVector to recalculate when selectedShapeIds changes
    [
      minimumRectangleBoundaries,
      inspectionItems,
      shapes,
      cameraProfile,
      arcballControlsRef,
      threePlanes,
      meshRefs,
      selectedShapeIds,
    ],
  )

  /**
   * Generate working grid points, skipping if locked.
   */
  useEffect(() => {
    if (!meshRefs) return

    Object.keys(intervals).forEach((volumeId) => {
      const interval = intervals[volumeId]

      const { topShape, bottomShape } = getTopAndBottomShapes(interval)
      if (!topShape && !bottomShape) return

      // Get mesh references for top and bottom planes, prioritizing minimum boundary if available
      const actualTopMeshRef = meshRefs[interval.topPlaneId]
      const topMeshRef = meshRefs[`minimum-boundary-${interval.topPlaneId}`] || actualTopMeshRef
      const bottomMeshRef = meshRefs[interval.bottomPlaneId]

      if (!topMeshRef.current || !bottomMeshRef.current || !actualTopMeshRef.current) return

      // locked grid only need not to generate grid points
      if (interval.locked) return

      // Get edge vectors for the polygon
      const edgeVector = edgeVectors.find((v) => v.id === interval.topPlaneId)
      if (!edgeVector) return

      // Construct plane instances for top and bottom planes
      const topPlane: ThreePlane = threePlanes[edgeVector.id]
      const bottomPlane: ThreePlane = threePlanes[edgeVector.bottomId]

      if (!topPlane || !bottomPlane) return

      let longAxis: IntervalsConfig['longAxis']
      let shortAxis: IntervalsConfig['shortAxis']
      const axisA = {
        value: Math.max(interval.longAxis.value, GRID_MINIMUM_INTERVAL),
        max: interval.longAxis.max,
        offset: interval.longAxis.offset,
      }
      const axisB = {
        value: Math.max(interval.shortAxis.value, GRID_MINIMUM_INTERVAL),
        max: interval.shortAxis.max,
        offset: interval.shortAxis.offset,
      }

      if (edgeVector.right.distance > edgeVector.up.distance) {
        longAxis = axisA
        shortAxis = axisB
      } else {
        longAxis = axisB
        shortAxis = axisA
      }

      // Generate grid points
      const points = generateGridPoints(
        {
          ...interval,
          longAxis,
          shortAxis,
          whichLongAxis: 1,
        },
        true,
      ).map((point) => [millimeterToMeter(point[0]), millimeterToMeter(point[1])])

      // convert 2D to 3D
      const points3d = points.map((point) => new Vector3(point[0], point[1], 0).applyMatrix4(edgeVector.transform))

      // Finalize grid points by projecting them to the bottom plane and filtering out points that are not on the top plane.
      const gridPoints = points3d
        // filter out points that are not on the top plane
        .reduce<PointArray[][]>((arr, point) => {
          const rawPoints = [
            point
              .clone()
              .add(
                edgeVector.forward.direction
                  .clone()
                  .negate()
                  .multiplyScalar(topMeshRef.current.position.distanceTo(bottomMeshRef.current.position)),
              ),
            findPerpendicularProjection(point, topPlane, bottomPlane),
          ]
          const validPoints: Vector3[] = []

          // Ray cast from first to second point to check if the second point is actually on the bottom plane
          raycaster.set(rawPoints[0], edgeVector.forward.direction)
          const results = raycaster.intersectObjects([actualTopMeshRef.current, bottomMeshRef.current], true)

          results.forEach((result) => {
            if (result.object.uuid === bottomMeshRef.current.uuid) {
              validPoints[1] = result.point
            } else if (result.object.uuid === actualTopMeshRef.current.uuid) {
              validPoints[0] = result.point
            }
          })

          if (validPoints.length > 1) {
            arr.push(validPoints.map((p) => p.toArray()))
          }

          return arr
        }, [])

      dispatch(updateWorkingGridPoints({ id: volumeId, points: gridPoints }))
    })
  }, [edgeVectors, intervals, meshRefs, threePlanes, getTopAndBottomShapes, dispatch])

  /**
   * Generate label distance from grid points.
   */
  useEffect(
    () => {
      const labels = Object.keys(workingGridPoints).map((volumeId) =>
        workingGridPoints[volumeId].map<DistanceLabelProps>((points, pointIndex) => ({
          id: `${volumeId}-${points.join('-')}`,
          lineOutline: true,
          bottomAnchorColor: '#f00',
          points,
          anchorScale: volumeId === highlightedWorkingGrid ? 1.5 : undefined,
          labelOutlineColor: volumeId === highlightedWorkingGrid ? '#fff' : undefined,
          label: !movingGridPoint ? `t${pointIndex + 1}` : undefined,
          onDown: (anchorIndex) => {
            const interval = intervals[volumeId]

            changeIsDragging(true) // toggling this back to false is managed by MainCanvas::onMouseUp
            setMovingGridPoint({ volumeId, gridPointIndex: pointIndex, anchorIndex, points })
            dispatch(setCursor(CursorState.GRABBING)) // MainCanvas::onMouseUp will handle the reset

            // Limit anchor placement on top plane only
            dispatch(setAnchorPlacementObjects([interval.topPlaneId]))

            // Mark the top plane to be a bit dimmer
            const topShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.topPlaneId)
            if (topShapePolygon) {
              dispatch(addDimmedShapeId(topShapePolygon.shape_id))
            }

            // remove this grid point as we have a different instances of it
            dispatch(removeWorkingGridPoint({ id: volumeId, gridPointIndex: pointIndex }))
          },
          onEnter: () => {
            if (!movingGridPoint) dispatch(setCursor(CursorState.GRAB))
          },
          onLeave: () => {
            if (!movingGridPoint) dispatch(setCursor(CursorState.CROSSHAIR))
          },
        })),
      )

      const workingLabels = labels.reduce(
        (acc, current, index) => {
          acc[`${index}`] = current
          return acc
        },
        {} as Record<string, DistanceLabelProps[]>,
      )
      setWorkingDistanceLabels(workingLabels)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingGridPoints, selectedTool, movingGridPoint, changeIsDragging, dispatch, highlightedWorkingGrid],
  )

  /**
   * Reset anchor placement objects when tool is deselected.
   */
  useEffect(() => {
    if (prevSelectedTool === EDITOR_TOOLS.GRID && !isToolSelected) {
      dispatch(setAnchorPlacementObjects([]))
    }
  }, [isToolSelected, prevSelectedTool, dispatch])

  /**
   * Callback for ending interaction, e.g. mouse up or touch end.
   */
  const onEndInteraction = useCallback(() => {
    if (movingGridPoint) {
      // Reset plane opacity
      const interval = intervals[movingGridPoint.volumeId]
      const topShapePolygon = shapes.polygons?.find((poly) => poly.shape_id === interval.topPlaneId)
      if (topShapePolygon) {
        dispatch(removeDimmedShapeId(topShapePolygon.shape_id))
      }

      // re-add the grid point back to the working grid points
      const gridPoints = [...workingGridPoints[movingGridPoint.volumeId]]
      gridPoints.splice(movingGridPoint.gridPointIndex, 0, movingGridPoint.points)

      dispatch(updateWorkingGridPoints({ id: movingGridPoint.volumeId, points: gridPoints }))
    }

    setMovingGridPoint(null)
    dispatch(setAnchorPlacementObjects([]))
  }, [workingGridPoints, movingGridPoint, intervals, shapes, dispatch])

  /**
   * Generate distance labels for all grids points.
   */
  const allDistanceLabels = useMemo(
    () =>
      Object.values(existingDistanceLabels)
        .flat()
        .concat(Object.values(workingDistanceLabels).flat())
        .concat([
          ...(movingGridPoint
            ? [
                {
                  id: 'moving-grid-point',
                  bottomAnchorColor: '#f00',
                  points: movingGridPoint.points,
                },
              ]
            : []),
        ]),
    [existingDistanceLabels, movingGridPoint, workingDistanceLabels],
  )

  return {
    objects: {
      distanceLabels: [
        // DEBUG: polygon edge direction vectors
        // ...edgeVectors
        //   .map((vectors) => [
        //     {
        //       id: `edge-direction-${vectors.id}-up`,
        //       points: [vectors.center.toArray(), vectors.up.point.toArray()],
        //       anchorScale: 1.5,
        //       labelOutlineColor: '#fff',
        //       label: 'up',
        //       lineOutline: true,
        //       topAnchorColor: PLANE_SIDE_COLOR.upper,
        //       bottomAnchorColor: PLANE_SIDE_COLOR.lower,
        //     },
        //     {
        //       id: `edge-direction-${vectors.id}-right`,
        //       points: [vectors.center.toArray(), vectors.right.point.toArray()],
        //       anchorScale: 1.5,
        //       labelOutlineColor: '#fff',
        //       label: 'right',
        //       lineOutline: true,
        //       topAnchorColor: PLANE_SIDE_COLOR.upper,
        //       bottomAnchorColor: PLANE_SIDE_COLOR.lower,
        //     },
        //     {
        //       id: `edge-direction-${vectors.id}-forward`,
        //       points: [vectors.center.toArray(), vectors.forward.point.toArray()],
        //       anchorScale: 1.5,
        //       labelOutlineColor: '#fff',
        //       label: 'forward',
        //       lineOutline: true,
        //       topAnchorColor: PLANE_SIDE_COLOR.upper,
        //       bottomAnchorColor: PLANE_SIDE_COLOR.lower,
        //     },
        //   ])
        //   .flat(),
        // All distance labels
        ...allDistanceLabels,
      ],
      labels: warningLabels,
      polygonPlaneMesh: useMemo(
        () => [
          // Minimum boundary (needed for placing grid points, not needed at all times or displayed to user)
          ...(isToolSelected
            ? (shapes?.polygons
                .filter((polygon) => polygon.plane_side === PlaneSide.UPPER)
                .map((polygon) => {
                  const topBoundary = minimumRectangleBoundaries.find(
                    (bundle) => bundle.polygon.shape_id === polygon?.shape_id,
                  )?.boundary

                  if (!topBoundary) return null

                  return {
                    polygon: {
                      shape_id: `minimum-boundary-${polygon.shape_id}`,
                      positions: transform2Dto3D(
                        topBoundary.vertices.map((vertex) => new Vector2(...vertex)),
                        polygon.transformation,
                      ),
                      vertices: topBoundary.vertices,
                      center: transform2Dto3D(
                        [new Vector2(topBoundary.center[0], topBoundary.center[1])],
                        polygon.transformation,
                      )[0],
                    } as Polygon,
                    color: 'pink',
                    opacity: 0,
                    selectable: false,
                  }
                })
                .filter(Boolean) as PolygonPlaneMeshProps[]) || []
            : []),
        ],
        [shapes, minimumRectangleBoundaries, isToolSelected],
      ),
    },
    events: {
      onTouchEnd: onEndInteraction,
      onMouseUp: onEndInteraction,
      /**
       * Callback for moving grid point
       */
      onMove: useCallback(
        (point: PointArray | undefined) => {
          if (!isToolSelected || !movingGridPoint || !workingGridPoints[movingGridPoint.volumeId] || !point) return

          const interval = intervals[movingGridPoint.volumeId]
          if (!interval) return

          // Lock interval config only if it's not locked yet
          if (!interval.locked)
            dispatch(updateInterval({ id: movingGridPoint.volumeId, interval: { ...interval, locked: true } }))

          // Get plane instances for top and bottom planes
          const topPlane = threePlanes[interval.topPlaneId]
          const bottomPlane = threePlanes[interval.bottomPlaneId]

          setMovingGridPoint((prev) =>
            prev
              ? {
                  ...prev,
                  points: [point, findPerpendicularProjection(new Vector3(...point), topPlane, bottomPlane).toArray()],
                }
              : null,
          )
        },
        [movingGridPoint, workingGridPoints, intervals, threePlanes, isToolSelected, dispatch],
      ),
    },
  }
}

export default useMainCanvas
