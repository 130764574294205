import { Matrix4, Quaternion, Vector2, Vector3 } from 'three'

import { PointArray } from 'interfaces/attribute'
import {
  AlignedMinimumAreaBoundary,
  AlignedMinimumAreaBoundaryMirrored,
  DiagramOperation,
  IntersectionResult2D,
  IntersectionResult3D,
  MinimumAreaBoundary,
} from 'interfaces/diagram'
import { CameraProfile, InspectionItemGrid, PolygonDiagramVertice } from 'interfaces/inspection'
import { Polygon } from 'interfaces/shape'

import { getCentroidOfPolygon, getCentroidOfPolygon3d, radiansToDegrees } from './Points'
import { meterToMillimeter } from './Util'

/**
 * Check if a value exceeds or is lower than a certain threshold with a given margin
 * @param value - The value to check
 * @param threshold - The threshold value
 * @param margin - The acceptable margin (tolerance) for both upper and lower thresholds
 * @returns - Returns true if the value exceeds the threshold plus the margin or is lower than the threshold minus the margin,
 *            returns false if the value is within the acceptable range
 */
export const checkAngleOutsideThreshold = (value: number, thresholds: number[], margin: number) =>
  !thresholds.some((threshold) => value < threshold + margin && value > threshold - margin)

/**
 * Normalize a set of coordinates to a specified range.
 *
 * @param coords Coordinates to normalize
 * @param maxX Maximum x value
 * @param maxY Maximum y value
 */
export const normalize = (coords: Vector2[], maxX = 1, maxY = 1): Vector2[] => {
  if (coords.length === 0) return []

  // Extract x, y, and z values separately
  const xValues = coords.map((point) => point.x)
  const yValues = coords.map((point) => point.y)

  // Find min and max for x, y, and z
  const xMin = Math.min(...xValues)
  const xMax = Math.max(...xValues)
  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)

  // Function to normalize a value within the specified range
  const normValue = (value: number, min: number, max: number, maxVal: number) => ((value - min) / (max - min)) * maxVal

  // Normalize the coordinates
  return coords.map(
    (point) =>
      new Vector2(
        xMax === xMin ? 0 : normValue(point.x, xMin, xMax, maxX),
        yMax === yMin ? 0 : normValue(point.y, yMin, yMax, maxY),
      ),
  )
}

/**
 * Find the optimum edges to use for flipping the polygon
 *
 * @param vertices2d Polyon vertices in 2D
 */
export const findOptimumEdges = (
  vertices2d: Vector2[],
): { edge1Index: [number, number]; edge2Index: [number, number]; edge: Vector2 } => {
  for (let i = 0; i < vertices2d.length; i += 1) {
    const id_1 = i - 1 < 0 ? vertices2d.length - 1 : i - 1
    const id0 = i
    const id1 = (i + 1) % vertices2d.length
    const id2 = (i + 2) % vertices2d.length

    // prep edges and reference vectors
    // (edge from current index, the following edge and the previous edge)
    const edge_1 = vertices2d[id0].clone().sub(vertices2d[id_1])
    const edge1 = vertices2d[id1].clone().sub(vertices2d[id0])
    const edge2 = vertices2d[id2].clone().sub(vertices2d[id1])
    const combinedEdge = edge1.add(edge2)
    const combinedEdgeAngle = radiansToDegrees(combinedEdge.angle())
    const prevEdgeAngle = radiansToDegrees(edge_1.angleTo(edge1))

    // make sure the combined edge is not too close to the x-axis and it did not come out of a concave angle
    if (checkAngleOutsideThreshold(combinedEdgeAngle, [0, 180, 360], 5) && prevEdgeAngle > 90) {
      return {
        edge1Index: [id1, id0],
        edge2Index: [id2, id1],
        edge: combinedEdge,
      }
    }
  }

  // default to 1st and 2nd vertices
  const edge2d1 = vertices2d[1].clone().sub(vertices2d[0])
  const edge2d2 = vertices2d[2].clone().sub(vertices2d[1])
  const combinedEdge2d = edge2d1.add(edge2d2)

  return {
    edge1Index: [1, 0],
    edge2Index: [2, 1],
    edge: combinedEdge2d,
  }
}

/**
 * Generate diagram data based on minimum area boundary, which will then be scaled.
 *
 * @param vertices Polygon vertices
 * @param minimumRectangleBoundary Minimum area boundary
 * @param scale Scale factor
 */
export const getDiagramScaledVertices = (
  vertices: PointArray[],
  minimumRectangleBoundary: AlignedMinimumAreaBoundary,
  scale: number,
  pivot: Vector2,
): PolygonDiagramVertice[] => {
  if (!minimumRectangleBoundary?.boundaryAxisAlignment) return []

  let vertices2d = vertices.map((v) => new Vector2(meterToMillimeter(v[0]) * scale, meterToMillimeter(v[1]) * scale))

  if (
    minimumRectangleBoundary.mirrored &&
    (minimumRectangleBoundary.mirrored.horizontally || minimumRectangleBoundary.mirrored.vertically)
  ) {
    vertices2d = vertices2d.map((point) => new Vector2(2 * pivot.x - point.x, point.y))
  }

  vertices2d = vertices2d.map((point) =>
    point.rotateAround(pivot, minimumRectangleBoundary.boundaryAxisAlignment.x.angle),
  )

  // translate by offsets
  vertices2d = vertices2d.map((point) =>
    point.add(
      new Vector2(
        minimumRectangleBoundary.boundaryAxisAlignment.translation[0] * scale,
        minimumRectangleBoundary.boundaryAxisAlignment.translation[1] * scale,
      ),
    ),
  )

  // Add labels
  return vertices2d.map((point, index) => {
    const nextPoint = vertices2d[(index + 1) % vertices2d.length]
    const center = new Vector2().addVectors(point, nextPoint).multiplyScalar(0.5)

    return {
      label: {
        text: (point.distanceTo(nextPoint) * (1 / scale)).toFixed(0),
        point: center,
      },
      point,
    }
  })
}

/**
 * Function to move a 2D line perpendicularly by a specified distance
 *
 * @param start - The start point of the line
 * @param end - The end point of the line
 * @param distance - The distance to move the line perpendicularly
 */
export const moveLinePerpendicularly2D = (start: Vector2, end: Vector2, distance: number) => {
  // Calculate the direction vector of the line
  const direction = new Vector2().subVectors(end, start).normalize()

  // Calculate the perpendicular (normal) vector to the line
  const perpendicular = new Vector2(-direction.y, direction.x)

  // Move the start and end points by the specified distance along the perpendicular vector
  const newStart = start.clone().addScaledVector(perpendicular, distance)
  const newEnd = end.clone().addScaledVector(perpendicular, distance)

  return { newStart, newEnd }
}

/**
 * Check if the projected polygon is flipped horizontally or vertically
 *
 * @deprecated No longer necessary with new method of 3D -> 2D projection
 * @param vertices2d Polygon vertices in 2D
 * @param vertices3d Polygon vertices in 3D
 * @param cameraUpVector Camera's up vector
 * @param cameraRightVector Camera's right vector
 */
/* istanbul ignore next */
export const isFlipped = (
  vertices2d: Vector2[],
  vertices3d: Vector3[],
  cameraUpVector: Vector3,
  cameraRightVector: Vector3,
): AlignedMinimumAreaBoundaryMirrored => {
  // prep edges and reference vectors
  const { edge: combinedEdge2d, edge1Index, edge2Index } = findOptimumEdges(vertices2d)
  const ref2dUp = new Vector2(0, -1)
  const ref2dRight = new Vector2(1, 0)

  // prep polygon's 3D vectors. we need a combined vector of edge 1 and 2
  const edge3d1 = new Vector3().subVectors(vertices3d[edge1Index[0]], vertices3d[edge1Index[1]])
  const edge3d2 = new Vector3().subVectors(vertices3d[edge2Index[0]], vertices3d[edge2Index[1]])
  const combinedEdge3d = edge3d1.add(edge3d2)

  // calculate dot products of both 2d and 3d
  const dot2dUp = combinedEdge2d.dot(ref2dUp)
  const dot2dRight = combinedEdge2d.dot(ref2dRight)
  const dot3dUp = combinedEdge3d.dot(cameraUpVector)
  const dot3dRight = combinedEdge3d.dot(cameraRightVector)

  return {
    horizontally: dot2dRight * dot3dRight < 0,
    vertically: dot2dUp * dot3dUp < 0,
    edge1Index,
    edge2Index,
    intersectionUp2d: null,
    intersectionRight2d: null,
    intersectionUp3d: null,
    intersectionRight3d: null,
  }
}

/**
 * Function to calculate the angle to rotate a point to a reference point around a pivot
 *
 * @param pivot Point to rotate around
 * @param point Point to rotate
 * @param referencePoint Reference point to calculate the angle
 * @returns The angle in radians
 */
export const calculateRotationAngle = (pivot: Vector2, point: Vector2, referencePoint: Vector2): number => {
  // Calculate vectors from the pivot to the point and reference point
  const vector1 = new Vector2().subVectors(point, pivot)
  const vector2 = new Vector2().subVectors(referencePoint, pivot)

  // Calculate the dot product and the magnitude of the vectors
  const dotProduct = vector1.dot(vector2)
  const magnitude1 = vector1.length()
  const magnitude2 = vector2.length()

  // Calculate the angle using the dot product formula
  const angle = Math.acos(dotProduct / (magnitude1 * magnitude2))

  // Calculate the cross product to determine the direction of rotation
  const crossProduct = vector1.x * vector2.y - vector1.y * vector2.x

  // If cross product is negative, the angle is clockwise
  const direction = crossProduct < 0 ? -1 : 1

  // Return the angle in radians, adjusted by the direction
  return direction * angle
}

/**
 * Find the intersection of a polygon that is the closest to the camera's up and right vectors.
 *
 * @param vertices3d The vertices of the polygon in 3D space
 * @param cameraVector The camera's vector
 * @param center The center of the polygon
 */
export const findOptimumIntersection = (
  vertices3d: Vector3[],
  cameraVector: Vector3,
  center: Vector3,
): IntersectionResult3D | null => {
  let result: IntersectionResult3D | null = null
  const all = vertices3d.map<IntersectionResult3D | null>((v, idx) =>
    findIntersection3d(
      vertices3d[idx],
      vertices3d[(idx + 1) % vertices3d.length],
      new Vector3(...center),
      cameraVector
        .clone()
        .multiplyScalar(1)
        .add(new Vector3(...center)),
    ),
  )

  // find the best up
  result = all.reduce((prev, v, idx) => {
    if (v?.intersectionPoint && v.projectedPoint) {
      if (
        ((!prev && v?.intersectionPoint) || (prev && v?.intersectionPoint && prev?.vectorDiff < v?.vectorDiff)) &&
        !v.isOpposite
      )
        return {
          ...v,
          edgeIndices: [idx, (idx + 1) % vertices3d.length],
        }
    }
    return prev
  }, null)

  // if we can't find any up, we'll just take the best one
  if (!result) {
    result = all.reduce((prev, v, idx) => {
      if (
        v?.intersectionPoint &&
        v.projectedPoint &&
        ((!prev && v?.intersectionPoint) || (prev && v?.intersectionPoint && prev?.vectorDiff < v?.vectorDiff))
      ) {
        return {
          ...v,
          edgeIndices: [idx, (idx + 1) % vertices3d.length],
        }
      }
      return prev
    }, null)
  }

  return result
}

/**
 * Get the projected vertices of a polygon based on saved camera's position and rotation.
 *
 * @param cameraProfile Saved camera profile
 * @param focalLength Camera's focal length
 * @param width Diagram width
 * @param height Diagram height
 * @param polygon Polygon to project
 */
export const getProjectedVertices = (
  cameraProfile: CameraProfile,
  boundary: MinimumAreaBoundary,
  width: number,
  height: number,
  polygon: Polygon,
  scale: number,
  alignToAxis = true,
): {
  vertices: Vector2[]
  boundary: Vector2[]
  newWidth: number
  newHeight: number
  result2d: {
    up: IntersectionResult2D | null
    right: IntersectionResult2D | null
  }
  result3d: {
    up: IntersectionResult3D | null
    right: IntersectionResult3D | null
  }
  operations: DiagramOperation[]
  orientation: ReturnType<typeof getOrientation>
} | null => {
  const cameraMatrix = new Matrix4().fromArray(cameraProfile.state.arcballState.cameraMatrix.elements)
  const cameraRotation = new Quaternion().setFromRotationMatrix(cameraMatrix)
  const cameraRightVector = new Vector3(1, 0, 0).applyQuaternion(cameraRotation)
  const cameraUpVector = new Vector3(0, 1, 0).applyQuaternion(cameraRotation)
  const vertices3d = polygon.positions.map((v) => new Vector3(...v))
  const polyCenter = new Vector3(...polygon.center!)

  const result3d = {
    up: findOptimumIntersection(vertices3d, cameraUpVector, polyCenter),
    right: findOptimumIntersection(vertices3d, cameraRightVector, polyCenter),
  }

  if (!result3d.up || !result3d.right) return null

  const operations = [] as DiagramOperation[]

  let updatedBoundary = boundary.vertices.map(
    (point) => new Vector2(meterToMillimeter(point[0]) * scale, meterToMillimeter(point[1]) * scale),
  )
  let updatedTargetPolygon = polygon.vertices.map(
    (v) => new Vector2(meterToMillimeter(v[0]) * scale, meterToMillimeter(v[1]) * scale),
  )
  let centroidTarget = getCentroidOfPolygon(updatedTargetPolygon)
  let upIntersection = updatedTargetPolygon[result3d.up.edgeIndices![1]]
    .clone()
    .sub(updatedTargetPolygon[result3d.up.edgeIndices![0]])
    .normalize()
    .multiplyScalar(meterToMillimeter(result3d.up.duration!) * scale)
    .add(updatedTargetPolygon[result3d.up.edgeIndices![0]])
  let rightIntersection = updatedTargetPolygon[result3d.right.edgeIndices![1]]
    .clone()
    .sub(updatedTargetPolygon[result3d.right.edgeIndices![0]])
    .normalize()
    .multiplyScalar(meterToMillimeter(result3d.right.duration!) * scale)
    .add(updatedTargetPolygon[result3d.right.edgeIndices![0]])

  // Rotate up to actually be up
  const upVector = new Vector2().subVectors(upIntersection, centroidTarget).normalize()
  if (result3d.up.isOpposite) upVector.negate()
  const angle = -(Math.atan2(upVector.y, upVector.x) + Math.PI / 2)
  upIntersection = upIntersection.clone().rotateAround(centroidTarget, angle)
  rightIntersection = rightIntersection.clone().rotateAround(centroidTarget, angle)
  updatedTargetPolygon = updatedTargetPolygon.map((point) => point.clone().rotateAround(centroidTarget, angle))
  updatedBoundary = updatedBoundary.map((point) => point.clone().rotateAround(centroidTarget, angle))
  operations.push({ rotation: { angle, pivot: centroidTarget, reason: 'up' } })
  centroidTarget = getCentroidOfPolygon(updatedTargetPolygon)

  // find out if flipping is necessary by checking right is actually right
  const rightVector = new Vector2().subVectors(rightIntersection, centroidTarget)
  const flipped = new Vector2(1, 0).dot(result3d.right.isOpposite ? rightVector.negate() : rightVector) < 0
  if (flipped) {
    updatedTargetPolygon = updatedTargetPolygon.map((point) => new Vector2(2 * centroidTarget.x - point.x, point.y))
    updatedBoundary = updatedBoundary.map((point) => new Vector2(2 * centroidTarget.x - point.x, point.y))
    upIntersection = new Vector2(2 * centroidTarget.x - upIntersection.x, upIntersection.y)
    rightIntersection = new Vector2(2 * centroidTarget.x - rightIntersection.x, rightIntersection.y)
    operations.push({ flip: { pivot: centroidTarget } })
    centroidTarget = getCentroidOfPolygon(updatedTargetPolygon)
  }

  // // find the vertice index on boundary that is closest to 0,0
  const minDistanceIndex = updatedBoundary.reduce(
    (acc, point, index) => (point.length() < updatedBoundary[acc].length() ? index : acc),
    0,
  )

  // calculate the angle difference between the vertice and the origin for both axes
  const v1 = updatedBoundary[minDistanceIndex]
  const v2 = updatedBoundary[(minDistanceIndex + 1) % updatedBoundary.length]
  const angleDiffX = calculateRotationAngle(v1, v2, new Vector2(v2.x, v1.y))
  const angleDiffY = calculateRotationAngle(v1, v2, new Vector2(v1.x, v2.y))
  const alignToX = Math.abs(angleDiffX) < Math.abs(angleDiffY)

  // // rotate the boundary and polygon to align to the x- or y-axis
  if (alignToAxis) {
    if (alignToX) {
      updatedTargetPolygon = updatedTargetPolygon.map((point) => point.clone().rotateAround(centroidTarget, angleDiffX))
      updatedBoundary = updatedBoundary.map((point) => point.clone().rotateAround(centroidTarget, angleDiffX))
      upIntersection = upIntersection.clone().rotateAround(centroidTarget, angleDiffX)
      rightIntersection = rightIntersection.clone().rotateAround(centroidTarget, angleDiffX)
      operations.push({ rotation: { angle: angleDiffX, pivot: centroidTarget, reason: 'x-axis-alignment' } })
    } else {
      updatedTargetPolygon = updatedTargetPolygon.map((point) => point.clone().rotateAround(centroidTarget, angleDiffY))
      updatedBoundary = updatedBoundary.map((point) => point.clone().rotateAround(centroidTarget, angleDiffY))
      upIntersection = upIntersection.clone().rotateAround(centroidTarget, angleDiffY)
      rightIntersection = rightIntersection.clone().rotateAround(centroidTarget, angleDiffY)
      operations.push({ rotation: { angle: angleDiffY, pivot: centroidTarget, reason: 'y-axis-alignment' } })
    }
    centroidTarget = getCentroidOfPolygon(updatedTargetPolygon)
  }

  // If any of the vertice crosses over the vertice, bring it back to the positive quarter
  const minXValue = Math.min(...updatedTargetPolygon.map((p) => p.x))
  const minYValue = Math.min(...updatedTargetPolygon.map((p) => p.y))
  const translateX = minXValue < 0 ? -minXValue : 0
  const translateY = minYValue < 0 ? -minYValue : 0

  updatedTargetPolygon = updatedTargetPolygon.map((p) => new Vector2(p.x + translateX, p.y + translateY))
  updatedBoundary = updatedBoundary.map((p) => new Vector2(p.x + translateX, p.y + translateY))
  upIntersection = upIntersection.clone().add(new Vector2(translateX, translateY))
  rightIntersection = rightIntersection.clone().add(new Vector2(translateX, translateY))
  centroidTarget = getCentroidOfPolygon(updatedTargetPolygon)
  operations.push({ translate: { fixed: new Vector2(translateX, translateY), reason: 'pull-from-negative' } })

  // If it's too far on any of the axis, move it to 0, 0
  const minTranslatedY = Math.min(...updatedTargetPolygon.map(([, y]) => y))
  const minTranslatedX = Math.min(...updatedTargetPolygon.map(([x]) => x))

  updatedTargetPolygon = updatedTargetPolygon.map(([x, y]) => new Vector2(x - minTranslatedX, y - minTranslatedY))
  updatedBoundary = updatedBoundary.map(([x, y]) => new Vector2(x - minTranslatedX, y - minTranslatedY))
  upIntersection = upIntersection.clone().sub(new Vector2(minTranslatedX, minTranslatedY))
  rightIntersection = rightIntersection.clone().sub(new Vector2(minTranslatedX, minTranslatedY))
  centroidTarget = getCentroidOfPolygon(updatedTargetPolygon)
  operations.push({
    translate: { fixed: new Vector2(minTranslatedX, minTranslatedY).negate(), reason: 'pull-from-positive' },
  })

  // construct matrix from result3d.up, result3d.right

  return {
    vertices: updatedTargetPolygon,
    boundary: updatedBoundary,
    orientation: getOrientation(updatedBoundary.map((point) => [point.x, point.y])),
    result2d: {
      up: {
        intersectionPoint: upIntersection,
        edge: [updatedTargetPolygon[result3d.up.edgeIndices![0]], updatedTargetPolygon[result3d.up.edgeIndices![1]]],
        edgeIndices: result3d.up.edgeIndices,
        duration: result3d.up.duration,
        centroid: centroidTarget,
      },
      right: {
        intersectionPoint: rightIntersection,
        edge: [
          updatedTargetPolygon[result3d.right.edgeIndices![0]],
          updatedTargetPolygon[result3d.right.edgeIndices![1]],
        ],
        edgeIndices: result3d.right.edgeIndices,
        duration: result3d.right.duration,
        centroid: centroidTarget,
      },
    },
    result3d,
    newWidth: width,
    newHeight: height,
    operations,
  }
}

/**
 * Orient the grid points based on the projected diagram.
 *
 * @param operations Operations to apply
 * @param polygon Polygon of the grid
 * @param grid Grid data
 * @param scale Scale factor
 * @param matrixInverse Inverse of the transformation matrix
 */
export const orientGridBasedOnProjectedDiagram = (
  operations: DiagramOperation[],
  polygon: Polygon,
  grid: InspectionItemGrid,
  scale: number,
  matrixInverse: Matrix4,
): { label: string; x: number; y: number; valid: boolean }[] => {
  if (!grid?.list_distances || !operations?.length) return []

  let points = grid.list_distances
    .map((distance) => new Vector3().fromArray(distance.position_on_grid_point).applyMatrix4(matrixInverse))
    .map((point) => new Vector2(meterToMillimeter(point.x) * scale, meterToMillimeter(point.y) * scale))

  // prep original polygon
  let originalPolygon = polygon.vertices.map((v) => new Vector2(meterToMillimeter(v[0]), meterToMillimeter(v[1])))

  operations.forEach(({ flip, rotation, translate }) => {
    if (flip) {
      points = points.map((point) => new Vector2(2 * flip.pivot.x - point.x, point.y))
      originalPolygon = originalPolygon.map((point) => new Vector2(2 * flip.pivot.x - point.x, point.y))
    }

    if (rotation) {
      points = points.map((point) => point.clone().rotateAround(rotation.pivot, rotation.angle))
      originalPolygon = originalPolygon.map((point) => point.clone().rotateAround(rotation.pivot, rotation.angle))
    }

    if (translate?.fixed) {
      points = points.map((point) => point.clone().add(translate.fixed!))
    }

    if (translate?.refBased) {
      let vec: Vector2 | undefined
      if (translate.refBased.refIndex !== undefined) {
        vec = originalPolygon[translate.refBased.refIndex]
      } else if (translate.refBased.targetIndex !== undefined) {
        vec = points[translate.refBased.targetIndex]
      }

      if (vec) {
        points = points.map((point) => {
          if (translate.refBased!.operation === 'add') {
            return point.clone().add(vec)
          }
          return point.clone().sub(vec)
        })
      }
    }

    return points
  })

  return points.map((point, index) => ({
    label: grid.list_distances[index].name,
    x: point.x,
    y: point.y,
    valid: true,
  }))
}

/**
 * Orient positions based on the projected diagram.
 */
export const orientPositionsBasedOnProjectedDiagram = (
  operations: DiagramOperation[],
  positions: Vector3[],
  scale: number,
  matrixInverse: Matrix4,
): Vector2[] => {
  let points = positions
    .map((point) => new Vector3(point.x, point.y, point.z).applyMatrix4(matrixInverse))
    .map((point) => new Vector2(meterToMillimeter(point.x) * scale, meterToMillimeter(point.y) * scale))

  operations.forEach(({ flip, rotation, translate }) => {
    if (flip) {
      points = points.map((point) => new Vector2(2 * flip.pivot.x - point.x, point.y))
    }

    if (rotation) {
      points = points.map((point) => point.clone().rotateAround(rotation.pivot, rotation.angle))
    }

    if (translate?.fixed) {
      points = points.map((point) => point.clone().add(new Vector2(translate.fixed!.x, translate.fixed!.y)))
    }

    if (translate?.refBased) {
      let vec: Vector2 | undefined
      if (translate.refBased.refIndex !== undefined) {
        vec = points[translate.refBased.refIndex]
      } else if (translate.refBased.targetIndex !== undefined) {
        vec = points[translate.refBased.targetIndex]
      }

      if (vec) {
        points = points.map((point) => {
          if (translate.refBased!.operation === 'add') {
            return point.clone().add(vec)
          }
          return point.clone().sub(vec)
        })
      }
    }
  })

  return points
}

/**
 * Calculate grid point positions based on the grid data.
 */
export const getGridForScaledDiagram = (
  minimumRectangleBoundary: AlignedMinimumAreaBoundary,
  grid: InspectionItemGrid,
  scale: number,
  matrixInverse: Matrix4,
  pivot: Vector2,
): { label: string; x: number; y: number; valid: boolean }[] => {
  if (!minimumRectangleBoundary || !grid?.list_distances) return []

  let distances = grid.list_distances
    .map((distance) => ({
      position: new Vector3().fromArray(distance.position_on_grid_point).applyMatrix4(matrixInverse),
      valid: !!distance.distance,
    }))
    .map((distance) => ({
      ...distance,
      position: new Vector2(
        meterToMillimeter(distance.position.x) * scale,
        meterToMillimeter(distance.position.y) * scale,
      ),
    }))

  if (
    minimumRectangleBoundary.mirrored &&
    (minimumRectangleBoundary.mirrored.horizontally || minimumRectangleBoundary.mirrored.vertically)
  ) {
    distances = distances.map((distance) => ({
      ...distance,
      position: new Vector2(2 * pivot.x - distance.position.x, distance.position.y),
    }))
  }

  distances = distances.map((distance) => ({
    ...distance,
    position: distance.position.rotateAround(pivot, minimumRectangleBoundary.boundaryAxisAlignment.x.angle),
  }))

  return distances.map(({ position, valid }, index) => ({
    label: grid.list_distances[index].name,
    x: position.x + minimumRectangleBoundary.boundaryAxisAlignment.translation[0] * scale,
    y: position.y + minimumRectangleBoundary.boundaryAxisAlignment.translation[1] * scale,
    valid,
  }))
}

/**
 * Check if two lines intersect.
 *
 * @param p1 - Start point of line 1
 * @param p2 - End point of line 1
 * @param q1 - Start point of line 2
 * @param q2 - End point of line 2
 */
export const checkIntersection = (p1: Vector2, p2: Vector2, q1: Vector2, q2: Vector2) => {
  const det = (p2.x - p1.x) * (q2.y - q1.y) - (p2.y - p1.y) * (q2.x - q1.x)
  if (det === 0) return null // Parallel lines

  const lambda = ((q2.y - q1.y) * (q2.x - p1.x) + (q1.x - q2.x) * (q2.y - p1.y)) / det
  const gamma = ((p1.y - p2.y) * (q2.x - p1.x) + (p2.x - p1.x) * (q2.y - p1.y)) / det

  if (lambda >= 0 && lambda <= 1 && gamma >= 0 && gamma <= 1) {
    const x = p1.x + lambda * (p2.x - p1.x)
    const y = p1.y + lambda * (p2.y - p1.y)
    return { x, y, lambda }
  }

  return null
}

/**
 * Find the intersection of a polygon with a line projected from the centroid.
 *
 * @param polygon Polygon vertices
 * @param direction Direction to project the line
 */
export const findIntersection = (polygon: Vector2[], direction: 'up' | 'right'): IntersectionResult2D | null => {
  // Calculate the centroid
  const centroid = polygon.reduce(
    (acc, point) => {
      acc.x += point.x
      acc.y += point.y
      return acc
    },
    new Vector2(0, 0),
  )

  centroid.x /= polygon.length
  centroid.y /= polygon.length

  // Project a vertical line upwards from the centroid
  const projectedPoint =
    direction === 'up'
      ? new Vector2(centroid.x, -Number.MAX_SAFE_INTEGER)
      : new Vector2(Number.MAX_SAFE_INTEGER, centroid.y)

  // Find the intersection with the polygon edges
  for (let i = 0; i < polygon.length; i += 1) {
    const edgeStart = polygon[i]
    const edgeEnd = polygon[(i + 1) % polygon.length]

    const intersection = checkIntersection(centroid, projectedPoint, edgeStart, edgeEnd)
    if (intersection) {
      const intersectedPoint = new Vector2(intersection.x, intersection.y)
      const duration = edgeStart.distanceTo(intersectedPoint) / edgeStart.distanceTo(edgeEnd)

      return {
        intersectionPoint: intersectedPoint,
        edge: [edgeStart, edgeEnd],
        edgeIndices: [i, (i + 1) % polygon.length],
        duration,
        centroid,
      }
    }
  }

  return null // No intersection found
}

/**
 * Function to find the closest vector to a reference vector
 * @param vectors - Array of directional vectors
 * @param reference - Reference vector
 * @returns - Angle between vectors
 */
export const vectorDiff = (vector: Vector3, reference: Vector3): number => {
  const normalizedReference = reference.clone().normalize()
  const normalizedVector = vector.clone().normalize()
  return Math.acos(normalizedReference.dot(normalizedVector))
}

/**
 * Function to find the intersection point of two lines in 3D space
 * @param line1Start - Starting point of the first line
 * @param line1End - Ending point of the first line
 * @param line2Start - Starting point of the second line
 * @param line2End - Ending point of the second line
 * @returns - The intersection point or null if they don't intersect
 */
export const findIntersection3d = (
  line1Start: Vector3,
  line1End: Vector3,
  line2Start: Vector3, // center of polygon
  line2End: Vector3, // projected point at the intended direction
): Omit<IntersectionResult3D, 'line1Index'> | null => {
  const line1Dir = new Vector3().subVectors(line1End, line1Start).normalize()
  const line2Dir = new Vector3().subVectors(line2End, line2Start).normalize()

  const cross = new Vector3().crossVectors(line1Dir, line2Dir)
  const crossMagnitude = cross.length()

  if (crossMagnitude === 0) {
    return null // Lines are parallel
  }

  const diff = new Vector3().subVectors(line2Start, line1Start)
  const t = diff.cross(line2Dir).dot(cross) / crossMagnitude ** 2
  const intersectionPoint = line1Start.clone().add(line1Dir.clone().multiplyScalar(t))
  const line1Length = line1Start.distanceTo(line1End)
  const line1StartToIntersectionDistance = intersectionPoint.distanceTo(line1Start)
  const intersectOnLine1 = line1Dir.clone().multiplyScalar(line1StartToIntersectionDistance).add(line1Start)
  const distanceDiff = intersectionPoint.distanceTo(intersectOnLine1)

  const intersectionDir = new Vector3().subVectors(intersectionPoint, line2Start).normalize()
  const intersectionDot = line2Dir.dot(intersectionDir)

  const found =
    Math.abs(distanceDiff) < 0.02 && line1StartToIntersectionDistance <= line1Length ? intersectionPoint : null

  return {
    edgeIndices: null,
    duration: found ? line1StartToIntersectionDistance : null,
    intersectionPoint: found,
    edge: [line1Start, line1End],
    projectedIntersectionPoint: intersectOnLine1,
    centroid: line2Start,
    projectedPoint: line2End,
    vectorDiff: vectorDiff(line1Dir, line2Dir),
    isOpposite: intersectionDot < 0,
  }
}

/**
 * Function to find a point at a specific duration along a line from A to B
 *
 * @param pointA - Starting point A
 * @param pointB - Ending point B
 * @param duration - Duration (0 to 1) along the line from A to B
 * @returns - The point at the specified duration
 */
export const findPointAtDuration = (pointA: Vector3, pointB: Vector3, duration: number) => {
  const direction = new Vector3().subVectors(pointB, pointA)
  return new Vector3().addVectors(pointA, direction.multiplyScalar(duration))
}

/**
 * Figure out the orientation of a polygon. This is specifically for rectangles.
 *
 * @param vertices
 */
export const getOrientation = (vertices: number[][]): 'vertical' | 'horizontal' | undefined => {
  if (vertices.length !== 4) return undefined

  const maxX = Math.max(...vertices.map((v) => v[0]))
  const minX = Math.min(...vertices.map((v) => v[0]))
  const maxY = Math.max(...vertices.map((v) => v[1]))
  const minY = Math.min(...vertices.map((v) => v[1]))

  return Math.abs(maxX - minX) > Math.abs(maxY - minY) ? 'horizontal' : 'vertical'
}

/**
 * Best fits a rectangle inside another rectangle while maintaining aspect ratio.
 * Scales down if too large, scales up if too small.
 *
 * @param containerWidth - Width of the container rectangle.
 * @param containerHeight - Height of the container rectangle.
 * @param rectWidth - Width of the rectangle to fit.
 * @param rectHeight - Height of the rectangle to fit.
 * @returnsThe dimensions and scale factor of the best-fit rectangle.
 */
export const bestFitRectangle = (
  containerWidth: number,
  containerHeight: number,
  rectWidth: number,
  rectHeight: number,
) => {
  // Calculate the aspect ratio of the container and the rectangle
  const containerRatio = containerWidth / containerHeight
  const rectRatio = rectWidth / rectHeight

  // Determine whether to scale by width or height
  const scale = rectRatio > containerRatio ? containerWidth / rectWidth : containerHeight / rectHeight

  // Calculate the new dimensions of the rectangle
  const newWidth = rectWidth * scale
  const newHeight = rectHeight * scale

  return {
    width: newWidth,
    height: newHeight,
    scale,
  }
}

/**
 * Calculate direction vectors for the boundary based on the reference up and right vectors.
 *
 * @param positions Vertices of the boundary
 * @param upVector Reference up vector
 * @param rightVector Reference right vector
 */
export const calculateBoundaryDirectionVectors = (
  positions: Vector3[],
  upVector: Vector3,
  rightVector: Vector3,
): {
  up: {
    direction: Vector3
    point: Vector3
    distance: number
  }
  right: {
    direction: Vector3
    point: Vector3
    distance: number
  }
} | null => {
  if (positions.length !== 4) return null

  const center = getCentroidOfPolygon3d(positions)
  const midpoints = positions.map((point, index) => {
    const nextPoint = positions[(index + 1) % positions.length]
    return new Vector3().addVectors(point, nextPoint).multiplyScalar(0.5)
  })

  const vectors = midpoints.map((point) => ({
    point,
    direction: new Vector3().subVectors(point, center).normalize(),
    distance: point.distanceTo(center),
  }))
  const up = vectors.reduce(
    (prev, v) => (v.direction.dot(upVector) > prev.direction.dot(upVector) ? v : prev),
    vectors[0],
  )
  const right = vectors.reduce(
    (prev, v) => (v.direction.dot(rightVector) > prev.direction.dot(rightVector) ? v : prev),
    vectors[0],
  )

  return { up, right }
}
