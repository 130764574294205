import { User } from '@auth0/auth0-react'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { USER_TYPES } from 'config/constants'

import { UserInfo } from 'interfaces/user'

export interface UserState {
  /**
   * Whether the user has been loaded (both from Auth0 and from the DB).
   */
  userLoaded: boolean

  /**
   * Auth0 user object.
   */
  user: User

  /**
   * User profile from DB.
   */
  userProfile: UserInfo | null

  /**
   * User type from DB.
   */
  userType: string
}

const initialState: UserState = {
  userLoaded: false,
  user: {},
  userProfile: null,
  userType: USER_TYPES.NON_PAYING_USER,
}

export const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setUserProfile: (state, action: PayloadAction<UserInfo>) => {
      state.userProfile = action.payload
      state.userType = action.payload.user_type
    },
    setUserLoaded: (state) => {
      state.userLoaded = true
    },
  },
})

export const { setUser, setUserProfile, setUserLoaded } = slice.actions

export default slice.reducer
