import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from 'pages/dashboard/store/dashboard'
import blueprintReducer from 'pages/projects/blueprint/store/blueprint'
import blueprintVisiblePagesReducer from 'pages/projects/blueprint/store/visiblePages'
import attentionTextReducer from 'pages/projects/common/AttentionText/store/attentionText'
import CuboidReducer from 'pages/projects/editor/shapes/cuboid/store'
import editorReducer from 'pages/projects/editor/store/editor'
import ToolCameraFocusReducer from 'pages/projects/editor/tools/CameraFocus/store'
import ToolCameraProfile from 'pages/projects/editor/tools/CameraProfile/store'
import ToolCustomMeasurementReducer from 'pages/projects/editor/tools/CustomMeasurement/store'
import ToolGridReducer from 'pages/projects/editor/tools/Grid/store'
import ToolMaskPCDReducer from 'pages/projects/editor/tools/MaskPCD/store'
import ToolPlaneDetectionReducer from 'pages/projects/editor/tools/PlaneDetection/store'
import ToolPolylineReducer from 'pages/projects/editor/tools/Polyline/store'
import ToolRebarDetectionReducer from 'pages/projects/editor/tools/RebarDetection/store'
import ToolVolumeEstimationPolygonReducer from 'pages/projects/editor/tools/VolumeEstimation/Polygon/store'
import inspectionSheetReducer from 'pages/projects/inspection-sheet/store'
import { useDispatch } from 'react-redux'
import diagramReducer from 'store/diagram'
import pageReducer from 'store/page'
import systemReducer from 'store/system'
import userReducer from 'store/user'
import videoReducer from 'store/video'

import debugReducer from '../components/DebugPanel/store'
import commentsReducer from '../pages/projects/common/Comments/store/comments'

export const storeConfig = {
  reducer: {
    debug: debugReducer,

    // # Core components
    system: systemReducer,
    page: pageReducer,
    user: userReducer,
    videos: videoReducer,
    attentionText: attentionTextReducer,
    comments: commentsReducer,
    diagram: diagramReducer,

    // # Pages
    dashboard: dashboardReducer,
    editor: editorReducer,
    blueprint: blueprintReducer,
    blueprintVisiblePages: blueprintVisiblePagesReducer,
    inspectionSheet: inspectionSheetReducer,

    // # Editor Tools
    // REFACTOR: Rename to toolMaskPCD
    maskPCD: ToolMaskPCDReducer,
    cuboid: CuboidReducer,
    toolGrid: ToolGridReducer,
    toolCustomMeasurement: ToolCustomMeasurementReducer,
    toolVolumeEstimationPolygon: ToolVolumeEstimationPolygonReducer,
    toolCameraProfile: ToolCameraProfile,
    toolCameraFocus: ToolCameraFocusReducer,
    toolRebarDetection: ToolRebarDetectionReducer,
    toolPlaneDetection: ToolPlaneDetectionReducer,
    toolPolyline: ToolPolylineReducer,
  },
}

export const store = configureStore(storeConfig)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
