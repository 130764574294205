import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { DistanceLabelProps, InspectionItem, LayerStatusExtended } from 'interfaces/interfaces'

export interface CustomMeasurement extends LayerStatusExtended {
  id: string
  distanceLabel: DistanceLabelProps
  inspectionItem?: Partial<InspectionItem>
}

export interface CustomMeasurementState {
  /**
   * Distance measurements.
   */
  measurements: CustomMeasurement[]

  /**
   * Temporary distance measurements for users without permission to save.
   */
  tempMeasurements: CustomMeasurement[]
}

const initialState: CustomMeasurementState = {
  measurements: [],
  tempMeasurements: [],
}

export const slice = createSlice({
  name: 'editor/tools/custom_measurement',
  initialState,
  reducers: {
    setCustomMeasurements: (state, action: PayloadAction<CustomMeasurement[]>) => {
      state.measurements = action.payload
    },
    updateCustomMeasurement: (state, action: PayloadAction<CustomMeasurement>) => {
      state.measurements = state.measurements.map((measurement) =>
        measurement.id === action.payload.id ? action.payload : measurement,
      )
    },
    updateAllCustomMeasurementStatus: (state, action: PayloadAction<LayerStatusExtended>) => {
      state.measurements = state.measurements.map((measurement) => ({ ...measurement, ...action.payload }))
    },
    setTempMeasurements: (state, action: PayloadAction<CustomMeasurement[]>) => {
      state.tempMeasurements = action.payload
    },
    updateTempMeasurement: (state, action: PayloadAction<CustomMeasurement>) => {
      state.tempMeasurements = state.tempMeasurements.map((measurement) =>
        measurement.id === action.payload.id ? action.payload : measurement,
      )
    },
    deleteTempMeasurement: (state, action: PayloadAction<string>) => {
      state.tempMeasurements = state.tempMeasurements.filter((measurement) => measurement.id !== action.payload)
    },
  },
})

export const {
  setCustomMeasurements,
  updateCustomMeasurement,
  updateAllCustomMeasurementStatus,
  setTempMeasurements,
  updateTempMeasurement,
  deleteTempMeasurement,
} = slice.actions

export default slice.reducer
