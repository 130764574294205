import { useCallback, useContext, useEffect, useMemo } from 'react'

import { Text } from '@chakra-ui/react'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import useDetectShapes from 'hooks/useDetectShapes'

import { EDITOR_COLLAPSE_TYPES, EDITOR_TOOLS } from 'config/constants'

import { Editor } from 'interfaces/canvas'
import { EditorConfig } from 'interfaces/editor'
import { ShapeKeyType } from 'interfaces/shape'

import { detectShapes } from 'services/InspectionArea'
import { meterToMillimeter } from 'services/Util'

import { reset, resetFullIfNoWorkingLabels, resetWorking, setDiameter, setIsLoading } from '../store'

export default function useEditor(props: Editor): EditorConfig {
  // Props
  const { selectedTool, isPreviousTool, inspectionSheet, shapes, updateToggledCollapses, changeIsJobRunning } = props

  // Context
  const { showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  // Store
  const dispatch = useAppDispatch()
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const project = useSelector((state: RootState) => state.page.project)
  const maskRegions = useSelector((state: RootState) => state.maskPCD.regions)
  const isLoading = useSelector((state: RootState) => state.toolRebarDetection.isLoading)
  const workingDistanceLabels = useSelector((state: RootState) => state.toolRebarDetection.workingDistanceLabels)
  const isResetable = useSelector((state: RootState) => state.toolRebarDetection.isDirty)
  const baseDiameter = useSelector((state: RootState) => state.toolRebarDetection.baseDiameter)

  // Vars
  const isSelectedTool = useMemo(() => selectedTool === EDITOR_TOOLS.CYLINDER, [selectedTool])

  // Hooks
  const { poll } = useDetectShapes(props, ShapeKeyType.CYLINDER, {
    onFinished: useCallback(() => {
      dispatch(resetWorking())
    }, [dispatch]),
  })

  /**
   * TODO: Figure out diameter from existing data
   */
  useEffect(() => {
    if (!isSelectedTool || !shapes.cylinders?.length) return

    dispatch(setDiameter(meterToMillimeter(shapes.cylinders[shapes.cylinders.length - 1]?.diameter)))
  }, [isSelectedTool, shapes.cylinders, dispatch])

  /**
   * Start rebar detection
   */
  const startRebarDetection = useCallback(async () => {
    if (!inspectionArea?.down_sampled_file?.path || !project || !inspectionSheet) {
      return false
    }

    // If diameter has not been set, highlight the diameter panel and return
    if (!baseDiameter) {
      document.getElementById('property-panel')?.style.setProperty('background-color', 'red')
      setTimeout(() => {
        document.getElementById('property-panel')?.style.removeProperty('background-color')
      }, 550)
      return false
    }

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    dispatch(setIsLoading(true))
    changeIsJobRunning(true)
    dispatch(setAttentionText({ message: '鉄筋を検出中...' }))

    const result = await detectShapes(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      inspectionSheet.inspection_sheet_id,
      inspectionArea.down_sampled_file?.path,
      ShapeKeyType.CYLINDER,
      {
        ...INITIAL_SHAPE_STATE(),
        cylinders: workingDistanceLabels.map((label) => ({
          diameter: label.diameter,
          points: label.points,
        })),
      },
      maskRegions.filter((region) => !region.invisible),
      showErrorModal,
    )

    if (result) {
      void poll(result)
      return true
    }

    dispatch(setIsLoading(false))
    changeIsJobRunning(false)
    dispatch(setAttentionText({ message: '' }))
    return true
  }, [
    inspectionArea,
    inspectionSheet,
    project,
    workingDistanceLabels,
    maskRegions,
    baseDiameter,
    getAccessToken,
    showErrorModal,
    poll,
    changeIsJobRunning,
    dispatch,
  ])

  /**
   * Toggle info panels when tool is selected
   */
  useEffect(() => {
    if (!isSelectedTool) {
      if (isPreviousTool(EDITOR_TOOLS.CYLINDER) && selectedTool !== EDITOR_TOOLS.FOCUS) {
        dispatch(resetFullIfNoWorkingLabels())
      }

      return
    }

    updateToggledCollapses([EDITOR_COLLAPSE_TYPES.detecting, EDITOR_COLLAPSE_TYPES.diameter])
  }, [isSelectedTool, isPreviousTool, selectedTool, updateToggledCollapses, dispatch])

  /**
   * Reset on unmount
   */
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch],
  )

  return {
    buttons: {
      submit: {
        key: 'rebar-detection-submit',
        label: `鉄筋を検出`,
        loadingLabel: '検出中',
        tooltip: !baseDiameter ? <Text color="orange.400">直径指定パネルから直径を選択してください</Text> : undefined,
        onClick: startRebarDetection,
        isShown: useCallback(() => isSelectedTool || isLoading, [isSelectedTool, isLoading]),
        isLoading: useCallback(() => isLoading, [isLoading]),
        isDisabled: useCallback(() => isLoading || !workingDistanceLabels.length, [isLoading, workingDistanceLabels]),
      },
      reset: {
        onClick: useCallback(() => dispatch(resetWorking()), [dispatch]),
        isShown: useCallback(() => isSelectedTool || isLoading, [isSelectedTool, isLoading]),
        isDisabled: useCallback(() => isLoading || !isResetable, [isLoading, isResetable]),
      },
    },
  }
}
