/* istanbul ignore file */
import { useCallback, useContext, useMemo, useState } from 'react'

import { ThreeEvent } from '@react-three/fiber'
import { uniqueId } from 'lodash'
import { CursorState, setCursor } from 'pages/projects/editor/store/editor'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { DistanceLabelProps, LabelProps, LineStyle } from 'interfaces/canvas'
import { CanvasConfig } from 'interfaces/editor'

import { pointArrayToVector3 } from 'services/Points'
import { getDistanceLabel } from 'services/Util'

import {
  Polyline,
  WORKING_ID_PREFIX,
  addWorkingPolyline,
  patchWorkingPolyline,
  setFirstPointPlaced,
  setIsDirty,
  setWorkingLabel,
  undoLastWorkingPolyline,
} from '../store'

const DISTANCE_LABEL_STYLES: Partial<DistanceLabelProps> = {
  anchorProps: {
    color: '#555',
  },
  lineColor: '#000',
  lineOutline: true,
  lineOutlineColor: 'lightgreen',
  labelBgColor: 'lightgreen',
  labelTextColor: '#000',
}

const DISTANCE_LABEL_STYLES_HOVERED: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  lineColor: '#fff',
}

const DISTANCE_LABEL_STYLES_SELECTED: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES,
  lineColor: '#4cb364',
  lineOutlineColor: 'yellow',
}

const DISTANCE_LABEL_STYLES_WORKING_LABEL: Partial<DistanceLabelProps> = {
  anchorProps: {
    color: '#f00',
  },
  lineColor: '#000',
  lineOutline: true,
  lineOutlineColor: 'yellow',
  labelBgColor: 'lightgreen',
  labelTextColor: '#000',
}

// For working polyline itself (not working label)
const DISTANCE_LABEL_STYLES_WORKING_POLY: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL,
  topAnchorProps: {
    ...DISTANCE_LABEL_STYLES_WORKING_LABEL.topAnchorProps,
    color: '#555',
  },
  bottomAnchorProps: {
    ...DISTANCE_LABEL_STYLES_WORKING_LABEL.bottomAnchorProps,
    color: '#f00',
  },
}

const DISTANCE_LABEL_WORKING_COMPLETING: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_POLY,
  lineColor: '#f00',
}

const DISTANCE_LABEL_COMPLETED: Partial<DistanceLabelProps> = {
  ...DISTANCE_LABEL_STYLES_WORKING_LABEL,
  topAnchorProps: undefined,
  bottomAnchorProps: undefined,
  anchorProps: {
    color: '#555',
  },
  lineColor: '#000',
  lineOutlineColor: 'pink',
}

const useMainCanvas = (): CanvasConfig => {
  // Context
  const { selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const polylines = useSelector((state: RootState) => state.toolPolyline.polylines)
  const workingPolylines = useSelector((state: RootState) => state.toolPolyline.workingPolylines)
  const hoveredShapeId = useSelector((state: RootState) => state.editor.hoveredShapeId)
  const workingLabel = useSelector((state: RootState) => state.toolPolyline.workingLabel)
  const firstPointPlaced = useSelector((state: RootState) => state.toolPolyline.firstPointPlaced)

  // State
  const [isClosing, setIsClosing] = useState(false)

  // Flags
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.POLYLINE, [selectedTool])
  const currentWorkingPolyline: Polyline | undefined = useMemo(() => workingPolylines.slice(-1)[0], [workingPolylines])
  const hideWorkingLabel = workingPolylines.filter((p) => !p.completed).slice(-1)[0]?.invisible

  /**
   * Add a working polyline from the working label.
   */
  const addPolyline = useCallback(
    (extra?: Partial<Polyline>) => {
      if (!workingLabel) return

      dispatch(
        addWorkingPolyline({
          ...extra,
          inspection_item_id: uniqueId(WORKING_ID_PREFIX),
          part_name: `延長`,
          shape_ids: INITIAL_SHAPE_STATE(),
          polyline_length: {
            positions_for_distance: workingLabel.points,
          },
        }),
      )
    },
    [workingLabel, dispatch],
  )

  /**
   * Check if the working label points are at the same place.
   */
  const isWorkingLabelPointsSamePlace = useMemo(() => {
    if (!workingLabel || workingLabel.points.length !== 2) return false

    return workingLabel.points[0].join(',') === workingLabel.points[1].join(',')
  }, [workingLabel])

  /**
   * End drawing of polyline
   */
  const endPolyline = useCallback(
    (addFinalPoint = true) => {
      if (!workingLabel) return

      if (!currentWorkingPolyline || currentWorkingPolyline.completed) {
        addPolyline({
          completed: true,
        })
      } else {
        dispatch(
          patchWorkingPolyline({
            ...currentWorkingPolyline,
            polyline_length:
              workingLabel.points[1] && addFinalPoint
                ? {
                    positions_for_distance: [
                      ...currentWorkingPolyline.polyline_length!.positions_for_distance!,
                      workingLabel.points[1],
                    ],
                  }
                : currentWorkingPolyline.polyline_length,
            completed: true,
          }),
        )
      }
      dispatch(setCursor(CursorState.CROSSHAIR))
      dispatch(setFirstPointPlaced(false))
      dispatch(
        setWorkingLabel({
          ...workingLabel,
          points: [workingLabel.points[1] ?? workingLabel.points[0]],
        }),
      )
    },
    [currentWorkingPolyline, workingLabel, addPolyline, dispatch],
  )

  /**
   * Get anchor events for the polyline.
   * This is separated from the function that defines working label to ensure
   * it will always refer to the latest state of the polyline since the working
   * label is re-used through re-renders.
   */
  const getAnchorEvents = useCallback(() => {
    // if there's already a working polyline, use the last point as the first point.
    // otherwise, use current point.
    const isInteractable =
      (isToolSelected || selectedTool === EDITOR_TOOLS.MOVE) &&
      currentWorkingPolyline &&
      !currentWorkingPolyline.completed

    return {
      tooltipText: isInteractable ? 'この延長の作成を完了' : undefined,
      onMove: isInteractable
        ? (event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation()
            dispatch(setCursor(CursorState.POINTER))
            setIsClosing(true)
          }
        : undefined,
      onLeave: isInteractable
        ? (event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation()
            dispatch(setCursor(selectedTool === EDITOR_TOOLS.MOVE ? CursorState.DEFAULT : CursorState.CROSSHAIR))
            setIsClosing(false)
          }
        : undefined,
      onUp: isInteractable
        ? (event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation()
            setIsClosing(true)
            endPolyline(false)
          }
        : undefined,
    }
  }, [isToolSelected, selectedTool, currentWorkingPolyline, endPolyline, dispatch])

  /**
   * Get working label definition with only the first point.
   */
  const getWorkingLabelOnlyFirstPoint = useCallback(
    (points: PointArray) =>
      ({
        ...DISTANCE_LABEL_STYLES_WORKING_POLY,
        id: `working-label-${WORKING_ID_PREFIX}`,
        points: [points],
        label: '',
      }) as DistanceLabelProps,
    [],
  )

  /**
   * Get the total distance label of the polyline.
   */
  const getTotalDistanceLabel = useCallback((allPoints: PointArray[]): LabelProps => {
    // Go through each section of the polyline and calculate the distance between them, then the total distance.
    const totalDistance = allPoints.reduce<number>((collection, point, index) => {
      const labelPoints = [point, allPoints[index + 1]]
      if (!labelPoints[1]) return collection
      return collection + pointArrayToVector3(labelPoints[0]).distanceTo(pointArrayToVector3(labelPoints[1]))
    }, 0)

    // Figure out the mid-point of the polyline.
    const midPointDistance = totalDistance / 2
    const midPoint = allPoints.reduce<{ point: PointArray; totalDistance: number }>(
      (collection, point, index) => {
        if (collection.totalDistance >= midPointDistance) return collection

        const labelPoints = [point, allPoints[index + 1]]
        if (!labelPoints[1]) return collection

        const distance = pointArrayToVector3(labelPoints[0]).distanceTo(pointArrayToVector3(labelPoints[1]))
        if (collection.totalDistance + distance < midPointDistance) {
          collection.totalDistance += distance
          return collection
        }

        const remainingDistance = midPointDistance - collection.totalDistance
        const result = pointArrayToVector3(labelPoints[0])
          .lerp(pointArrayToVector3(labelPoints[1]), remainingDistance / distance)
          .toArray()

        return { point: result, totalDistance: midPointDistance }
      },
      {
        point: [0, 0, 0],
        totalDistance: 0,
      },
    ).point

    return {
      point: midPoint,
      message: '', // this is tooltip, not using it
      label: getDistanceLabel(totalDistance),
      labelPrefix: '',
      backgroundColor: 'lightgreen',
      color: '#333333',
      padding: [5, 2],
    }
  }, [])

  return {
    objects: {
      /**
       * Labels of total distance of the polyline.
       */
      labels: [
        // Generate labels for working polylines.
        ...useMemo(
          () =>
            workingPolylines
              .filter((polyline) => !polyline.invisible)
              .map<LabelProps | null>((polyline) => {
                const points = polyline.polyline_length?.positions_for_distance

                if (!points || points.length < 2) return null

                // Collect all points of this polyline, and if it's not completed, add the working label points.
                const allPoints = polyline.completed ? points : [...points, ...(workingLabel?.points || [])]

                return {
                  ...getTotalDistanceLabel(allPoints),
                  backgroundColor: polyline.completed ? 'lightgreen' : 'white',
                }
              }),
          [workingPolylines, workingLabel?.points, getTotalDistanceLabel],
        ),

        // Generate labels for saved polylines.
        ...useMemo(
          () =>
            polylines.map<LabelProps | null>((polyline) => {
              const points = polyline.polyline_length?.positions_for_distance
              const isHovered = hoveredShapeId === polyline.inspection_item_id

              if (!points || points.length < 2 || (!isHovered && !polyline.selected)) return null

              return getTotalDistanceLabel(points)
            }),
          [polylines, hoveredShapeId, getTotalDistanceLabel],
        ),
      ].filter(Boolean) as LabelProps[],

      distanceLabels: [
        /**
         * Generate distance labels for saved polyline.
         */
        ...useMemo(
          () =>
            polylines
              .filter((polyline) => !polyline.invisible)
              .map<DistanceLabelProps[] | null>((polyline) => {
                const points = polyline.polyline_length?.positions_for_distance
                if (!points) return null

                let style = DISTANCE_LABEL_STYLES
                const isHovered = hoveredShapeId === polyline.inspection_item_id
                if (isHovered) {
                  style = DISTANCE_LABEL_STYLES_HOVERED
                } else if (polyline.selected) {
                  style = DISTANCE_LABEL_STYLES_SELECTED
                }

                // Each Polyline has multiple distance labels, each with 2 anchors where
                // the following point is the end of the previous label.
                // eg: [0, 1, 2, 3, 4] => [[0, 1], [1, 2], [2, 3], [3, 4]]
                return points
                  .reduce<DistanceLabelProps[]>((collection, point, index) => {
                    if (index === points.length - 1) return collection

                    const labelPoints = [point, points[index + 1]]
                    const label: DistanceLabelProps = {
                      ...style,
                      id: `polyline-${polyline.inspection_item_id}-${index}`,
                      points: labelPoints,
                      hideTopAnchor: true,
                      hideBottomAnchor: true,
                      lineStyle: LineStyle.Dashed,
                    }

                    return collection.concat(label)
                  }, [])
                  .flat()
              })
              .flat(),
          [polylines, hoveredShapeId],
        ),

        /**
         * Generate distance labels for working polyline.
         */
        ...useMemo(
          () =>
            workingPolylines
              .filter((polyline) => !polyline.invisible)
              .map<DistanceLabelProps[] | null>((polyline) => {
                const points = polyline.polyline_length?.positions_for_distance

                if (!points) return null

                // Each Polyline has multiple distance labels, each with 2 anchors where
                // the following point is the end of the previous label.
                // eg: [0, 1, 2, 3, 4] => [[0, 1], [1, 2], [2, 3], [3, 4]]
                return points
                  .reduce<DistanceLabelProps[]>((collection, point, index) => {
                    if (index === points.length - 1) return collection

                    // Only the first label will have the first anchor as the start point
                    // bottom is always hidden since the working label will continue after it
                    const labelPoints = [point, points[index + 1]]
                    const hideBottomAnchor =
                      !polyline.completed || index !== points.length - 2 || (points.length > 2 && index === 0)
                    const hideTopAnchor = index !== 0

                    if (labelPoints.length < 2 || labelPoints.some((p) => !p)) return collection

                    // the colors need to change based on its state (selected, hover)
                    let style = DISTANCE_LABEL_STYLES_WORKING_POLY
                    if (isClosing && currentWorkingPolyline?.inspection_item_id === polyline.inspection_item_id) {
                      style = DISTANCE_LABEL_WORKING_COMPLETING
                    } else if (polyline.completed) {
                      style = DISTANCE_LABEL_COMPLETED
                    }

                    const label: DistanceLabelProps = {
                      ...style,
                      id: `working-polyline-${polyline.inspection_item_id}-${index}`,
                      points: labelPoints,
                      hideTopAnchor,
                      hideBottomAnchor,
                      lineStyle: LineStyle.Dashed,
                    }

                    return collection.concat(label)
                  }, [])
                  .flat()
              })
              .flat(),
          [workingPolylines, currentWorkingPolyline, isClosing],
        ),

        /**
         * Working distance label.
         * - This label will be displayed when the user is drawing a polyline.
         * - Once user completes the distance label (both point placed) it will be added to the polyline state.
         * - It will follow current polyline visibility state.
         * No point of useMemo here since it updates on mouse move.
         */
        workingLabel?.points.length && !hideWorkingLabel
          ? ({
              ...workingLabel,
              topAnchorProps: {
                ...workingLabel.topAnchorProps,
                ...getAnchorEvents(),
              },
            } as DistanceLabelProps)
          : null,
      ].filter(Boolean) as DistanceLabelProps[],
    },
    events: {
      /**
       * Main action to draw the polyline.
       * - If there's only 1 point on the distance label, commit the point to the working label as its first point.
       * - If there are 2 points;
       *   - If the polyline does not have any points yet, commit both points (first in working label and current point) to the polyline.
       *   - If the polyline already has points, only commit the current point to the working label.
       *   - Whichever the case, the working label will be reset.
       * - On double click, end the polyline drawing.
       *
       * @param e Mouse event
       * @param points Clicked point in 3D Space
       */
      onMouseUp(e, points, { isDoubleClicked, isDragged }) {
        if (!points || isDragged) return

        if (isToolSelected) {
          let currentPoly: Polyline | undefined = workingPolylines.slice(-1)[0]
          if (currentPoly?.completed) {
            currentPoly = undefined
          }

          // TODO: check if clicked in close proximity to the last point
          // This is to help touch user to close without having to be too precise.

          // Double click to end the polyline
          if (isDoubleClicked) {
            const lastWorkingPolyline = workingPolylines.slice(-1)[0]
            if (lastWorkingPolyline && !lastWorkingPolyline.completed) {
              endPolyline(!isWorkingLabelPointsSamePlace)
              setIsClosing(false)
              return
            }
          }

          // Disable adding points if the polyline is being ended.
          if (isClosing) {
            setIsClosing(false)
            return
          }

          // Placing down the first point.
          // For mouse interactions, no need to do anything other than the flag. onMove will handle the rest.
          // For touch interactions, we need to add the first point to the working label.
          if (!firstPointPlaced) {
            if (!workingLabel) {
              dispatch(setWorkingLabel(getWorkingLabelOnlyFirstPoint(points)))
            }

            dispatch(setIsDirty(true))
            dispatch(setFirstPointPlaced(true))
            return
          }

          // Further processing are to complete the label and add it to the polyline.
          // If working label is missing for some reason, there's nothing that can be done.
          // Can happen if the user clicks too fast.
          if (!workingLabel || workingLabel.points.length < 2 || workingLabel.points.some((p) => !p)) return

          // Get current working polyline. If there's none yet, create a new one.
          if (!currentPoly) {
            addPolyline()
            dispatch(setWorkingLabel(getWorkingLabelOnlyFirstPoint(workingLabel.points[1])))
            return
          }

          // If there's already a polyline, add the points to the working polyline.
          dispatch(
            patchWorkingPolyline({
              ...currentPoly,
              polyline_length: {
                positions_for_distance: [
                  ...currentPoly.polyline_length!.positions_for_distance!,
                  workingLabel.points[1],
                ],
              },
            }),
          )
          dispatch(setWorkingLabel(getWorkingLabelOnlyFirstPoint(workingLabel.points[1])))
        }
      },

      /**
       * On movement of the mouse, add/update the working distance label.
       * - If there's no working distance label, an anchor will be added and always follow mouse movement.
       * - If there's a working distance label, the second anchor will be added to it and follows mouse movement,
       *   plus a complete distance label will be displayed.
       *
       * The working label points are not part of the polyline state until the second anchor is placed.
       *
       * @param points Point in 3D Space
       */
      onMove(points, mouseEvent, touchEvent) {
        if (!points || touchEvent) return // ignore touch events

        if (isToolSelected) {
          let updatedWorkingLabel = { ...workingLabel } as DistanceLabelProps | undefined

          if ((!currentWorkingPolyline || currentWorkingPolyline.completed) && !firstPointPlaced) {
            updatedWorkingLabel = getWorkingLabelOnlyFirstPoint(points)
          } else if (updatedWorkingLabel?.points[0]) {
            const distance = pointArrayToVector3(updatedWorkingLabel.points[0]).distanceTo(pointArrayToVector3(points))
            const canHaveLabel =
              ((!currentWorkingPolyline?.completed &&
                currentWorkingPolyline?.polyline_length?.positions_for_distance?.length) ||
                0) < 2
            updatedWorkingLabel = {
              ...updatedWorkingLabel,
              ...DISTANCE_LABEL_STYLES_WORKING_LABEL,
              hideBottomAnchor: isClosing,
              points: [updatedWorkingLabel.points[0], points],
              label: !isClosing && distance && canHaveLabel ? getDistanceLabel(distance) : undefined,
              labelBgColor: 'white',
              labelTextColor: '#333333',
            }
          }

          dispatch(setWorkingLabel(updatedWorkingLabel))
        }
      },

      /**
       * Undo the last point of the working polyline.
       * If the last polyline is completed, it will remove the last point and make it the drawing stage.
       */
      onUndo() {
        dispatch(undoLastWorkingPolyline())
        setIsClosing(false)
      },
    },
  }
}

export default useMainCanvas
