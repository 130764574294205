import { FC, useCallback, useContext, useState } from 'react'

import { Flex, Text, VStack } from '@chakra-ui/react'
import { setAttentionText } from 'pages/projects/common/AttentionText/store/attentionText'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import LayerItem from 'pages/projects/editor/infoPanels/components/LayerItem'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { EDITOR_COLLAPSE_TYPES, MODAL_TYPES } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { DepthType } from 'interfaces/canvas'

import { getDepthEstimationItems, getDepthTypeColor } from 'services/DepthEstimation'
import { deleteInspectionItem } from 'services/InspectionSheet'
import { zeroPad } from 'services/Util'

const DepthPanel: FC = () => {
  // Store
  const dispatch = useAppDispatch()
  const permissionSet = useSelector((state: RootState) => state.editor.permissionSet)
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)
  const userType = useSelector((state: RootState) => state.user.userType)

  // Context
  const { getAccessToken } = useContext(UserContext)
  const {
    selectedPoint,
    isLayerModifying,
    depthEstimationTypes,
    inspectionSheet,
    inspectionItems,
    setDepthEstimationTypes,
    fetchInspectionItems,
    setIsLayerModifying,
    changeCollidingShapeIds,
  } = useContext(EditorContext)

  // Permission check
  const isAllowedToModify = permissionSet.MODIFY.includes(userType)

  const { showModal, showErrorModal } = useContext(GlobalModalContext)

  const [isCollapseOpen, setIsCollapseOpen] = useState(true)

  /**
   * Delete all of Depth Estimations distance values.
   */
  const deleteDepthEstimations = useCallback(() => {
    if (!project || !inspectionArea || !inspectionSheet) {
      return
    }

    showModal({
      title: `かぶり厚の削除`,
      body: <Text>かぶり厚測定値を削除します。一度削除してしまうと、元に戻せません。</Text>,
      confirmText: '削除',
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          // get inspection item IDs. Run through filter first for safety.
          // Running delete with empty/null ID can be dangerous
          const inspectionItemIds = depthEstimationTypes
            .filter((row) => !!row.inspectionItems?.length)
            .map((row) => row.inspectionItems!.map((item) => item.inspection_item_id!) || [])
            .flat()

          if (inspectionItemIds.length) {
            setIsLayerModifying(true)
            dispatch(setAttentionText({ message: 'データを更新中...' }))
            const token = await getAccessToken()
            if (!token) {
              return false
            }

            const success = await Promise.all(
              inspectionItemIds.map((id) =>
                deleteInspectionItem(
                  token,
                  project.project_id,
                  inspectionArea.inspection_area_id,
                  inspectionSheet.inspection_sheet_id,
                  id,
                  showErrorModal,
                ),
              ),
            )

            // Refresh inspection sheet even if only 1 succeeds.
            if (success.some((val) => val)) {
              await fetchInspectionItems()
            }

            changeCollidingShapeIds([])
            setIsLayerModifying(false)
            dispatch(setAttentionText({ message: '' }))
          }

          return true
        })()
        return true
      },
    })
  }, [
    depthEstimationTypes,
    project,
    inspectionArea,
    inspectionSheet,
    fetchInspectionItems,
    setIsLayerModifying,
    getAccessToken,
    showModal,
    showErrorModal,
    dispatch,
    changeCollidingShapeIds,
  ])

  /**
   * Update visibility of all mode value's visibility
   * @param {DepthType} depthType The object should contain the new value of visibility
   */
  const updateAllLayerVisibility = useCallback(
    (props: DepthType) => {
      setDepthEstimationTypes([...depthEstimationTypes].map((type) => ({ ...type, ...props })))
    },
    [depthEstimationTypes, setDepthEstimationTypes],
  )

  /**
   * Update specfic mode value's visibility
   * @param {boolean} visibility New visibility
   * @param {number} index Array index of the mode
   */
  const updateLayerVisibility = useCallback(
    (visibility: boolean, index: number) => {
      const newArray = [...depthEstimationTypes]
      newArray[index].invisible = visibility
      setDepthEstimationTypes(newArray)
    },
    [depthEstimationTypes, setDepthEstimationTypes],
  )

  if (!getDepthEstimationItems(inspectionItems).length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isCollapseOpen ? 20 : 10}
      data-testid="depth-panel"
    >
      <CollapsePanel title="測定した距離" type={EDITOR_COLLAPSE_TYPES.depth} onChange={setIsCollapseOpen}>
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={depthEstimationTypes.filter((type) => type.invisible).length === depthEstimationTypes.length}
            label="全て表示"
            updateVisibility={(invisible) => updateAllLayerVisibility({ invisible })}
            deleteLayer={isAllowedToModify ? deleteDepthEstimations : undefined}
          />
          {depthEstimationTypes.map(
            (type, index) =>
              type.distanceAnchors?.length && (
                <LayerItem
                  colorLabel={getDepthTypeColor(index + 1)}
                  disabled={isLayerModifying}
                  selected={selectedPoint?.anchorIndex === index}
                  invisible={type.invisible}
                  key={`measured-depth-listitem-${zeroPad(index, 3)}`}
                  label={type.label}
                  updateVisibility={(invisible) => updateLayerVisibility(invisible, index)}
                  isChild
                />
              ),
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default DepthPanel
