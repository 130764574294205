import axios from 'axios'
import { AttentionTextIcon } from 'pages/projects/common/AttentionText/store/attentionText'

import { API_GATEWAY_URL } from 'config/environments'

import {
  AnchorPoints,
  Anchors,
  Cuboid,
  InspectionItem,
  Polygon,
  PolygonDetectionResult,
  Shape,
  ShapeKey,
  ShapeKeyType,
  Shapes,
} from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { getInternalShapeParameters } from './InspectionArea'

const DETECT_POLYGONS_VOLUME_API_URL = `${API_GATEWAY_URL}/detect-polygons-volume`

const ATTENTION_MESSAGES: { message: string; icon?: keyof typeof AttentionTextIcon }[] = [
  { message: '仕上がり面の3点を選択してください。', icon: 'topPlane' },
  { message: '斫り面の3点を選択してください。', icon: 'bottomPlane' },
  {
    message:
      '右下の「体積を測定」をクリックしてください。\n平面を選び直す場合は右側の「検出中の要素」パネルから平面を削除してください。',
    icon: undefined,
  },
]
export const VOLUME_ESTIMATION_IN_PROGRESS_TEXT = '体積を測定中です...'

/**
 * @deprecated
 */
/* istanbul ignore next */
export const getStepText = (anchors: AnchorPoints[]) => {
  if (anchors.length === 1) return ATTENTION_MESSAGES[anchors.some((anchor) => anchor.plane_side === 'lower') ? 0 : 1]
  return ATTENTION_MESSAGES[anchors.length]
}

/**
 * Find and return the inspection item resposible for volume estimation.
 * If there are multiple inspection item with volume defined, it will
 * return the first with non-null value.
 *
 * May return undefined if it couldn't find one.
 *
 * @param {InspectionItem[]} items Array of inspection items.
 * @param {string} shape_id Restrict to only inspection item with the provided shape ID.
 * @returns {InspectionItem | undefined}
 */
export const getVolumeEstimationItem = (items: InspectionItem[], shape_id?: string): InspectionItem | undefined =>
  items.find(
    (item) =>
      item.volume &&
      !!item.volume.estimated_value &&
      (!shape_id || (shape_id && (item.shape_ids.polygons || []).includes(shape_id))),
  )

/**
 * Find and return all inspection item resposible for volume estimation.
 *
 * @param {InspectionItem[]} items Array of inspection items.
 * @param {string} shape_id Restrict to only inspection item with the provided shape ID.
 * @returns {InspectionItem[]} Will return an empty array if none found.
 */
export const getVolumeEstimationItems = (items: InspectionItem[], shape_id?: string): InspectionItem[] =>
  items.filter(
    (item) =>
      item.volume &&
      !!item.volume.estimated_value &&
      (!shape_id || (shape_id && (item.shape_ids.polygons || []).includes(shape_id))),
  )

/**
 * Retrieves the inspection item IDs of polygon shapes associated with a specific volume ID.
 * @param volumeId - The ID of the volume.
 * @param items - The array of inspection items.
 * @returns An array of inspection item IDs of polygon shapes associated with the specified volume ID.
 */
export const getPolygonIdsFromVolumeId = (items: InspectionItem[], volumeId: string) =>
  items.find((item) => item.item_type === 'volume' && item.inspection_item_id === volumeId)?.shape_ids.polygons || []

/**
 * Get the label of a plane based on its state.
 *
 * @param shape Plane or Polygon.
 * @param inspectionItems Inspection items.
 * @returns Label value
 */
export const getPlaneLabel = (shape: Polygon, inspectionItems: InspectionItem[]) => {
  const volumeItem = getVolumeEstimationItem(inspectionItems, shape.shape_id)
  if (volumeItem?.volume?.estimated_value) return `${volumeItem.part_name} : ${volumeItem.volume.estimated_value}m³`

  return ''
}

/**
 * Get the partner plane of planes used for a volume.
 *
 * @param planeId Plane ID.
 * @param volumeItem
 */
export const getPlanePairPartner = (planeId: string, volumeItem: InspectionItem) => {
  if (!volumeItem.shape_ids.polygons.includes(planeId)) return undefined
  const shapes = volumeItem.shape_ids.polygons
  if (shapes.length !== 2) return undefined
  return shapes.find((id) => id !== planeId)
}

/**
 * Get a shape based on its ID.
 *
 * @param shapeId Shape ID
 * @param shapeKey Shape type
 * @param shapes All shapes
 */
export const getShapeById = (shapeId: string, shapeKey: ShapeKeyType, shapes: Shapes): Shape | undefined => {
  if (shapeKey === ShapeKeyType.CYLINDER) return shapes.cylinders.find((shape) => shape.shape_id === shapeId)
  if (shapeKey === ShapeKeyType.POLYGON) return shapes.polygons.find((shape) => shape.shape_id === shapeId)
  return undefined
}

/**
 * Get the volume name based on the volume item and index.
 * @param volume
 * @param index
 */
export const getVolumeName = (volume: InspectionItem, index: number) => volume.part_name || `体積 ${index + 1}`

/**
 * Volume estimation for polygons
 * @param {string} access_token token
 * @param {string} projectId project ID
 * @param {string} inspectionAreaId inspection area ID
 * @param {string} inspectionSheetId inspection sheet ID
 * @param {string} downSampledFilePath downsampled file path
 * @param {ShapeKey} shape_type shape type
 * @param {Anchors} anchors anchors
 * @param {Cuboid[]} maskRegions mask regions
 * @param {function} showErrorModal error modal
 * @return {string | null} job token
 */
export const detectPolygonsVolume = async (
  access_token: string,
  projectId: string,
  inspectionAreaId: string,
  inspectionSheetId: string,
  downSampledFilePath: string,
  shape_type: ShapeKey,
  anchors: Anchors,
  maskRegions: Cuboid[],
  showErrorModal: (message: string) => void,
): Promise<string | null> => {
  const token = await axios
    .post<{
      job_token: string
    }>(
      `${DETECT_POLYGONS_VOLUME_API_URL}`,
      {
        project_id: projectId,
        inspection_area_id: inspectionAreaId,
        inspection_sheet_id: inspectionSheetId,
        part_name: '',
        path_pcd: downSampledFilePath,
        parameters_for_detecting_shapes: anchors[shape_type]
          .filter((anchor) => !anchor.deleted)
          .map((anchor) => ({
            internal_shape_parameters: getInternalShapeParameters(anchor.diameter || 0, shape_type),
            positions: anchor.points.map((point) => ({ x: point[0], y: point[1], z: point[2] })),
            plane_side: anchor.plane_side,
            is_virtual: anchor.is_virtual,
          })),
        mask_region_ids: maskRegions.map((maskRegion) => maskRegion.region_id),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      },
    )
    .then((response) => response.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ESTIMATE_VOLUME, showErrorModal)
      return null
    })

  return token
}

/**
 * Check status of long-running jobs. By default will swallow failed jobs and not show error modal.
 * To show error modal, set throwError to true.
 *
 * @param {string} access_token token
 * @param {string} jobToken job's token - get from the detect shape API
 * @param {function} showErrorModal error modal
 * @param {boolean} throwError show error modal if job failed
 * @return {object | null} detection result
 */
export const checkJobStatusForDetectPolygonsVolume = async (
  access_token: string,
  jobToken: string,
  showErrorModal: (message: string) => void,
): Promise<{
  shape_type?: ShapeKey
  list_shapes: PolygonDetectionResult[]
  inspection_items: InspectionItem[]
  error_object: string
} | null> => {
  const shapes = await axios
    .get<{
      job_status: string
      results: {
        error_object: string
        shape_type: ShapeKey
        list_shapes: PolygonDetectionResult[]
        inspection_items: InspectionItem[]
      }
    }>(`${API_GATEWAY_URL}/job-status?token=${jobToken}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      if (response.data.job_status === 'SUCCEEDED') {
        return response.data.results
      }
      if (response.data.job_status === 'FAILED') {
        return { ...response.data.results, list_shapes: [], inspection_items: [] }
      }
      return null
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.ESTIMATE_VOLUME, showErrorModal)
      return null
    })

  return shapes
}
