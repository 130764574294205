import './styles.css'

import { FC } from 'react'

import {
  Box,
  Button,
  HStack,
  Hide,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Square,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useLocation, useMatches, useParams } from 'react-router-dom'
import { RootState } from 'store/app'

import { BackIcon, CubeIcon, GridIcon, ListIcon, LogoLight, MenuIcon } from 'assets/icons'

import {
  BUTTON_FONT_SIZE,
  EDITOR_NAVBAR_HEIGHT,
  EDITOR_NAVBAR_HEIGHT_TABLET,
  EDITOR_NAVBAR_PADDING,
  USER_TYPES,
} from 'config/constants'

import CADExportButton from '../../editor/actions/CADExportButton'
import PCDExportButton from '../../editor/actions/PCDExportButton'
import AreaSwitcher from '../AreaSwitcher'
import NavbarPageButton from './components/NavbarPageButton'

const Navbar: FC = () => {
  // Store
  const userType = useSelector((state: RootState) => state.user.userType)
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)

  // Route matching to check current page
  const matches = useMatches()
  const isBlueprintPage = matches.some((row) => row.id.includes('blueprint'))
  const isEditorPage = matches.some((row) => row.id.includes('editor'))
  const isInspectionSheetPage = matches.some((row) => row.id.includes('inspection-sheet'))

  // Get project ID from URL
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')
  if (!project_id) {
    return null
  }

  return (
    <HStack background="gray.900" p={EDITOR_NAVBAR_PADDING} w="100%" m={0} id="project-navbar" zIndex={150}>
      <HStack
        w="100%"
        h={{ base: EDITOR_NAVBAR_HEIGHT_TABLET, xl: EDITOR_NAVBAR_HEIGHT }}
        justifyContent="start"
        gap={4}
      >
        <Button
          as={RouterLink}
          variant="ghostLink"
          leftIcon={<BackIcon />}
          to={`/dashboard#${project_id}`}
          size={{ base: 'lg', xl: 'xs' }}
          h="100%"
        >
          工事一覧
        </Button>
        <HStack
          spacing={0}
          backgroundColor="gray.800"
          overflow="hidden"
          borderRadius="md"
          divider={<StackDivider borderColor="whiteAlpha.200" />}
        >
          <>
            <NavbarPageButton
              id="editor-page-button"
              title="3D画面"
              tooltip={
                <Box>
                  <Text>3D画面に移動</Text>
                  {!inspection_area_id && <Text color="orange">エリアを選択してください</Text>}
                </Box>
              }
              icon={<CubeIcon />}
              path={`/projects/${project_id}/editor?area=${inspection_area_id}`}
              isActive={isEditorPage}
              isDisabled={!inspection_area_id || isPageLoading}
            />
            <NavbarPageButton
              id="blueprint-page-button"
              title="設計図面"
              tooltip="設計図面に移動"
              icon={<GridIcon />}
              path={
                !inspection_area_id
                  ? `/projects/${project_id}/blueprints`
                  : `/projects/${project_id}/blueprints?area=${inspection_area_id}`
              }
              isActive={isBlueprintPage}
              isDisabled={isPageLoading}
            />
            <NavbarPageButton
              id="inspection-sheet-page-button"
              title="帳票詳細"
              tooltip="帳票詳細に移動"
              icon={<ListIcon />}
              path={
                !inspection_area_id
                  ? `/projects/${project_id}/inspection-sheet`
                  : `/projects/${project_id}/inspection-sheet?area=${inspection_area_id}`
              }
              isActive={isInspectionSheetPage}
              isDisabled={isPageLoading}
            />
          </>
        </HStack>
      </HStack>

      <Hide below={isBlueprintPage ? 'xl' : 'md'}>
        <HStack>
          <LogoLight height="28" />
        </HStack>
      </Hide>

      <HStack w="100%" justifyContent="end" gap={2}>
        <AreaSwitcher />

        {isEditorPage && (
          <Menu>
            <MenuButton
              bg="gray.800"
              color="gray.300"
              borderColor="gray.600"
              borderWidth={1}
              _hover={{ bg: 'gray.700' }}
              _active={{ bg: 'gray.700' }}
              as={IconButton}
              icon={
                <Square fontSize={BUTTON_FONT_SIZE}>
                  <MenuIcon />
                </Square>
              }
            />
            <MenuList>
              {userType === USER_TYPES.ADMIN && <PCDExportButton />}
              <CADExportButton />
            </MenuList>
          </Menu>
        )}
      </HStack>
    </HStack>
  )
}

export default Navbar
