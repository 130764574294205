/* istanbul ignore file */

/**
 * Interfaces related to the editor.
 */
import React, { ReactElement } from 'react'

import { Points } from 'three'

import { USER_TYPES } from 'config/constants'

import { PointArray } from './attribute'
import {
  CircleAnchorProps,
  DistanceLabelProps,
  Editor,
  LabelProps,
  PolygonPlaneMeshProps,
  PolygonPlaneMeshTransformableProps,
} from './canvas'
import { ShapeKeyType } from './shape'
import { PermissionSets } from './validation'

/**
 * Extra properties/flags for editor events.
 */
export interface CanvasEventsExtra {
  /**
   * User has clicked on the same point twice.
   */
  isDoubleClicked: boolean

  /**
   * User has clicked on the same point 3 times.
   */
  isTripleClicked: boolean

  /**
   * User has been dragging the canvas.
   */
  isDragged: boolean
}

/**
 * All events that could occur on the canvas.
 */
export interface CanvasEvents {
  /**
   * Mouse up event from the canvas.
   *
   * @param e MouseEvent
   * @param points Clicked point in 3D Space
   * @param extra Extra properties/flags
   */
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, points: PointArray | undefined) => void

  /**
   * Raw mouse up event from the canvas.
   * Since this runs before the onMouseUp event, it will not have any extra properties (double click detection, etc)
   * or any click validation.
   *
   * Generally you do not want to use this event, use onMouseUp instead.
   *
   * Note that having this does not prevent onMouseUp from running.
   *
   * @param e MouseEvent
   * @param points Clicked point in 3D Space
   * @param extra Extra properties/flags
   */
  onRawMouseUp?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, points: PointArray | undefined) => void

  /**
   * Mouse up event from the canvas.
   * This is only triggered with left click or touch. To handle right click, use onRawMouseUp.
   *
   * Note that having this does not prevent onRawMouseUp from running.
   *
   * @param e MouseEvent
   * @param points Clicked point in 3D Space
   * @param extra Extra properties/flags
   */
  onMouseUp?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    points: PointArray | undefined,
    extra: CanvasEventsExtra,
  ) => void

  /**
   * Move event from the canvas (mouse or touch).
   *
   * @param points Clicked point in 3D Space
   * @param mouseEvent MouseEvent
   * @param touchEvent TouchEvent
   * @param extra Extra properties/flags
   */
  onMove?: (
    points: PointArray | undefined,
    mouseEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    touchEvent?: React.TouchEvent<HTMLDivElement>,
    extra?: CanvasEventsExtra,
  ) => void

  /**
   * Touch start event from the canvas.
   *
   * @param points Clicked point in 3D Space
   */
  onTouchStart?: (touchEvent: React.TouchEvent<HTMLDivElement>, points: PointArray | undefined) => void

  /**
   * Touch move event from the canvas.
   *
   * @param points Current point in 3D Space
   */
  onTouchMoveCapture?: (touchEvent: React.TouchEvent<HTMLDivElement>, points: PointArray | undefined) => void

  /**
   * Touch end event from the canvas. End touch events does not provide location of the touch.
   * Use the last known location from the onTouchMove event.
   */
  onTouchEnd?: (touchEvent: React.TouchEvent<HTMLDivElement>, extra: CanvasEventsExtra) => void

  /**
   * User initiated undo through keyboard shortcut or button.
   */
  onUndo?: () => void
}

/**
 * Objects to be shown on the canvas.
 */
export interface CanvasConfigObjects {
  /**
   * Distance labels to be shown on the canvas.
   */
  distanceLabels?: DistanceLabelProps[]

  /**
   * Warning labels to be shown on the canvas.
   */
  labels?: LabelProps[]

  /**
   * Circle anchors to be shown on the canvas.
   */
  circleAnchors?: CircleAnchorProps[]

  /**
   * Polygon plane mesh to be shown on the canvas.
   */
  polygonPlaneMesh?: PolygonPlaneMeshProps[]

  /**
   * Transformable polygon plane mesh to be shown on the canvas.
   */
  polygonPlaneMeshTransformable?: PolygonPlaneMeshTransformableProps[]
}

/**
 * Configuration for the canvas.
 */
export interface CanvasConfig {
  events?: CanvasEvents
  objects?: CanvasConfigObjects
}

/**
 * Props to be accepted by tool hooks.
 */
export interface CanvasProps {
  pointCloud: Points | undefined
}

/**
 * Events for editor buttons.
 */
export interface EditorButtonEvents {
  /**
   * Click event handler.
   */
  onClick: () => void
}

/**
 * Editor button congfiguration.
 */
export interface EditorButton extends EditorButtonEvents {
  /**
   * Unique key of this button. It must be unique across all tools.
   */
  key?: string

  /**
   * Label of the button.
   */
  label?: string

  /**
   * Label to be shown when the action is in progress.
   */
  loadingLabel?: string

  /**
   * Tooltip to be shown when hovering over the button.
   */
  tooltip?: string | ReactElement

  /**
   * Indicates the tool is processing.
   */
  isLoading?: () => boolean

  /**
   * Whether the button should be shown.
   */
  isShown: () => boolean

  /**
   * Whether the button should be disabled.
   */
  isDisabled: () => boolean
}

/**
 * Buttons to be shown on the bottom-right corner of the canvas.
 */
export interface EditorButtons {
  /**
   * Trigger the save function of the current tool.
   */
  submit?: EditorButton

  /**
   * Completely reset the current tool. It should set the state back to the initial state.
   */
  reset?: EditorButton

  /**
   * Undo the last action.
   * It does not have any events since it is handled by the editor itself
   * and propagated to event hook in useMainCanvas.
   */
  undo?: Omit<EditorButton, keyof EditorButtonEvents>
}

export interface EditorConfig {
  buttons?: EditorButtons
}

export enum ToolbarCategory {
  None,
  Navigate,
  Generate,
  Manipulate,
  Help,
}

/**
 * Definition of a tool to be used by Editor.
 */
export interface EditorTool {
  /**
   * Tool key (must be from EDITOR_TOOLS constant)
   */
  key: string

  /**
   * Check if the tool is allowed for the user.
   *
   * @param permissionSets User's permission sets.
   * @param userType User's type.
   * @returns
   */
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) => boolean

  /**
   * Toolbar-related configurations
   */
  toolbar?: {
    /**
     * Tool icon. Must be a React component.
     * Define it first in assets/icons.tsx
     */
    icon: ReactElement

    /**
     * Tool label. Used for tooltip when hovering over the tool.
     */
    label: string

    /**
     * Tool variants configuration. Usually used for multiple tools under the same category.
     */
    variants?: EditorTool[]

    /**
     * Tool category. Used to group tools in the toolbar.
     * Default is ToolbarCategory.None.
     */
    category?: ToolbarCategory
  }

  /**
   * Tool hooks
   */
  hooks?: {
    /**
     * Hook to be executed by Editor.tsx
     * Usually used to define components to be shown on the canvas.
     * Context value is passed directly here since the hook is run _inside_ Editor.tsx
     * itself. The context has not been set yet, so useContext() will not work.
     */
    useEditor?: (contextValue: Editor) => EditorConfig

    /**
     * Hook for MainCanvas.
     * Most behavior will defined in this hook.
     *
     * @param props
     */
    useMainCanvas?: (props: CanvasProps) => CanvasConfig
  }

  /**
   * Components to be added to various parts of the editor.
   */
  components?: {
    /**
     * Components to be added on the editor. This will be added outside of the canvas.
     * Use this to add modals, etc.
     * Must be a React component.
     */
    editor?: React.FC[]

    /**
     * Components to be added on the canvas.
     * Must be a THREE.js component.
     */
    canvas?: React.FC[]

    /**
     * Component to be added on the info panel.
     * By default, panels will follow the order the tool was registered.
     * In the case panel is to be ordered differently, specify in the form of [Component, order] where order is the order of the panel.
     * Check other modules and InfoPanels.tsx for what value to be used in respect of other panels.
     * The order minimum value is 50 unless you specifically want to place the panel in between standard panels.
     */
    panel?: [React.FC, number][] | React.FC[]
  }

  /**
   * Configuration for the tool.
   */
  config?: {
    /**
     * Additional toolbars to be shown.
     */
    additionalToolbars?: {
      /**
       * Whether to use cuboid controls.
       */
      cuboidControls?: boolean
    }

    /**
     * Volume configuration.
     */
    volume?: {
      /**
       * Whether the tool requires a volume to be created.
       */
      required?: boolean

      /**
       * If volume can be selected.
       */
      selectable?: boolean

      /**
       * If only one volume can be selected.
       */
      onlyOneSelectable?: boolean
    }

    cylinder?: {
      /**
       * Whether the tool requires a cylinder to be created.
       */
      required?: boolean
    }

    /**
     * Config for selecting/placing anchors.
     */
    anchor?: {
      /**
       * Whether anchor can be placed on objects.
       * By default, anchors can be placed on objects.
       */
      notPlaceableOnObjects?: boolean
    }

    /**
     * Type of shapes that can be created by this tool.
     */
    selectableShapes?: ShapeKeyType[]
  }
}
