import { FC, MutableRefObject, ReactElement, RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, VStack } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { BackSide, Camera, CylinderGeometry, Euler, Matrix4, Mesh, MeshBasicMaterial, Object3D } from 'three'
import { ArcballControls } from 'three-stdlib'

import { WarningTwoIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import useMeshOutline from 'hooks/MeshOutline'
import useShape from 'hooks/Shape'

import { EDITOR_SHAPE_KEYS } from 'config/constants'

import { Cylinder } from 'interfaces/interfaces'

import { getCenterPoint } from 'services/Editor'
import { calculateRebarSurfaceArea } from 'services/Util'

//* 鉄筋ジオメトリ
const createCylinderGeometry = (diameter: number, length: number) =>
  new CylinderGeometry(diameter / 2, diameter / 2, length, 64, 1, false, 0, Math.PI * 2)
//* 鉄筋マテリアル
const standardMaterial = new MeshBasicMaterial({ color: 'red' })
const hoveredMaterial = new MeshBasicMaterial({ color: 'yellow' })
const selectedMaterial = new MeshBasicMaterial({
  color: 'white',
  side: BackSide,
})
//* 検出された鉄筋はZ軸方向が上となるため、X軸を90度回転させるオイラー角を定義
const cylinderEuler = new Euler(Math.PI / 2, 0, 0)

export const CylinderMesh: FC<{
  cameraRef: RefObject<Camera> | null
  cylinder: Cylinder
  invisible?: boolean
  labelPrefix?: string | JSX.Element | ReactElement
  arcballControls: ArcballControls | null
}> = ({ cameraRef, cylinder, invisible, labelPrefix, arcballControls }) => {
  // Context
  const { updateMeshRefs, collidingShapeIds } = useContext(EditorContext)

  // Store
  const selectedShapeIds = useSelector((state: RootState) => state.editor.selectedShapeIds)

  // States
  const [cylinderMesh, setCylinderMesh] = useState<Mesh>(new Mesh())
  const [outlineMesh, setOutlineMesh] = useState<Mesh>(new Mesh())

  // Hooks
  const { outlineThickness } = useMeshOutline(arcballControls)
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut, onPointerMove, isFocus } = useShape(
    cylinder,
    EDITOR_SHAPE_KEYS.CYLINDERS,
    cameraRef,
  )

  // Refs
  const meshRef = useRef<Mesh | null>(null)

  // States
  const [label, setLabel] = useState<string | ReactElement>('')

  // Vars
  const isColliding = collidingShapeIds.includes(cylinder.shape_id)
  const surfaceArea = useMemo(() => calculateRebarSurfaceArea(cylinder), [cylinder])
  const isSelected = useMemo(() => selectedShapeIds.includes(cylinder.shape_id), [selectedShapeIds, cylinder.shape_id])

  useEffect(() => {
    if (meshRef.current) {
      updateMeshRefs({ [cylinder.shape_id]: meshRef as MutableRefObject<Object3D> })
    }
  }, [updateMeshRefs, cylinder.shape_id, meshRef])

  useEffect(() => {
    const { diameter, length, transformation } = cylinder
    if (transformation?.length !== 16) {
      return
    }
    const matrix = new Matrix4().set(...transformation)

    //* X軸をZ軸方向に90度回転させ、鉄筋回転を適用したmeshを生成
    const mesh = new Mesh()
    mesh.quaternion.setFromEuler(cylinderEuler)
    mesh.applyMatrix4(matrix)

    // used for outline effect
    const outlineCylinderMesh = mesh.clone()
    setOutlineMesh(outlineCylinderMesh)

    //* 円柱の形状設定
    const geo = createCylinderGeometry(diameter, length)
    geo.translate(0, cylinder.length / 2, 0)
    mesh.geometry = geo

    //* state保存
    setCylinderMesh(mesh)
  }, [cylinder])

  useEffect(() => {
    // set label
    setLabel('')
    if (isSelected || isFocus) setLabel(surfaceArea.toFixed(4))
  }, [isFocus, surfaceArea, isSelected])

  useEffect(() => {
    if (!outlineMesh || !selectedShapeIds.includes(cylinder.shape_id)) {
      return
    }

    // used for outline effect
    const { diameter, length } = cylinder
    const outlineGeo = createCylinderGeometry(diameter + outlineThickness, length + outlineThickness)
    outlineGeo.translate(0, length / 2, 0)
    outlineMesh.geometry = outlineGeo
  }, [cylinder, outlineMesh, outlineThickness, selectedShapeIds])

  if (!cylinder) return null

  if (invisible)
    return (
      <mesh
        visible={false}
        ref={meshRef}
        quaternion={cylinderMesh.quaternion}
        position={cylinderMesh.position}
        geometry={cylinderMesh.geometry}
      />
    )

  return (
    <>
      <mesh
        ref={meshRef}
        onPointerUp={onPointerUp}
        onPointerDown={onPointerDown}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerMove={onPointerMove}
        quaternion={cylinderMesh.quaternion}
        position={cylinderMesh.position}
        geometry={cylinderMesh.geometry}
        material={isFocus ? hoveredMaterial : standardMaterial}
      >
        {/* Label */}
        {(isColliding || !!label) && (
          <Html
            position={getCenterPoint(cylinderMesh)}
            style={{ transform: 'translateX(-50%) translateY(-50%)', pointerEvents: 'none' }}
            zIndexRange={[1, 9]}
          >
            <VStack spacing={0}>
              {isColliding && (
                <Box backgroundColor="transparent" px={2} fontSize="130%" fontWeight="bold" color="black">
                  <WarningTwoIcon color="orange" bgColor="black" rounded={1} />
                </Box>
              )}
              {!!label && (
                <Box backgroundColor="yellow" px={2} fontSize="80%" fontWeight="bold" color="black" whiteSpace="nowrap">
                  {labelPrefix ? <>{labelPrefix}: </> : ''}
                  {label}m&sup2;
                </Box>
              )}
            </VStack>
          </Html>
        )}
      </mesh>
      {selectedShapeIds.includes(cylinder.shape_id) && (
        <mesh
          quaternion={outlineMesh.quaternion}
          position={outlineMesh.position}
          geometry={outlineMesh.geometry}
          material={selectedMaterial}
        />
      )}
    </>
  )
}
