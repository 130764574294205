import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import {
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Square,
  StackDivider,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import TutorialVideoModal from 'components/TutorialVideoModal'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'
import { setSelectedVideoConfig } from 'store/video'

import useLongPress from 'hooks/LongPress'

import { HELP_CENTER_URL } from 'config/constants'

import { TutorialVideoConfig } from 'interfaces/tutorialVideoConfig'

const ToolbarButton: FC<{
  icon: React.ReactElement
  label: string
  warningLabel?: string | ReactElement
  toolType: string
  variants?: {
    icon: React.ReactElement
    toolType: string
    label: string
  }[]
  buttonSize: number | Partial<{ base: number; sm: number; md: number; lg: number; xl: number; '2xl': number }>
  toolTipPlacement?: PlacementWithLogical
  selectedTool: string
  isActive?: boolean
  disabled?: boolean
  changeTool: (tool: string) => void
}> = ({
  icon,
  label,
  warningLabel,
  toolType,
  variants,
  buttonSize,
  toolTipPlacement,
  selectedTool,
  isActive,
  disabled = false,
  changeTool,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  // Refs
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const [selectedVariant, setSelectedVariant] = useState(toolType)

  // store
  const dispatch = useAppDispatch()
  const videos = useSelector((root: RootState) => root.videos.videos)

  // Get the selected video configuration
  // The tool change modal is only displayed if it has a videoUrl or description.
  // Otherwise, it is displayed when the help icon is pressed.
  const vc = useMemo(() => videos.find((video) => video.toolType === selectedVariant), [videos, selectedVariant])
  const defaultVc = useMemo(
    () => ({
      toolType: selectedVariant,
      version: 0,
      title: variants?.find((v) => v.toolType === selectedVariant)?.label
        ? `${label} - ${variants.find((v) => v.toolType === selectedVariant)!.label}`
        : label,
      helpCenterUrl: HELP_CENTER_URL,
    }),
    [selectedVariant, label, variants],
  )

  // The modal display when a tool is changed is not shown if there is no setting in AppConfig.
  const videoConfig: TutorialVideoConfig | undefined = useMemo(
    () =>
      vc
        ? {
            ...defaultVc,
            ...vc,
          }
        : undefined,
    [vc, defaultVc],
  )

  // The modal display when the ? icon is clicked is displayed
  // with the default setting even if there is no setting in AppConfig.
  useEffect(() => {
    if (selectedTool === selectedVariant) {
      dispatch(setSelectedVideoConfig(videoConfig || defaultVc))
    }
  }, [videoConfig, selectedTool, selectedVariant, dispatch, defaultVc])

  const onLongPress = () => {
    if (!disabled) onToggle()
  }
  const useDisclosureProps = useDisclosure()
  const onClick = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (disabled) return

    // Get the version of the watched video from localStorage
    const watchedVersion = localStorage.getItem(selectedVariant)
    const isVideoWatched = videoConfig && watchedVersion ? Number(watchedVersion) >= videoConfig.version : false
    if (!isVideoWatched && videoConfig) {
      useDisclosureProps.onOpen()
      localStorage.setItem(selectedVariant, videoConfig.version.toString())

      // Tracking
      mixpanel.track('Show Help Modal', {
        'Selected Tool': selectedVariant,
        'Video URL': videoConfig.videoUrl,
        'External URL': videoConfig.helpCenterUrl,
        'Triggered By': 'Auto',
        'Help Config Version': videoConfig.version,
      })
    }
    changeTool(selectedVariant)
  }

  const mouseLongPress = useLongPress({ onLongPress, onClick }, { delay: 1200, shouldPreventDefault: true })
  return (
    <>
      {(videoConfig?.description || videoConfig?.videoUrl) && (
        <TutorialVideoModal useDisclosureProps={useDisclosureProps} {...videoConfig} />
      )}
      <Tooltip
        placement={toolTipPlacement || 'right'}
        hasArrow
        label={
          <>
            <Text fontSize="sm">{label}</Text>
            {warningLabel ? (
              <Text fontSize="xs" color="orange.400" mt={2}>
                {warningLabel}
              </Text>
            ) : null}
          </>
        }
        p={2}
        fontSize="xs"
        fontWeight="normal"
      >
        <Flex userSelect="none" className="toolbar-button">
          <Square
            data-testid={`${label}ToolbarButton`}
            ref={buttonRef}
            {...mouseLongPress}
            position="relative"
            backgroundColor={selectedTool === selectedVariant ? 'gray.600' : 'transparent'}
            color="white"
            opacity={disabled ? 0.2 : 1}
            fontSize="xl"
            size={buttonSize}
            className={[isActive ? 'active' : null, selectedTool === selectedVariant ? 'selected' : null]
              .filter(Boolean)
              .join(' ')}
          >
            {variants?.find((v) => v.toolType === selectedVariant)?.icon || icon}
            {variants && (
              <Square
                position="absolute"
                bottom={0}
                right={0}
                size={2}
                borderBottomColor="secondary.400"
                borderRightColor="secondary.400"
                borderTopColor="transparent"
                borderLeftColor="transparent"
                borderWidth={4}
              />
            )}
          </Square>
        </Flex>
      </Tooltip>
      {variants && (
        <Portal>
          <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement="right-start" closeOnBlur>
            <PopoverTrigger>
              <Square
                position="absolute"
                size={buttonSize}
                pointerEvents="none"
                backgroundColor="transparent"
                top={(buttonRef.current?.offsetTop || 0) + 35}
                zIndex={0}
              />
            </PopoverTrigger>
            <PopoverContent w={buttonSize} borderWidth={0} backgroundColor="transparent">
              <PopoverBody
                p={0}
                backgroundColor="secondary.800"
                borderColor="secondary.700"
                borderWidth={1}
                overflow="hidden"
                borderRadius="md"
              >
                <VStack spacing={0} divider={<StackDivider borderColor="whiteAlpha.200" />}>
                  {variants.map((variant) => (
                    <Tooltip
                      key={variant.toolType}
                      hasArrow
                      placement="right"
                      label={variant.label}
                      p={2}
                      fontSize="xs"
                      fontWeight="normal"
                    >
                      <Square
                        data-testid={`${variant.label}ToolbarButton`}
                        position="relative"
                        backgroundColor={selectedVariant === variant.toolType ? 'secondary.600' : 'transparent'}
                        color="white"
                        fontSize="xl"
                        size={buttonSize}
                        onClick={(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
                          e.stopPropagation()
                          setSelectedVariant(variant.toolType)

                          // Get the version of the watched video from localStorage
                          const variantVc = videos.find((video) => video.toolType === variant.toolType)
                          const watchedVersion = localStorage.getItem(variant.toolType)
                          const isVideoWatched =
                            variantVc && watchedVersion ? Number(watchedVersion) >= variantVc.version : false
                          if (!isVideoWatched && variantVc) {
                            useDisclosureProps.onOpen()
                            localStorage.setItem(variant.toolType, variantVc.version.toString())
                          }
                          changeTool(variant.toolType)
                          onToggle()
                        }}
                      >
                        {variant.icon}
                      </Square>
                    </Tooltip>
                  ))}
                </VStack>
              </PopoverBody>
              <PopoverArrow
                left={12}
                backgroundColor="secondary.800"
                borderColor="secondary.700"
                boxShadow="-1px 1px 1px 0 var(--chakra-colors-gray-600) !important"
              />
            </PopoverContent>
          </Popover>
        </Portal>
      )}
    </>
  )
}

export default ToolbarButton
