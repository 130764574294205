import { RefObject, createContext, useContext } from 'react'

import { PerspectiveCamera } from 'three'
import { ArcballControls } from 'three-stdlib'

import { DEFAULT_EDITOR_TOOL } from 'config/constants'

import { Editor } from 'interfaces/interfaces'

// To prevent mis-reuse of this object, use this function to create a new object
export const INITIAL_SHAPE_STATE = () => ({
  cylinders: [],
  tori: [],
  planes: [],
  cuboid: [],
  polygons: [],
})
const initialState: Editor = {
  addDistanceAnchor: () => null,
  anchors: INITIAL_SHAPE_STATE(),
  backgroundColor: '',
  changeBackgroundColor: () => null,
  changeBaseDiameter: () => null,
  changeCuboidDirection: () => null,
  changeCommentPopupPosition: () => null,
  changeHoveredPoint: () => null,
  changeIsJobRunning: () => null,
  changeIsDragging: () => null,
  changeIsMouseDown: () => null,
  changeIsToolProcessing: () => null,
  changeSelectedInspectionItem: () => null,
  changePointSize: () => null,
  changeProcessingAnchor: () => null,
  changeSelectedPoint: () => null,
  changeSelectedShapeIds: () => null,
  changeTool: () => null,
  changeSubTool: () => null,
  setPrevSelectedTool: () => null,
  commentBody: '',
  commentDeletingImageIndexes: [],
  commentEditingCaptions: [],
  commentImages: [],
  commentImageHandlingIndex: -1,
  commentName: '',
  commentOpeningImageIds: [],
  commentUploadedImages: [],
  deleteShapes: () => null,
  distanceAnchors: [],
  initCompleted: false,
  isJobRunning: false,
  isDragging: false,
  isLayerModifying: false,
  isMouseDown: false,
  isPointCloudInvisible: false,
  isToolProcessing: false,
  selectedTool: DEFAULT_EDITOR_TOOL,
  prevSelectedTool: DEFAULT_EDITOR_TOOL,
  prevPrevSelectedTool: DEFAULT_EDITOR_TOOL,
  isPreviousTool: () => false,
  shapes: INITIAL_SHAPE_STATE(),
  setShapes: () => null,
  toggledCollapses: [],
  toggleCommentOpeningImageId: () => null,
  updateAnchorPoint: () => null,
  updateAllAnchorsStatus: () => null,
  updateAllDistanceAnchorsStatus: () => null,
  updateAllSelectedShapesStatus: () => null,
  updateAllShapesStatus: () => null,
  updateAnchorStatus: () => null,
  updateCommentBody: () => null,
  updateCommentDeletingImageIndexes: () => null,
  updateCommentEditingCaptions: () => null,
  updateCommentImages: () => null,
  updateCommentImageHandlingIndex: () => null,
  updateCommentName: () => null,
  updateCommentUploadedImages: () => null,
  updateDistanceAnchorPoint: () => null,
  updateDistanceAnchorStatus: () => null,
  updatePointCloudVisibility: () => null,
  updateMeshRefs: () => null,
  updateSelectedPointDiameter: () => null,
  updateSelectedPointValue: () => null,
  updateShapeStatus: () => null,
  updateShapeStatusById: () => null,
  updateShapeStatusByIds: () => null,
  updateToggledCollapses: () => null,
  setInspectionSheet: () => null,
  isMainSheetModalOpen: false,
  setIsMainSheetModalOpen: () => null,
  inspectionItems: [],
  setInspectionItems: () => null,
  fetchInspectionSheet: () => Promise.reject(),
  fetchInspectionItems: () => Promise.reject(),
  fetchShapes: () => Promise.reject(),
  setIsLayerModifying: () => null,
  setDepthEstimationTypes: () => null,
  depthEstimationTypes: [],
  changeCollidingShapeIds: () => null,
  collidingShapeIds: [],
  selectionBoundaryBoxVectors: [],
  meshPoints: {},
  setMeshPoints: () => null,
  cameraRef: { current: null } as RefObject<PerspectiveCamera>,
  arcballControlsRef: {} as RefObject<ArcballControls>,
  focusCamera: () => null,
}

export const EditorContext = createContext(initialState)
export const useEditorContext = () => useContext(EditorContext)
