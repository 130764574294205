import { useContext } from 'react'

import { useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { CanvasConfig } from 'interfaces/editor'

import { setTarget } from '../store'

export default function useMainCanvas(): CanvasConfig {
  // Context
  const { focusCamera, selectedTool } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()

  // Derived
  const isToolSelected = selectedTool === EDITOR_TOOLS.FOCUS

  return {
    events: {
      onRawMouseUp: (event, points) => {
        if (!points) return

        // Possible action to trigger
        // - Mouse middle click at anytime, with any tool
        if (event.button === 1) {
          focusCamera(points)
          dispatch(setTarget(points))
        }
      },
      onMouseUp: (event, points, { isDoubleClicked }) => {
        if (!points) return

        // Possible action to trigger
        // - Double click on the same point with only the MOVE tool
        // - FOCUS tool is selected
        if ((isDoubleClicked && selectedTool === EDITOR_TOOLS.MOVE) || isToolSelected) {
          focusCamera(points)
          dispatch(setTarget(points))
        }
      },
    },
  }
}
