import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TransformTypes } from 'interfaces/canvas'
import { EditorPermissionSet } from 'interfaces/validation'

export enum CursorState {
  DEFAULT = 'default',
  GRAB = 'grab',
  GRABBING = 'grabbing',
  POINTER = 'pointer',
  CROSSHAIR = 'crosshair',
}

export interface EditorState {
  /**
   * User is allowed to do modification for editor-related actions
   */
  permissionSet: EditorPermissionSet

  /**
   * Flag to indicate if volume value should be hidden.
   */
  hidePlaneLabel: boolean

  /**
   * Shape IDs of objects that anchor can be placed on.
   * Once this is set, every other settings will be ignored.
   * Even PCD will be ignored.
   */
  anchorPlacementObjects: string[]

  /**
   * Cursor state
   */
  cursor: CursorState

  /**
   * Allowed transform controls.
   */
  allowedTransformControls?: TransformTypes[]

  /**
   * Current transform type.
   */
  currentTransformType?: TransformTypes

  /**
   * Flag to indicate if currently applied transforms should be reset.
   */
  shouldResetTransforms: boolean

  /**
   * Shape IDs of objects that are currently hovered.
   */
  hoveredShapeId: string

  /**
   * Shape IDs of objects that are currently selected.
   */
  selectedShapeIds: string[]

  /**
   * Shape IDs of objects that needs to be dimmed.
   */
  dimmedShapeIds: string[]

  /**
   * Flag to indicate if panning is disabled.
   */
  disablePanning: boolean

  /**
   * PCD transparency, normalized (0 to 1).
   */
  pcdTransparency: number | undefined
}

const initialState: EditorState = {
  permissionSet: {
    BROWSE: [],
    MEASURE: [],
    DETECT: [],
    MODIFY: [],
  },
  hidePlaneLabel: false,
  anchorPlacementObjects: [],
  cursor: CursorState.DEFAULT,
  shouldResetTransforms: false,
  hoveredShapeId: '',
  selectedShapeIds: [],
  dimmedShapeIds: [],
  disablePanning: false,
  pcdTransparency: 0.8,
}

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setPermissionSet: (state, action: PayloadAction<EditorPermissionSet>) => {
      state.permissionSet = action.payload
    },
    setHidePlaneLabel: (state, action: PayloadAction<boolean>) => {
      state.hidePlaneLabel = action.payload
    },
    setAnchorPlacementObjects: (state, action: PayloadAction<string[]>) => {
      state.anchorPlacementObjects = action.payload
    },
    setCursor: (state, action: PayloadAction<CursorState>) => {
      state.cursor = action.payload
    },
    setAllowedTransformControls: (state, action: PayloadAction<TransformTypes[]>) => {
      state.allowedTransformControls = action.payload
      state.currentTransformType = action.payload[0] // eslint-disable-line
    },
    setCurrentTransformType: (state, action: PayloadAction<TransformTypes>) => {
      state.currentTransformType = action.payload
    },
    setShouldResetTransforms: (state, action: PayloadAction<boolean>) => {
      state.shouldResetTransforms = action.payload
    },
    setHoveredShapeId: (state, action: PayloadAction<string>) => {
      state.hoveredShapeId = action.payload
    },
    setSelectedShapeIds: (state, action: PayloadAction<string[]>) => {
      state.selectedShapeIds = action.payload
    },
    addSelectedShapeId: (state, action: PayloadAction<string>) => {
      state.selectedShapeIds.push(action.payload)
    },
    addDimmedShapeId: (state, action: PayloadAction<string>) => {
      state.dimmedShapeIds.push(action.payload)
    },
    removeDimmedShapeId: (state, action: PayloadAction<string>) => {
      state.dimmedShapeIds = state.dimmedShapeIds.filter((id) => id !== action.payload)
    },
    resetTransformControls: (state) => {
      state.allowedTransformControls = undefined
      state.currentTransformType = undefined
      state.shouldResetTransforms = false
    },
    setDisablePanning: (state, action: PayloadAction<boolean>) => {
      state.disablePanning = action.payload
    },
    setPcdTransparency: (state, action: PayloadAction<number>) => {
      state.pcdTransparency = action.payload
    },
    resetPcdTransparency: (state) => {
      state.pcdTransparency = undefined
    },
    restorePcdTransparency: (state) => {
      state.pcdTransparency = initialState.pcdTransparency
    },
    reset: () => initialState,
  },
})

export const {
  setPermissionSet,
  setHidePlaneLabel,
  setAnchorPlacementObjects,
  setCursor,
  setDisablePanning,
  setPcdTransparency,
  resetPcdTransparency,
  restorePcdTransparency,

  // transform controls
  setAllowedTransformControls,
  setCurrentTransformType,
  setShouldResetTransforms,
  resetTransformControls,

  // shapes-related
  setHoveredShapeId,
  setSelectedShapeIds,
  addSelectedShapeId,
  addDimmedShapeId,
  removeDimmedShapeId,

  reset,
} = editorSlice.actions

export default editorSlice.reducer
