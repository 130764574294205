import { FC } from 'react'

import { VStack } from '@chakra-ui/react'

import { INFO_PANEL_WIDTH } from 'config/styles'

import { Tools } from '../tools/setup'
import SettingPanel from './SettingPanel'
import ShapePanel from './ShapePanel'
import WorkingShapePanel from './WorkingShapePanel'

const InfoPanels: FC<{ actionPanelHeight: number }> = ({ actionPanelHeight }) => {
  // Standard panels
  const standardPanels: [FC, number][] = [
    [SettingPanel, 0],
    [ShapePanel, 10],
    [WorkingShapePanel, 30],
  ]

  // Module panels
  const modulePanels = (
    Tools.map((tool) => [
      tool.components?.panel,
      ...(tool.toolbar?.variants?.map((variant) => variant.components?.panel) || []),
    ])
      .flat()
      .filter(Boolean)
      .flat() as ([FC, number] | FC)[]
  ).map<[FC, number]>((panel) => (panel && Array.isArray(panel) ? panel : [panel, Number.MAX_SAFE_INTEGER]))

  // Merge all tools and sort by order
  const toolsPanels = [...standardPanels, ...modulePanels].sort((a, b) => a[1] - b[1]).map((panel) => panel[0])

  return (
    <VStack
      color="secondary.400"
      fontSize="xs"
      position="absolute"
      right={0}
      spacing={1.5}
      top={2}
      width={INFO_PANEL_WIDTH}
      maxH={`calc(100svh - ${actionPanelHeight}px)`}
      overflow="hidden"
    >
      {toolsPanels.map((ToolPanel) => (
        <ToolPanel key={`tool-panels-${ToolPanel.name}`} />
      ))}
    </VStack>
  )
}

export default InfoPanels
