import { useCallback, useContext, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import { PointArray } from 'interfaces/attribute'
import { CanvasConfig } from 'interfaces/editor'

import { SelectionStage, setSelectionStage, setTarget } from '../store'

export default function useMainCanvas(): CanvasConfig {
  // Context
  const { selectedTool, arcballControlsRef, cameraRef, focusCamera } = useContext(EditorContext)

  // Store
  const dispatch = useAppDispatch()
  const selectionStage = useSelector((state: RootState) => state.toolCameraProfile.selectionStage)

  // State
  const [onDownData, setOnDownData] = useState<{ position: number[]; timestamp: number } | undefined>(undefined) // position here is screen position, not canvas coordinates.

  // Vars
  const isToolSelected = useMemo(() => selectedTool === EDITOR_TOOLS.CAMERA_PROFILE, [selectedTool])

  /**
   * Handle mouse up event.
   *
   * @param e MouseEvent
   * @param point Clicked point in 3D Space
   */
  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, point: PointArray | undefined) => {
      if (
        !isToolSelected ||
        !arcballControlsRef.current ||
        !cameraRef.current ||
        selectionStage === SelectionStage.SAVING
      )
        return
      const mouseStart = onDownData ? { ...onDownData } : undefined
      setOnDownData(undefined)

      const distanceFromDown = mouseStart
        ? Math.hypot(e.clientX - mouseStart.position[0], e.clientY - mouseStart.position[1])
        : 0
      if (distanceFromDown > 10) return // user has been dragging the canvas

      if (point) {
        focusCamera(point)
        dispatch(setTarget(point))
        dispatch(setSelectionStage(SelectionStage.ROTATE))
      }
    },
    [onDownData, isToolSelected, selectionStage, arcballControlsRef, cameraRef, focusCamera, dispatch],
  )

  /**
   * Handle mouse down event.
   */
  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isToolSelected) return
      setOnDownData({ position: [e.clientX, e.clientY], timestamp: Date.now() })
    },
    [isToolSelected],
  )

  return {
    events: {
      onMouseUp,
      onMouseDown,
    },
  }
}
