import { useContext, useEffect, useState } from 'react'

import { setSelectedBlueprint } from 'pages/projects/blueprint/store/blueprint'
import {
  fetchComments,
  resetComments,
  setPermissionSet,
  setSelectedComment,
} from 'pages/projects/common/Comments/store/comments'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { extractHashIds } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

export const useComments = () => {
  // Route matching to check current page
  const { project_id } = useParams<{ project_id: string }>()
  const location = useLocation()
  const queries = new URLSearchParams(location.search)
  const inspection_area_id = queries.get('area')

  // Contexts
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  // Store
  const dispatch = useAppDispatch()
  const isPageLoading = useSelector((state: RootState) => state.page.isLoading)
  const comments = useSelector((state: RootState) => state.comments.comments)
  const isInvited = useSelector((state: RootState) => state.page.isInvited)
  const isOwner = useSelector((state: RootState) => state.page.isOwner)
  const blueprints = useSelector((state: RootState) => state.blueprint.blueprints)

  // States
  const [isLoading, setIsLoading] = useState(false)

  /**
   * On page load, fetch comments
   */
  useEffect(() => {
    if (isPageLoading || !project_id) {
      return
    }

    void (async () => {
      setIsLoading(true)

      // Reset first to prevent any residue from another project/inspection area
      dispatch(resetComments())

      const access_token = await getAccessToken()
      if (!access_token) {
        return
      }

      await dispatch(
        fetchComments({
          access_token,
          project_id,
          inspection_area_id: inspection_area_id || '',
          showErrorModal,
        }),
      )

      setIsLoading(false)
    })()
  }, [project_id, inspection_area_id, isPageLoading, getAccessToken, showErrorModal, dispatch])

  useEffect(() => {
    if (location.hash.startsWith('#comments-')) {
      const ids = extractHashIds(location.hash)
      const comment = comments.find((cmt) => cmt.thread_id === ids.comments)
      if (comment) dispatch(setSelectedComment(comment))

      if (ids.blueprints) {
        const blueprint = blueprints.find((bp) => bp.blueprint_id === ids.blueprints)

        // If blueprint is not found, set the first blueprint as selected
        // Setting first blueprint by default is disabled when hash is set
        dispatch(setSelectedBlueprint(blueprint || blueprints[0]))
      }
    }
  }, [comments, blueprints, dispatch, location])

  /**
   * Get permission set for the Comments
   */
  useEffect(() => {
    if (isPageLoading) return

    dispatch(setPermissionSet(decideActionPermission(isOwner, isInvited).COMMENT_THREAD))
  }, [isPageLoading, isInvited, isOwner, dispatch])

  return { isLoading }
}
