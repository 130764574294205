import { FC, useContext, useState } from 'react'

import { ChevronDownIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Square,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import fileDownload from 'js-file-download'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { CAD_FILE_TYPES, MODAL_TYPES } from 'config/constants'

import { CADFileType } from 'interfaces/interfaces'

import { downloadCadFile } from 'services/AWS'
import { getSignedUrlForCadFile, postCadFiles } from 'services/InspectionArea'
import { shapesExist } from 'services/Util'

const CADExportButton: FC = () => {
  // Store
  const project = useSelector((state: RootState) => state.page.project)
  const inspectionArea = useSelector((state: RootState) => state.page.inspectionArea)

  // Context
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { shapes } = useContext(EditorContext)
  const { getAccessToken } = useContext(UserContext)

  //* ダウンロード処理用
  const [extension, setExtension] = useState<CADFileType>(CAD_FILE_TYPES.ifc)
  const [isLoading, setIsLoading] = useState(false)
  const [downloadText, setDownloadText] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  //* ダウンロード処理
  const download = async () => {
    if (!project?.project_id || !inspectionArea?.inspection_area_id) {
      showModal({
        body: '工事が存在しません。',
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    //* cadファイルの生成
    setIsLoading(true)
    setIsModalOpen(false)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    const generatedCadProject = await postCadFiles(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      extension,
      showErrorModal,
    )
    if (!generatedCadProject) {
      setIsLoading(false)
      return false
    }

    if (!generatedCadProject.cad_file?.name) {
      setIsLoading(false)
      showModal({
        body: 'データが存在しません。',
        modalType: MODAL_TYPES.ERROR,
      })
      return false
    }

    //* cadファイル用の署名付きURL取得
    const downloadUrl = await getSignedUrlForCadFile(
      token,
      project.project_id,
      inspectionArea.inspection_area_id,
      showErrorModal,
    )
    if (!downloadUrl) {
      setIsLoading(false)
      return false
    }

    //* 署名付きURLからcadファイルをダウンロード
    const downloadResult = await downloadCadFile(downloadUrl, setDownloadText, showErrorModal)
    if (!downloadResult) {
      setIsLoading(false)
      return false
    }

    //* ファイルダウンロード処理
    const { blob, type } = downloadResult
    const fileBlob = new Blob(blob, { type })
    fileDownload(fileBlob, generatedCadProject.cad_file?.name)

    setIsLoading(false)
    return true
  }

  return (
    <>
      <Tooltip
        label={!shapesExist(shapes) ? <Text color="orange.400">鉄筋、平面等の要素がありません</Text> : undefined}
        hasArrow
        placement="left"
      >
        <Button
          rightIcon={
            <Square fontSize={{ base: 'xl', xl: 'lg' }}>
              <DownloadIcon />
            </Square>
          }
          _disabled={{ cursor: 'not-allowed', opacity: 0.5 }}
          fontSize={{ base: 'md', xl: 'sm' }}
          onClick={() => setIsModalOpen(true)}
          isLoading={isLoading}
          isDisabled={isLoading || !shapesExist(shapes)}
          disabled={isLoading || !shapesExist(shapes)}
          spinnerPlacement="end"
          loadingText={downloadText.substring(downloadText.indexOf('.'))}
          justifyContent="space-between"
          whiteSpace="nowrap"
          textAlign="left"
          data-testid="cad-export-button"
          w="100%"
          px={3}
        >
          CAD出力
        </Button>
      </Tooltip>

      <Modal
        closeOnOverlayClick
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        trapFocus={false}
        isCentered
        size="xs"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">CADファイルを生成</ModalHeader>
          <ModalCloseButton hidden={isLoading} />
          <ModalBody position="relative">
            <FormControl>
              <FormLabel fontSize="sm">ファイル形式を選択してください</FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  backgroundColor="transparent"
                  borderWidth={1}
                  width="100%"
                  textAlign="left"
                  fontWeight="normal"
                >
                  .{extension}
                </MenuButton>
                <MenuList>
                  {Object.values(CAD_FILE_TYPES).map((val: CADFileType) => (
                    <MenuItem key={val} onClick={() => setExtension(val)}>
                      .{val}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>
          </ModalBody>

          <ModalFooter mt={8} justifyContent="center">
            <Button me={3} py={2} minW="100px" onClick={() => setIsModalOpen(false)}>
              キャンセル
            </Button>
            <Button colorScheme="primary" me={3} py={2} minW="100px" onClick={() => download()}>
              ファイル生成
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CADExportButton
