import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Box, Tooltip } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { uniqueId } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Camera, Line, LineSegments, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import { EDITOR_MEASURE_KEYS, EDITOR_REQUIRED_ANCHORS } from 'config/constants'
import { EDITOR_FRAME_COLORS, PLANE_SIDE_COLOR } from 'config/styles'

import { AnchorPoints, CuboidAnchor, FocusedPoint, MeasureKey, PointArray, ShapeKey } from 'interfaces/interfaces'

import { calculateCenterAndDistance, getCenterOfTwoPoints } from 'services/Editor'
import {
  findMissingVertexParallelogram,
  fixVertexOnNormal,
  getCuboidFromPoints,
  pointsToVector3s,
} from 'services/Points'
import { getDistanceLabel, meterToMillimeter, millimeterToMeter } from 'services/Util'

import PointMesh from './PointMesh'

export const Frame: FC<{
  framePoints: Vector3[]
  color: string
  needFillMissingPoint: boolean
  label?: string
  labelColor?: string
  errorMessage?: string
}> = ({ framePoints, color, labelColor, needFillMissingPoint, label, errorMessage }) => {
  const ref = useRef<Line>()
  const [tooltipKey, setTooltipKey] = useState(uniqueId('frame-error-tooltip'))

  if (framePoints.length === 3 && needFillMissingPoint) {
    framePoints.push(findMissingVertexParallelogram(framePoints))
    framePoints.push(framePoints[0].clone())
  }

  useFrame(() => {
    if (ref.current) {
      ref.current.geometry.setFromPoints(framePoints)
      ref.current.computeLineDistances()
    }
  })

  /**
   * Set tooltip key to force update tooltip position
   */
  const onWindowResize = useCallback(() => {
    setTooltipKey(uniqueId('frame-error-tooltip'))
  }, [setTooltipKey])

  /**
   * Add event listener on window resize to update tooltip position
   */
  useEffect(() => {
    window.addEventListener('resize', onWindowResize)

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [onWindowResize])

  return (
    <>
      {/* ref must be any */}
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */}
      <line ref={ref as any}>
        <bufferGeometry />
        <lineDashedMaterial
          transparent
          depthTest={false}
          depthWrite={false}
          color={color}
          dashSize={0.005}
          gapSize={0.005}
        />
        {label && labelColor && (
          <Html
            position={framePoints.length >= 2 ? getCenterOfTwoPoints(framePoints[0], framePoints[1]) : framePoints[0]}
            style={{ transform: 'translateX(-50%) translateY(-50%)' }}
            zIndexRange={[1, 9]}
          >
            {errorMessage ? (
              <Tooltip
                key={tooltipKey}
                hasArrow
                placement="top"
                label={errorMessage}
                p="8px"
                fontWeight="normal"
                className="canvas-tooltip"
                defaultIsOpen
              >
                <Box
                  backgroundColor="yellow"
                  py={0}
                  px={4}
                  fontSize="80%"
                  fontWeight="bold"
                  color="black"
                  whiteSpace="nowrap"
                >
                  ⚠️ {label}
                </Box>
              </Tooltip>
            ) : (
              <Box
                backgroundColor={labelColor}
                py={0}
                px={4}
                fontSize="80%"
                fontWeight="bold"
                color="white"
                whiteSpace="nowrap"
              >
                {label}
              </Box>
            )}
          </Html>
        )}
      </line>
    </>
  )
}

const CuboidFrame: FC<{
  framePoints: Vector3[]
  color: string
  baseDiameter: number
}> = ({ framePoints, color, baseDiameter }) => {
  const ref = useRef<LineSegments>()

  const fixedPoints = [...fixVertexOnNormal([framePoints[0], framePoints[1], framePoints[2]]), framePoints[3]]
  const { position, quaternion, edgesGeometry } = getCuboidFromPoints(fixedPoints, millimeterToMeter(baseDiameter))

  useFrame(() => {
    if (ref.current) {
      ref.current.computeLineDistances()
    }
  })

  return (
    <lineSegments
      // ref must be any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      ref={ref as any}
      quaternion={quaternion}
      position={position}
      geometry={edgesGeometry}
    >
      <lineDashedMaterial depthTest={false} depthWrite={false} color={color} dashSize={0.005} gapSize={0.005} />
    </lineSegments>
  )
}

const AnchorFrames: FC<{
  anchors: AnchorPoints[]
  processingAnchor?: PointArray
  selectedPoint?: FocusedPoint
  arcballControls: ArcballControls | null
  shapeKey: ShapeKey | MeasureKey
}> = ({ anchors, processingAnchor, selectedPoint, arcballControls, shapeKey }) => {
  const lastAnchor = anchors.length ? anchors[anchors.length - 1] : null
  const [scale, setScale] = useState(1)
  const getAnchorKey = (anchorIndex: number) => `${shapeKey}-frame-${anchorIndex}`
  const getPointKey = (anchorIndex: number, pointIndex: number) => `${getAnchorKey(anchorIndex)}-${pointIndex}`

  useFrame(() => {
    setScale(
      Math.max(
        arcballControls
          ? // eslint-disable-next-line dot-notation
            (arcballControls['target'] as Vector3).distanceTo((arcballControls['camera'] as Camera).position) * 2
          : 1,
        1,
      ),
    )
  })

  const distanceLabel = (center: PointArray | undefined, distance: number | undefined, focused: boolean) => (
    <Html position={center || 0} style={{ transform: 'translateX(-50%) translateY(-50%)' }} zIndexRange={[1, 9]}>
      <Box
        backgroundColor={focused ? 'yellow' : EDITOR_FRAME_COLORS[shapeKey]}
        px={2}
        fontSize="80%"
        fontWeight="bold"
        color={focused ? 'black' : 'white'}
      >
        {getDistanceLabel(distance || 0)}
      </Box>
    </Html>
  )

  const anchorFrames = anchors.map((anchor, anchorIndex) => {
    if (anchor.invisible || anchor.deleted || !anchor.points?.length) {
      return null
    }

    return (
      <group renderOrder={1} key={getAnchorKey(anchorIndex)}>
        {anchor.points.map((point, pointIndex) => (
          <PointMesh
            key={getPointKey(anchorIndex, pointIndex)}
            point={point}
            scale={scale}
            anchorIndex={anchorIndex}
            pointIndex={pointIndex}
            shapeKey={shapeKey}
          />
        ))}

        {(Object.values(EDITOR_MEASURE_KEYS) as string[]).includes(shapeKey) &&
          anchor.points.length === 2 &&
          distanceLabel(
            anchor.center,
            anchor.distance,
            selectedPoint?.anchorIndex === anchorIndex && selectedPoint?.shapeKey === shapeKey,
          )}
      </group>
    )
  })

  const liveDistanceFrame = () => {
    if (shapeKey !== EDITOR_MEASURE_KEYS.DISTANCE || !processingAnchor || lastAnchor?.points?.length !== 1) {
      return null
    }
    const distanceFactors = calculateCenterAndDistance({
      ...lastAnchor,
      points: [...lastAnchor.points, processingAnchor],
    })
    return distanceLabel(distanceFactors?.[0], distanceFactors?.[1], true)
  }

  return (
    <>
      {anchorFrames}

      {processingAnchor && lastAnchor?.points?.length && (
        <>
          {/* TODO: this is really terrible place for this, but leaving it here until proper refactor */}
          {/* length_1 distance */}
          {lastAnchor.points.length >= 1 && (
            <Html
              position={getCenterOfTwoPoints(
                new Vector3(...lastAnchor.points[0]),
                new Vector3(...(lastAnchor.points[1] || processingAnchor)),
              )}
              style={{ transform: 'translateX(-50%) translateY(-50%)' }}
              zIndexRange={[1, 9]}
            >
              <Box
                backgroundColor="yellow"
                px={5}
                fontSize="80%"
                fontWeight="bold"
                color="black"
                whiteSpace="nowrap"
                borderWidth={2}
              >
                {meterToMillimeter(
                  new Vector3(...lastAnchor.points[0]).distanceTo(
                    new Vector3(...(lastAnchor.points[1] || processingAnchor)),
                  ),
                ).toFixed(0)}
                mm
              </Box>
            </Html>
          )}
          <Frame
            framePoints={fixVertexOnNormal(pointsToVector3s([...lastAnchor.points, processingAnchor]))}
            color={EDITOR_FRAME_COLORS[shapeKey]}
            needFillMissingPoint={false}
            labelColor={PLANE_SIDE_COLOR[lastAnchor.plane_side || 'default']}
          />
        </>
      )}
      {liveDistanceFrame()}
    </>
  )
}

export const CuboidAnchorFrames: FC<{
  anchor?: CuboidAnchor
  shapeKey: ShapeKey
  baseDiameter: number
}> = ({ anchor, shapeKey, baseDiameter }) => {
  const getAnchorKey = () => `${shapeKey}-cuboid-frame`

  // TODO: this is really terrible place for this, but leaving it here until proper refactor
  const processingAnchor = useSelector((state: RootState) => state.maskPCD.mouseAnchor)

  if (!anchor) {
    return null
  }

  const points = [...(anchor?.points || [])]

  if (processingAnchor) {
    points.push(processingAnchor)
  }

  if (!points.length) {
    return null
  }

  return (
    <group renderOrder={1} key={getAnchorKey()}>
      {points.length <= 3 && (
        <Frame
          framePoints={fixVertexOnNormal(pointsToVector3s(points))}
          color={EDITOR_FRAME_COLORS[shapeKey]}
          needFillMissingPoint
        />
      )}
      {points.length === EDITOR_REQUIRED_ANCHORS.cuboid && (
        <CuboidFrame
          framePoints={pointsToVector3s(points)}
          color={EDITOR_FRAME_COLORS[shapeKey]}
          baseDiameter={baseDiameter}
        />
      )}
    </group>
  )
}

export default AnchorFrames
