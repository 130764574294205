import { ToolbarVolumeIconPolygon } from 'assets/icons'

import { EDITOR_TOOLS, EDITOR_TOOLS_LABELS, USER_TYPES } from 'config/constants'

import { EditorTool, ToolbarCategory } from 'interfaces/editor'
import { PermissionSets } from 'interfaces/validation'

import PlaneSideSelectionPanel from './Polygon/components/PlaneSideSelection'
import useEditor from './Polygon/hooks/useEditor'
import useMainCanvas from './Polygon/hooks/useMainCanvas'

const VolumeEstimation: EditorTool = {
  key: EDITOR_TOOLS.VOLUME_POLYGON,
  authCheck: (permissionSets: PermissionSets, userType: keyof typeof USER_TYPES) =>
    permissionSets.MAIN_CANVAS.DETECT.includes(userType),
  toolbar: {
    icon: <ToolbarVolumeIconPolygon width="50%" height="50%" />,
    label: EDITOR_TOOLS_LABELS.VOLUME,
    category: ToolbarCategory.Generate,
  },
  hooks: {
    useMainCanvas,
    useEditor,
  },
  components: {
    panel: [PlaneSideSelectionPanel],
  },
  config: {
    anchor: {
      notPlaceableOnObjects: true,
    },
  },
}

export default VolumeEstimation
